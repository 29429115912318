import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

interface ProtectedLayoutProps {
  redirectTarget: string;
  blockedSearchParamList: string[];
}

const ProtectedLayout = ({ redirectTarget, blockedSearchParamList }: ProtectedLayoutProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isProtected = Array.from(searchParams.keys())
    .map((param) => blockedSearchParamList.includes(param))
    .filter(Boolean).length;

  if (isProtected) {
    return <Navigate to={redirectTarget} replace />;
  }

  return <Outlet />;
};

export default ProtectedLayout;
