import { useEffect, useState } from 'react';
import { StyledProjectSection } from './style';
import { ProjectsList } from './ProjectsList';
import { Tabs } from 'ui';
import { routes } from 'config/config';
import { useProjectState } from 'contexts/ProjectContext';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useParticipationBudgetState } from 'contexts/ParticipationBudgetContext';

export type ProjectState =
  | 'in_voting'
  | 'voting_is_closed'
  | 'supported'
  | 'being_implemented'
  | 'realized'
  | 'not_supported';

interface StatusProps {
  id: string;
  value: ProjectState;
  name: string;
}

interface ProjectSectionProps {
  budget: number;
  onTabChange?: (activeKey: string) => void;
  onProjectListChange?: (visibleProjects: any[]) => void;
}

export const ProjectSection = ({
  budget,
  onTabChange = () => {},
  onProjectListChange = () => {},
}: ProjectSectionProps) => {
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [years, setYears] = useState<string[]>([]);
  const [municipalProject, setMunicipalProject] = useState<any>({});

  const { budgets } = useParticipationBudgetState();
  let [searchParams] = useSearchParams();
  const isModalOpen = searchParams.get('municipal-modal');

  const { appendData, projects, initialized } = useProjectState();

  const excludedStatuses = ['submitted', 'did_not_qualify', 'ready_to_vote'];
  const validProjects = projects?.filter((project: any) => !excludedStatuses.includes(project.state));
  const publicStatus: StatusProps[] = [
    { id: '1', value: 'in_voting', name: 'Balsošanā' },
    { id: '2', value: 'voting_is_closed', name: 'Balsošana noslēgusies' },
    { id: '3', value: 'supported', name: 'Atbalstīts' },
    { id: '4', value: 'being_implemented', name: 'Tiek īstenots' },
    { id: '5', value: 'realized', name: 'Realizēts' },
    { id: '6', value: 'not_supported', name: 'Neatbalstīts' },
  ];

  const calculateYears = (municipalProject: any) => {
    if (!municipalProject?.atvk_id) {
      return [];
    }

    const { voting_period_from } = municipalProject;
    const today = dayjs();
    const startYear = dayjs(routes.geo.municipalProjectSelectedYear);
    const isInVoting = today.isAfter(dayjs(voting_period_from));
    const result = new Array(Math.abs(startYear.diff(today, 'year')))
      .fill(1)
      .map((_, index) => startYear.add(index, 'year').format('YYYY'));

    if (isInVoting) {
      result.push(today.format('YYYY'));
    }

    return result.sort((a, b) => +b - +a);
  };

  const handleTabChange = (year: string) => {
    setSelectedYear(year);
    onTabChange(year);
  };

  useEffect(() => {
    if (searchParams.get('atvk_id') && isModalOpen !== 'open') {
      const status = publicStatus.map((el) => el.value);
      const municipalProject = budgets.find((e: any) => e.atvk_id === searchParams.get('atvk_id'));
      const calculatedYears = calculateYears(municipalProject);

      setMunicipalProject(municipalProject);
      appendData({
        search: {
          atvk_id: searchParams.get('atvk_id'),
          year: selectedYear || calculatedYears[0],
          state: status,
          count_of_votes: true,
          show_winners: true,
          order: JSON.stringify({
            count_of_votes: 'desc',
          }),
        },
      });
      setYears(calculateYears(municipalProject));

      if (!selectedYear) {
        setSelectedYear(calculatedYears[0]);
      }
    }
  }, [searchParams, initialized, selectedYear]);

  useEffect(() => {
    onProjectListChange(validProjects);
  }, [JSON.stringify(validProjects)]);

  return (
    <StyledProjectSection>
      <Tabs
        type="card"
        activeKey={selectedYear}
        className="year_tabs"
        onChange={handleTabChange}
        items={years.map((year) => ({
          label: year,
          key: year,
          children: (
            <ProjectsList
              data={{ projects: validProjects, year }}
              budget={budget}
              municipalProject={municipalProject}
            />
          ),
        }))}
      />
    </StyledProjectSection>
  );
};
