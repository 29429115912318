import React, { useEffect, useState } from 'react';
import { Button, Card, FileUploadCards, Input, Upload, UploadLbis } from 'ui';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import FileUploadPart from './components/FileUploadPart';
import { getBase64 } from 'utils/globalFunctions';
import { useSearchParams } from 'react-router-dom';

export interface FileProp {
  id: number;
  name: string;
  size: number;
  uid: string;
}

interface UploadFileProps {
  files: any[] | [];
  setFiles: Function;
  submittableAttachments: string[];
}

const UploadFiles = ({ files, setFiles, submittableAttachments }: UploadFileProps) => {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const isMlNull = searchParams.get('ml') == null;
  const onMunicipalityLand = searchParams.get('ml') === 't';
  const isThereProjectId = searchParams.get('project-id') && !!searchParams.get('project-id');
  const projectId = isThereProjectId ? searchParams.get('project-id') : undefined;
  const [confirmationFiles, setConfirmationFiles] = useState<any[]>([]);
  const [restOfFiles, setRestOfFiles] = useState<any>([]);
  const [additionalFiles, setAdditionalFiles] = useState<any[]>([]);
  const isMunicipalLand = files.some((file: any) => file.section_name == 'Apstiprinājums');

  useEffect(() => {
    setRestOfFiles([
      ...files.filter((file) => file.section_name !== 'Papildus' && file.section_name !== 'Apstiprinājums'),
    ]);
    setAdditionalFiles([...files.filter((file) => file.section_name === 'Papildus')]);
    setConfirmationFiles([...files.filter((file) => file.section_name === 'Apstiprinājums')]);
  }, [files]);

  const addFile = async (fileList: FileProp[], newFile: any, section_name: string) => {
    const isFileAvailable = fileList.find((file: any) => file.name === newFile.file.name);
    if (isFileAvailable) {
      message.warning(intl.formatMessage({ id: 'submit_project.such_file_uploaded' }));
      return;
    }
    const id = Date.now();
    const res = await getBase64(newFile.file.originFileObj as any);
    setFiles([...files, { data: res, filename: newFile.file?.name, id, section_name }]);
  };

  const removeFile = (removingFile: any) => {
    if (removingFile.blob) {
      setFiles((prev: { [key: string]: any }[]) =>
        prev.map((file) => {
          if (file.id === removingFile.id) {
            return { ...file, _destroy: true };
          } else {
            return file;
          }
        })
      );
    } else {
      setFiles([...files.filter((file) => file.id != removingFile.id)]);
    }
  };

  return (
    <>
      <ol>
        {((projectId && isMunicipalLand) || (!isMlNull && !onMunicipalityLand)) && (
          <li key="static_key">
            <FileUploadPart
              title={intl.formatMessage({ id: `participation_budget.coniform_landowner_project_his_hand` })}
              onChange={(info: any) => addFile(confirmationFiles, info, 'Apstiprinājums')}
            />
            <FileUploadCards fileList={confirmationFiles} handleRemove={(file: any) => removeFile(file)} />
          </li>
        )}
        {submittableAttachments?.map((e: string, index) => (
          <li
            key={`submittable_${index}`}
            value={index + 1 + ((projectId && isMunicipalLand) || (!isMlNull && !onMunicipalityLand) ? 1 : 0)}
          >
            <FileUploadPart
              title={e}
              onChange={(info: any) =>
                addFile(
                  restOfFiles.filter((file: any) => file.section_name === e),
                  info,
                  e
                )
              }
            />
            <FileUploadCards
              fileList={restOfFiles.filter((file: any) => file.section_name === e)}
              handleRemove={(file: any) => removeFile(file)}
            />
          </li>
        ))}
        {additionalFiles.length > 0
          ? additionalFiles.map((file, index) => {
              if (file._destroy) return <></>;

              return (
                <li key={`additional_${index}`}>
                  <Input
                    name={['custom_name', file.section_name, `${file.id}`]}
                    label={' '}
                    validations="required"
                    className="one-row-item"
                    placeholder={intl.formatMessage({ id: 'general.name' })}
                  />

                  <Card key={file.name} className="uploaded_file">
                    <p>
                      <div className="filename">{file.blob ? file.blob.filename : file.filename}</div>
                      <span>
                        {((file.blob ? file.blob.byte_size : file.data.split(',')[1].length) / (1024 * 1024)).toFixed(
                          2
                        )}{' '}
                        MB
                      </span>
                    </p>
                    <i className="fa-solid fa-xmark remove_btn" onClick={() => removeFile(file)}></i>
                  </Card>
                </li>
              );
            })
          : null}
      </ol>
      <UploadLbis
        geoClassifer={true}
        fileList={[]}
        showUploadList={false}
        onChange={(info) => addFile(additionalFiles, info, 'Papildus')}
      >
        <Button htmlType="button" label={intl.formatMessage({ id: 'participation_budget.upload_additional_file' })} />
      </UploadLbis>
    </>
  );
};

export default UploadFiles;
