import React, { useRef } from 'react';
import { ClassName, Disabled, Validations } from 'interfaces/shared';
import { StyledSelect } from './style';
import { Icon } from '../icon';
import { Form } from 'antd';
import useFormValidation from 'utils/useFormValidation';
import { Rule } from 'rc-field-form/lib/interface';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import useTooltip from 'utils/useTooltip';
import { Tooltip } from 'constants/Tooltip';
import type { FlattenOptionData } from 'rc-select/lib/interface';
import { BaseOptionType } from 'rc-select/lib/Select';
import type { InputStatus } from 'antd/es/_util/statusUtils';

export interface Option extends BaseOptionType {
  label: string;
  value: string | number;
}

export interface SelectProps extends Disabled, ClassName, Validations {
  placeholder?: string;
  children?: React.ReactNode;
  defaultValue?: string | number | React.ReactText[];
  style?: React.CSSProperties;
  onChange?: any;
  onDropdownVisibleChange?: (open: boolean) => void;
  open?: boolean;
  size?: 'large' | 'middle' | 'small';
  mode?: 'multiple' | 'tags';
  value?: number | string | string[];
  showSearch?: boolean;
  maxTagCount?: number | 'responsive';
  allowClear?: boolean;
  loading?: boolean;
  optionLabelProp?: string;
  label?: string | React.ReactNode;
  name?: (string | number)[] | string | number;
  dropdownRender?: (menu: React.ReactElement) => React.ReactElement;
  tagRender?: (props: CustomTagProps) => React.ReactElement;
  rules?: Rule[];
  initialValue?: string | string[] | number | number[];
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
  noStyle?: boolean;
  customSufixIcon?: React.ReactNode;
  options?: Option[];
  optionFilterProp?: string;
  optionRender?: (
    oriOption: FlattenOptionData<BaseOptionType>,
    info: {
      index: number;
    }
  ) => React.ReactNode;
  onInputKeyDown?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  status?: InputStatus;
  maxTagTextLength?: number;
  popupClassName?: string;
  onSearch?: (value: string) => void;
  onSelect?: (value: any, option: BaseOptionType) => void;
  virtual?: boolean;
}

const { Option } = StyledSelect;

export const SelectOption = Option;

export const Select = ({
  disabled,
  open,
  placeholder,
  className,
  children,
  onChange,
  defaultValue,
  style,
  size = 'large',
  value,
  mode,
  showSearch = true,
  maxTagCount,
  allowClear,
  loading,
  optionLabelProp,
  label,
  name,
  dropdownRender,
  tagRender,
  validations,
  rules,
  initialValue,
  placement,
  noStyle,
  onDropdownVisibleChange,
  customSufixIcon,
  options,
  optionFilterProp = 'label',
  optionRender,
  onInputKeyDown,
  status,
  maxTagTextLength,
  popupClassName,
  onSearch,
  onSelect,
  virtual,
}: SelectProps) => {
  const { formValidations } = useFormValidation();
  const { tooltip: tooltipText } = useTooltip(name);

  const refWrapper = useRef<HTMLDivElement>(null);

  return (
    <div ref={refWrapper}>
      <Form.Item
        label={label}
        name={name}
        rules={validations ? formValidations(validations) : rules}
        tooltip={tooltipText ? { ...Tooltip, title: tooltipText } : undefined}
        initialValue={initialValue}
        noStyle={noStyle}
      >
        <StyledSelect
          width={refWrapper.current && `${refWrapper.current.getBoundingClientRect().width - 40}px`}
          open={open}
          disabled={disabled}
          placeholder={placeholder}
          className={className}
          onChange={onChange}
          defaultValue={defaultValue}
          style={style}
          size={size}
          value={value}
          mode={mode}
          showSearch={showSearch}
          maxTagCount={maxTagCount}
          suffixIcon={
            loading ? undefined : customSufixIcon ? (
              customSufixIcon
            ) : mode === 'multiple' || showSearch ? (
              <Icon faBase="fa-regular" icon="magnifying-glass" />
            ) : (
              <Icon faBase="far" icon="angle-down" />
            )
          }
          removeIcon={<Icon faBase="far" icon="times" />}
          menuItemSelectedIcon={mode === 'multiple' ? <Icon faBase="fal" icon="check" /> : undefined}
          allowClear={allowClear}
          filterOption={(input: string, option: Option | any) => {
            if (!!option && options?.length) {
              return String(option[optionFilterProp]).toLowerCase().includes(String(input).toLowerCase());
            }

            if (React.isValidElement(option.children?.[0])) {
              return String(option.children[1]).toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }

            return String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          loading={loading}
          optionLabelProp={optionLabelProp}
          dropdownRender={dropdownRender}
          tagRender={tagRender}
          placement={placement}
          onDropdownVisibleChange={onDropdownVisibleChange}
          options={options}
          optionFilterProp={optionFilterProp}
          optionRender={optionRender}
          onInputKeyDown={onInputKeyDown}
          status={status}
          maxTagTextLength={maxTagTextLength}
          popupClassName={popupClassName}
          onSearch={onSearch}
          onSelect={onSelect}
          virtual={virtual}
        >
          {children}
        </StyledSelect>
      </Form.Item>
    </div>
  );
};
