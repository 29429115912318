import { Button, ButtonProps, Tooltip, TooltipProps } from 'ui';
import React, { useState } from 'react';
import UnauthenticatedModal from '../Modals/UnauthenticatedModal';
import useTooltip, { LOGIN_TOOLTIP, ProjectOption } from 'utils/useTooltip';
import { useUserState } from 'contexts/UserContext';
import { useIntl } from 'react-intl';
import { ProjectType } from 'pages/LayoutPage/Components/Sidebars/Project';
import { useProjectState } from 'contexts/ProjectContext';

export interface VotingProject extends ProjectOption, Pick<ProjectType, 'id' | 'has_voted'> {}

export type VoteHandler = (project: VotingProject) => void;

interface VoteButtonProps {
  project: VotingProject;
  button: ButtonProps;
  tooltip?: Omit<TooltipProps, 'title' | 'children'>;
  handleVote: VoteHandler;
}

const VoteButton = ({ project, button, tooltip, handleVote }: VoteButtonProps) => {
  const [isOpenAuthModal, setIsOpenAuthModal] = useState<boolean>(false);

  const { getRestrictionTooltip } = useTooltip('');
  const user = useUserState();
  const intl = useIntl();
  const { voteMap_2 } = useProjectState();

  const restrictionTooltip = getRestrictionTooltip('vote-project', { user, project });
  const isDisabled = restrictionTooltip === LOGIN_TOOLTIP ? false : !!restrictionTooltip.length;
  const hasVoted = voteMap_2[project.id] === undefined ? project.has_voted : voteMap_2[project.id];
  const label = hasVoted
    ? intl.formatMessage({ id: 'participation_budget.projects_voted_btn' })
    : intl.formatMessage({ id: 'participation_budget.projects_tovote_btn' });

  const handleChangeStatus = (restrictionTooltip: string) => {
    switch (true) {
      case restrictionTooltip === LOGIN_TOOLTIP:
        setIsOpenAuthModal(true);
        break;

      case restrictionTooltip.length > 0:
        break;

      default:
        handleVote(project);
    }
  };

  return (
    <div className="vote-button">
      <Tooltip hack {...tooltip} title={restrictionTooltip && intl.formatMessage({ id: restrictionTooltip })}>
        <Button
          label={label}
          type={hasVoted ? undefined : 'primary'}
          {...button}
          onClick={() => handleChangeStatus(restrictionTooltip)}
          disabled={isDisabled}
          className={isDisabled ? 'gray-disabled' : ''}
        />
      </Tooltip>

      <UnauthenticatedModal setShowModal={setIsOpenAuthModal} showModal={isOpenAuthModal} />
    </div>
  );
};

export default VoteButton;
