import React from 'react';

interface State {
  ideas: any[];
  highlightedId?: number;
}

interface Action {
  type: 'SAVE_IDEAS' | 'HIGHLIGHT_IDEA';
  payload: Partial<State>;
}

type Dispatch = (action: Action) => void;

const IdeaContext = React.createContext<State>(undefined!);
const IdeaDispatchContext = React.createContext<Dispatch>(undefined!);

function userReducer(state: State, action: Action): State {
  const { ideas = [], highlightedId } = action.payload;

  switch (action.type) {
    case 'SAVE_IDEAS': {
      return {
        ...state,
        ideas,
      };
    }

    case 'HIGHLIGHT_IDEA': {
      return {
        ...state,
        highlightedId,
      };
    }
  }
}

function IdeaProvider({ children }: { children: React.ReactNode }) {
  const initialState: State = {
    ideas: [],
    highlightedId: undefined,
  };

  const [state, dispatch] = React.useReducer(userReducer, initialState);

  return (
    <IdeaContext.Provider value={state}>
      <IdeaDispatchContext.Provider value={dispatch}>{children}</IdeaDispatchContext.Provider>
    </IdeaContext.Provider>
  );
}

const useIdeaState = () => React.useContext(IdeaContext);
const useIdeaDispatch = () => React.useContext(IdeaDispatchContext);

export { IdeaProvider, useIdeaDispatch, useIdeaState };
