import styled from 'styled-components';

export const StyledDateVoteCount = styled.div`
  p {
    margin: initial;
  }

  .vote-count {
    font-weight: ${({ theme }) => theme.fontWeightBolder};
  }
`;
