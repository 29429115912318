export const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const getProjectUrl = (id: string, urlParams: URLSearchParams | undefined = undefined) => {
  const pathname = `api/v1/tapis/projects/${id}}`;

  return urlParams ? `${pathname}?${urlParams.toString()}` : pathname;
};

export const chunkArray = <T = any>(array: T[], size: number): Array<T[]> => {
  if (size <= 0) throw new Error('Chunk size must be greater than 0.');

  return Array.from({ length: Math.ceil(array.length / size) }, (_, i) => array.slice(i * size, i * size + size));
};
