import React from 'react';
import { useIntl } from 'react-intl';
import InfoWrapper from '../info-wrapper';
import TimelineItem from './timeline-item';
import { Alert, Button, Icon, Tooltip } from 'ui';
import { StatusProps } from '../..';
import parse from 'html-react-parser';
import { Tag } from 'antd';
import { DateVoteCount } from '../date-vote-status';
import { useUserState } from 'contexts/UserContext';
import useTooltip from 'utils/useTooltip';

const BeingImplementedState = ({
  votingPeriodFrom,
  votingPeriodTo,
  visibleVotes,
  voteCount,
  submittedProject,
}: StatusProps) => {
  const user = useUserState();
  const intl = useIntl();
  const { getRestrictionTooltip } = useTooltip('');

  const renderSubscribeButton = () => {
    const restrictionTooltip = getRestrictionTooltip('email-verification', {
      user,
      section: 'project-view',
    });

    return (
      <Tooltip hack title={restrictionTooltip && intl.formatMessage({ id: restrictionTooltip })}>
        <Button label={intl.formatMessage({ id: 'notification.subscribe' })} />
      </Tooltip>
    );
  };

  return (
    <InfoWrapper>
      <div className="beingImplementedStatus">
        {visibleVotes && (
          <DateVoteCount votingPeriodFrom={votingPeriodFrom} votingPeriodTo={votingPeriodTo} voteCount={voteCount} />
        )}
        <Tag className={`status ${submittedProject.state}`}>
          {intl.formatMessage({ id: `participation_budget.${submittedProject.state}` })}
        </Tag>
      </div>
      {submittedProject?.versions[0]?.state_explanation && (
        <Alert type="warning" message={parse(submittedProject?.versions[0]?.state_explanation)} className="my-4" />
      )}
      {submittedProject?.versions[0]?.implementation_activities &&
        submittedProject?.versions[0]?.implementation_activities?.map((e: any) => (
          <TimelineItem title={e.activity_description} date={e.activity_date} />
        ))}
      {renderSubscribeButton()}
      <p>
        <Icon icon="circle-info" faBase="fa-regular" />
        &nbsp; {intl.formatMessage({ id: 'participation_budget.notification_2' })}
      </p>
    </InfoWrapper>
  );
};

export default BeingImplementedState;
