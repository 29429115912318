import React, { useEffect, useState } from 'react';
import { StyledSidebarDrawer } from './style';
import { BreadcrumbObject, Icon, Label } from 'ui';
import { useNotificationHeader } from 'contexts/NotificationHeaderContext';
import { useIntl } from 'react-intl';
import { Breadcrumb } from '../breadcrumb';
import { Divider } from 'ui/divider';
import { useSystemSettingState } from 'contexts/SystemSettingContext';
import { useOpenedTypeDispatch } from 'contexts/OpenedTypeContext';
import { ClassNameUtil } from 'utils/className';
import type { DrawerClassNames } from 'antd/es/drawer/DrawerPanel';
import useCheckFooterStatus from 'utils/useCheckFooterStatus';
import CloseButton from 'components/CloseButton';
import { SiderProps } from 'antd/es/layout/Sider';

export interface SidebarDrawerProps {
  children?: React.ReactNode;
  title: string;
  subtitle?: string | React.ReactNode;
  breadcrumb?: BreadcrumbObject[];
  trunc?: number;
  onClose?: () => void | Function;
  isOpen?: boolean;
  width?: SiderProps['width'];
  className?: 'sidebar-style-1' | 'sidebar-style-2' | string;
  hideLabel?: boolean;
  showBreadcrumb?: boolean;
  backIcon?: string;
  dividerVisible?: boolean;
  backText?: string;
  showTitleInBreadcrump?: boolean;
  closeIcon?: React.ReactNode;
  side: 'left' | 'right'; // is it rendered before (right) or after (left) map component in html,
  name?: any;
  classNames?: Pick<DrawerClassNames, 'content'>;
  scrollShadow?: boolean;
}

const SIDEBAR_SIDE_MAP: {
  [key in SidebarDrawerProps['side']]: 'UPDATE_RIGHT_SIDEBAR' | 'UPDATE_LEFT_SIDEBAR';
} = {
  left: 'UPDATE_LEFT_SIDEBAR',
  right: 'UPDATE_RIGHT_SIDEBAR',
};

export const SidebarDrawer = ({
  children,
  title,
  subtitle,
  breadcrumb = [],
  trunc = 0,
  onClose,
  isOpen,
  width = '380',
  className = 'sidebar-style-1',
  hideLabel = false,
  showBreadcrumb = true,
  backIcon = 'right',
  dividerVisible = true,
  backText = 'hide',
  showTitleInBreadcrump = true,
  closeIcon,
  side,
  classNames: { content = '' } = {},
  scrollShadow,
}: SidebarDrawerProps) => {
  const { height } = useNotificationHeader();
  const intl = useIntl();
  const {
    device: { isDesktop },
  } = useSystemSettingState();
  const updateOpenedState = useOpenedTypeDispatch();
  const [uid] = useState(Date.now());
  const { renderFooter } = useCheckFooterStatus();

  useEffect(() => {
    updateOpenedSidebarState(isOpen ? width : '');

    return () => updateOpenedSidebarState('');
  }, [side, isOpen]);

  const updateOpenedSidebarState = (width: SidebarDrawerProps['width']) => {
    const type = SIDEBAR_SIDE_MAP[side];

    updateOpenedState({ type, payload: { sidebarWidth: { [uid]: width } } });
  };

  return (
    <StyledSidebarDrawer
      notificationHeight={height}
      trigger={null}
      collapsible
      collapsed={!isOpen}
      collapsedWidth={0}
      width={!isDesktop ? '100vw' : width}
      className={isOpen ? `${className} ${renderFooter ? 'hide-footer' : ''} sidebar-open` : className}
    >
      {!hideLabel &&
        (className?.includes('sidebar-style-2') ? (
          <div className="sidebar-title-wrapper">
            <div className="sidebar-title">
              <div className="breadcrumb_and_title">
                {showBreadcrumb && (
                  <Breadcrumb
                    title={showTitleInBreadcrump ? title : undefined}
                    breadcrumb={breadcrumb}
                    trunc={trunc}
                  ></Breadcrumb>
                )}
                <Label className="sidebar-title-text" title label={title} bold />
                {subtitle && <Label className="mt-3" label={subtitle} />}
              </div>
              <div className="close-icon-container" onClick={onClose}>
                <Icon className="close-icon" icon={`long-arrow-${backIcon}`} faBase="fa-regular" />
                <div className="close-icon-text">{intl.formatMessage({ id: backText })}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="sidebar-title">
            <div>
              <Label title label={title} bold />
              {subtitle && (typeof subtitle !== 'string' ? subtitle : <Label className="mt-1_5" label={subtitle} />)}
            </div>
            {closeIcon || <CloseButton onClick={onClose} />}
          </div>
        ))}
      {className?.includes('sidebar-style-2') && dividerVisible && <Divider />}
      {scrollShadow && <div className="scroll-shadow" />}
      <div className={ClassNameUtil.create(['content', content]).getClassName()}>{children}</div>
    </StyledSidebarDrawer>
  );
};
