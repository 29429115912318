import styled from 'styled-components';

export const StyledProjectList = styled.div`
  .municipal_project_list {
    margin-bottom: 20px;

    .projects {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
    .block_title {
      margin: 0 0 20px;
    }
    .ant-collapse-item {
      background-color: unset;
      border: unset !important;
    }
    .ant-collapse-header {
      padding-left: 0;
    }
    .ant-collapse-content {
      border-top: unset;
      .ant-collapse-content-box {
        padding: 0 16px 9px 0;
      }
    }

    .budget-title {
      margin: initial;
    }

    .default_block {
      margin-top: 20px;

      & + div.block,
      .first_block {
        border-top: 1px solid ${({ theme }) => theme.border2};
        margin-top: 20px;
      }
    }

    .block {
      padding: 20px 0 0;
    }
  }
`;
