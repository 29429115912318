import Title from 'antd/es/typography/Title';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Tooltip } from 'ui';
import { StyledSpecialistInfo } from './style';
import { useUserState } from 'contexts/UserContext';
import useJwt from 'utils/useJwt';
import UnauthenticatedModal from 'components/Modals/UnauthenticatedModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserGroups } from 'constants/userGroups';
import useTooltip from 'utils/useTooltip';

interface SpecialistInfoProps {
  isThereSubmissionPeriod: boolean;
  isMunicipalLoading: boolean;
  hasSubmissionPeriod?: boolean;
  budget?: any;
}

export const SpecialistInfo = ({
  hasSubmissionPeriod,
  isThereSubmissionPeriod,
  isMunicipalLoading,
  budget,
}: SpecialistInfoProps) => {
  const [showAuthModal, setShowAuthModal] = useState(false);

  const intl = useIntl();
  let [searchParams] = useSearchParams();
  const { isTokenActive } = useJwt();
  const user = useUserState();
  const navigate = useNavigate();
  const { getRestrictionTooltip } = useTooltip('');

  const atvkId = searchParams.get('atvk_id');
  const { contact_person_name: name, contact_person_email: email, contact_person_phone: phone } = budget || {};

  const closeModal = () => {
    setShowAuthModal(false);
  };

  const renderSubmitButton = () => {
    const restrictionTooltip = getRestrictionTooltip('submit-project', {
      user,
      project: { atvk_id: budget?.atvk_id },
      section: 'project-list',
    });
    const isLoggedIn = isTokenActive();
    const isEnabled = !isLoggedIn || !restrictionTooltip;

    if (!hasSubmissionPeriod) {
      return null;
    }

    return (
      <Tooltip
        className="submit-button mt-5"
        hack
        title={restrictionTooltip && intl.formatMessage({ id: restrictionTooltip })}
        placement="topRight"
      >
        <Button
          loading={isMunicipalLoading}
          disabled={!isEnabled || !isThereSubmissionPeriod}
          type="primary"
          label={intl.formatMessage({ id: 'participation_budget.submit_projects' })}
          onClick={() => {
            if (!isLoggedIn) {
              setShowAuthModal(true);
            } else if (!restrictionTooltip) {
              navigate(`/main?participation-budget=open&submit-project=open&atvk_id=${atvkId}`);
            }
          }}
        />
      </Tooltip>
    );
  };

  return (
    <StyledSpecialistInfo>
      <div className="info">
        {hasSubmissionPeriod && (
          <div>
            <Title level={5} className="title">
              {intl.formatMessage({ id: 'participation_budget.specialist_info' })}
            </Title>
            <Title level={4} className="name">
              {name}
            </Title>
            <a href="">{email}</a>
            <p>{`+371 ${phone}`}</p>
          </div>
        )}
        {hasSubmissionPeriod && (
          <div className="documents">
            <Title level={5} className="title">
              {intl.formatMessage({ id: 'participation_budget.regulations_other_documents' })}
            </Title>
            <ol>
              {budget?.attachments?.map((e: any) => (
                <li>
                  <a target="_blank" href={e.url}>
                    {e.name}
                  </a>
                </li>
              ))}
            </ol>
          </div>
        )}
      </div>
      {renderSubmitButton()}
      <UnauthenticatedModal showModal={showAuthModal} setShowModal={closeModal} />
    </StyledSpecialistInfo>
  );
};
