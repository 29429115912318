import styled from 'styled-components/macro';

export const StyledTagSelect = styled.div`
  .ant-tag {
    height: initial;
  }

  .ant-select {
    min-height: initial !important;
  }

  .hidden {
    display: none;
  }
`;
