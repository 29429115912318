import { Collapse, CollapseProps } from 'ui';
import { Collapse as AntdCollapse, CollapsePanelProps as AntdCollapsePanelProps } from 'antd';
import { OrganizationsSelect, OrganizationsSelectProps } from 'components/Selects/OrganizationsSelect/Tapis';
import React from 'react';
import { useIntl } from 'react-intl';

const { Panel } = AntdCollapse;

interface CollapsePanelProps extends Omit<AntdCollapsePanelProps, 'key'> {
  key?: string | number;
}

interface OrganizationsCollapseProps {
  collapse?: CollapseProps;
  panel: CollapsePanelProps;
  select: OrganizationsSelectProps;
}

export const OrganizationsCollapse = ({ collapse, panel, select }: OrganizationsCollapseProps) => {
  const intl = useIntl();

  return (
    <Collapse ghost expandIconPosition="end" {...collapse}>
      <Panel forceRender key={'only_sibling_key'} {...panel}>
        <OrganizationsSelect
          mode="multiple"
          label={intl.formatMessage({ id: 'my_participation.my_budget.add_another_municipality' })}
          {...select}
        />
      </Panel>
    </Collapse>
  );
};
