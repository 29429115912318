import styled from 'styled-components/macro';
import { Flex } from 'antd';
import { DESKTOP_MIN_WIDTH, MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from '../../utils/device';

export const SidebarHeaderContainer = styled.div`
  background: ${({ theme }) => theme.gray03};
  display: flex;
  flex-direction: row;
  min-width: 208px;
  align-items: center;
  justify-content: center;
  padding: 24px 24px 0 24px;

  .close-icon {
    cursor: pointer;
  }

  .title {
    font-weight: ${({ theme }) => theme.fontWeightBolder} !important;
    flex-grow: 1;

    label {
      font-size: ${({ theme }) => theme.h4Size};
    }
  }
`;

export const SettingsContainer = styled.div`
  background: ${({ theme }) => theme.gray03};
  padding: 24px;

  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    padding: 12px 0 0;
    background: initial;
  }

  .semi-title {
    font-weight: ${({ theme }) => theme.fontWeightBolder};

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      font-weight: ${({ theme }) => theme.fontWeightBold};
      color: ${({ theme }) => theme.textColor13};
      font-size: ${({ theme }) => theme.p3Size};
    }
  }

  .text-option {
    display: flex;
    padding: 8px;
    background: ${({ theme }) => theme.gray01};
    border: 1px solid ${({ theme }) => theme.border2};
    margin-bottom: 8px;
    cursor: pointer;
    min-height: 34px;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      margin-bottom: initial;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-weight: ${({ theme }) => theme.fontWeightBolder};
      font-size: ${({ theme }) => theme.p1Size};
      cursor: pointer;
    }

    .portal-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .default {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.black};

    i {
      color: ${({ theme }) => theme.black};
    }
  }

  .black-on-yellow {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.yellow};

    .portal-icon {
      color: ${({ theme }) => theme.black};
    }
  }

  .white-on-black {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.black};
    color: ${({ theme }) => theme.white};

    .portal-icon {
      color: ${({ theme }) => theme.white};
    }
  }
`;

export const StyledAccessibilityModal = styled(Flex)`
  .ant-modal {
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      margin: initial;
    }

    .ant-modal-content {
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        padding: 12px 0 24px;
        justify-content: space-between;
        width: inherit;
      }

      .modal-header,
      .actual-content,
      .ant-modal-footer {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          padding: 0 20px;
        }
      }

      .modal-header {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          padding-bottom: 12px;
          margin: initial;
          font-size: ${({ theme }) => theme.p1Size};
          font-weight: ${({ theme }) => theme.fontWeightBold};
        }
      }

      .ant-modal-footer {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          display: block;
          margin: initial;
        }
      }
    }
  }

  .ant-menu.ant-menu-root {
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      line-height: initial;
      border: initial;
      background: initial;
    }
  }

  .text-options {
    .is-active {
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        border: 1px solid ${({ theme }) => theme.border4} !important;
        color: ${({ theme }) => theme.textColor12} !important;
        background: ${({ theme }) => theme.brand02Light} !important;
      }

      i {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          color: ${({ theme }) => theme.iconColor10} !important;
        }
      }
    }

    .white-on-black.is-active {
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        background: ${({ theme }) => theme.black} !important;
      }

      i {
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          color: ${({ theme }) => theme.white} !important;
        }
      }
    }
  }

  .font-sizes .text-option {
    .font-size-text {
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        font-size: ${({ theme }) => theme.p2Size};
      }
    }

    &:not(.is-active) .font-size-text {
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        padding: 0 calc((${({ theme }) => theme.p2Size} + 4px) / 2);
      }
    }
  }

  .themes {
    button {
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        width: 100%;
      }
    }
  }

  .ant-modal-footer {
    button {
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        width: 100%;
      }
    }

    .text div {
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        font-size: ${({ theme }) => theme.p1Size};
      }
    }
  }
`;
