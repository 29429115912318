import stringConverter from 'utils/stringConverter';

export type BooleanMap = {
  [key: string]: boolean;
};

export class ClassNameUtil {
  private className: string[] = [];

  private constructor(initialClassNames: string[] = []) {
    this.className = initialClassNames;
  }

  public static create(initialClassNames: Array<string | undefined | boolean> | BooleanMap = []): ClassNameUtil {
    if (initialClassNames !== null && typeof initialClassNames === 'object' && !Array.isArray(initialClassNames)) {
      return new ClassNameUtil([ClassNameUtil.processObjectClasses(initialClassNames)]);
    }

    return new ClassNameUtil(initialClassNames ? (initialClassNames.filter(Boolean) as string[]) : []);
  }

  private static processObjectClasses(className: { [key: string]: any }): string {
    return Object.keys(className)
      .filter((key) => className[key])
      .map(stringConverter().toKebab)
      .join(' ');
  }

  public add(className: string | null | BooleanMap, checkableValue: boolean = true): this {
    if (className !== null && typeof className === 'object') {
      this.className.push(ClassNameUtil.processObjectClasses(className));

      return this;
    }

    if (className && checkableValue) {
      this.className.push(className);
    }

    return this;
  }

  public remove = (className: string | null | undefined, checkableValue: boolean | undefined = true) => {
    if (className && checkableValue) {
      const index = this.className.indexOf(className);
      this.className.splice(index);
    }

    return this;
  };

  public getClassName(): string {
    return this.toString();
  }

  public toString(): string {
    return this.className.join(' ');
  }
}
