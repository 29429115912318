import React from 'react';
import { ClassNameUtil } from 'utils/className';
import { StyledPage } from './style';

interface PageLayoutProps {
  withStickyFooter?: boolean;
  withoutPageHeader?: boolean;
  children?: React.ReactNode;
}

const PageLayout = ({ withStickyFooter, withoutPageHeader, children }: PageLayoutProps) => {
  const className = ClassNameUtil.create(['page-layout'])
    .add('with-sticky-footer', !!withStickyFooter)
    .add('without-page-header', !!withoutPageHeader);

  return <StyledPage className={className.getClassName()}>{children}</StyledPage>;
};

export default PageLayout;
