import React, { useState } from 'react';
import { Button, Icon, Modal, Spinner } from 'ui';
import { Flex } from 'antd';
import { useIntl } from 'react-intl';
import useQueryApiClient from 'utils/useQueryApiClient';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResponseTutorial, TutorialsConfiguration } from 'pages/TutorialsPage';
import parse from 'html-react-parser';
import { updateUrlsInStringForHref } from 'utils/formatUrl';
import { StyledTutorialModal } from './styles';
import { pages } from 'constants/pages';
import { useOpenedTypeDispatch, useOpenedTypeState } from 'contexts/OpenedTypeContext';
import { tutorialListOrder } from 'constants/listOrder';
import { Sections } from 'constants/sections';
import { useSystemSettingState } from '../../../contexts/SystemSettingContext';
import CloseButton from '../../CloseButton';

const ModalTutorials = () => {
  const [tutorialList, setTutorialList] = useState<ResponseTutorial[]>([]);

  const intl = useIntl();
  const navigate = useNavigate();
  const { openTutorial = false } = useOpenedTypeState();
  const dispatch = useOpenedTypeDispatch();
  const {
    device: { isMobile },
  } = useSystemSettingState();
  const location = useLocation();

  const { PARTICIPATION_BUDGET, PLANNED_DOCUMENTS, GEOPRODUCTS } = Sections;
  const sectionUrlMap = {
    [PARTICIPATION_BUDGET]: '/main?participation-budget=open',
    [PLANNED_DOCUMENTS]: '/main',
    [GEOPRODUCTS]: pages.geoProductsPublic.url,
  };

  const { isLoading: isInitialLoading } = useQueryApiClient({
    request: { url: `api/v1/tutorials` },
    onSuccess: (tutorialList) => {
      const sortedList = tutorialList.sort(
        (a: any, b: any) => tutorialListOrder.indexOf(a.uniqueKey) - tutorialListOrder.indexOf(b.uniqueKey)
      );

      setTutorialList(sortedList);
    },
  });

  const onTutorialClick = (type: keyof TutorialsConfiguration) => {
    closeModal();
    navigate(sectionUrlMap[type]);
  };

  const onCancel = () => {
    closeModal();
    navigate('/main');
  };

  const closeModal = () => {
    dispatch({ type: 'CLOSE_TUTORIAL' });
  };

  const renderTutorialButton = (uniqueKey: ResponseTutorial['uniqueKey']) => {
    return (
      <Flex justify="center" className="button-wrapper">
        <Button
          type="primary"
          label={intl.formatMessage({ id: `navigation.${uniqueKey}` })}
          onClick={() => onTutorialClick(uniqueKey)}
        />
      </Flex>
    );
  };

  const renderTutorial = ({ content, attachment, uniqueKey }: ResponseTutorial) => {
    const children = (
      <>
        <Flex className="image-wrapper mb-6" onClick={() => onTutorialClick(uniqueKey)}>
          {attachment ? <img src={attachment.url} alt={`${attachment.name} Image`} /> : null}
        </Flex>
        {!isMobile && renderTutorialButton(uniqueKey)}
        <div className="content-wrapper mt-6 clamped-text">{parse(updateUrlsInStringForHref(content || ''))}</div>
        {isMobile && renderTutorialButton(uniqueKey)}
      </>
    );

    if (isMobile) {
      return (
        <Flex vertical gap={12} className="tutorial-wrapper" key={uniqueKey}>
          {children}
        </Flex>
      );
    }

    return (
      <div className="tutorial-wrapper" key={uniqueKey}>
        {children}
      </div>
    );
  };

  if (openTutorial && !!location.search) {
    onCancel();
  }

  return (
    <StyledTutorialModal>
      <Modal
        open={openTutorial}
        footer={null}
        closable
        width={970}
        onCancel={onCancel}
        closeIcon={
          isMobile ? <CloseButton onClick={onCancel} /> : <Icon className="close-icon" icon="xmark" faBase="fa-solid" />
        }
      >
        <Spinner spinning={isInitialLoading}>
          <Flex gap={isMobile ? 24 : 30} className="tutorials-wrapper">
            {tutorialList.map(renderTutorial)}
          </Flex>
        </Spinner>
      </Modal>
    </StyledTutorialModal>
  );
};

export default ModalTutorials;
