import { Menu } from 'antd';
import { Divider, Dropdown, Icon, Modal, Tooltip } from 'ui';
import React, { MouseEventHandler, useState } from 'react';
import { State as UserType, useUserState } from 'contexts/UserContext';
import { UserGroups } from 'constants/userGroups';
import useJwt from 'utils/useJwt';
import { useIntl } from 'react-intl';
import useSessionStorage from 'utils/useSessionStorage';
import useQueryApiClient from 'utils/useQueryApiClient';
import { Link, useLocation } from 'react-router-dom';
import { useSystemSettingState } from 'contexts/SystemSettingContext';
import { ProfileMenuContainer, StyledProfileMenu } from './styles';
import { createPortal } from 'react-dom';
import ProfileIcon from '../ProfileIcon';
import { ClassNameUtil } from 'utils/className';

interface ProfileMenuOptions {
  id: number;
  url: string;
  faBase?: string;
  icon: string;
  label: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  tooltip?: string;
  isActive?: boolean;
}

const ProfileMenu = () => {
  const [profileVisible, setProfileVisible] = useState<boolean>(false);

  const user: UserType = useUserState();
  const { isTokenActive } = useJwt();
  const intl = useIntl();
  const location = useLocation();
  const {
    device: { isMobile },
  } = useSystemSettingState();
  const { setSessionValue } = useSessionStorage<string | null | undefined>('SAVE_LOCATION');
  const { data: loginUrl } = useQueryApiClient({
    request: {
      url: 'api/v1/saml2',
    },
  });

  const activeRole = user.roles.find((e) => e.id === user.selectedRole);
  const isPhysical = activeRole?.code === UserGroups.authenticated;
  const isProxyUser = activeRole?.code === UserGroups.proxy;
  const disableAuthentication = window?.runConfig?.disableAuthentication;
  const hasSingleRole = user.roles.length === 1 && isPhysical;
  const showEmbeds = user?.userEmbeds > 0;
  const logoutMeuOption = {
    id: 8,
    icon: 'arrow-right-from-bracket',
    faBase: 'fa-regular',
    label: intl.formatMessage({
      id: 'profile.logout',
    }),
    url: '',
    onClick: () => user.logout(),
  };
  const menuOptions: Array<ProfileMenuOptions | undefined | false> = isTokenActive()
    ? [
        !hasSingleRole && {
          id: 1,
          icon: 'user',
          faBase: 'fa-regular',
          label: intl.formatMessage({
            id: 'profile.my_invoice',
          }),
          url: '/account',
          isActive: location.pathname.includes('/account'),
        },
        {
          id: 9,
          icon: 'people-group',
          faBase: 'fa-regular',
          label: intl.formatMessage({
            id: 'profile.my_participation',
          }),
          tooltip: intl.formatMessage({ id: 'only_physical_person_can_view_section' }),
          url: '/main?my-participation=open&tab=notices_tab',
          isActive: location.search.includes('my-participation=open'),
        },
        {
          id: 2,
          icon: 'cart-shopping',
          faBase: 'fa-regular',
          label: intl.formatMessage({
            id: 'profile.my_orders',
          }),
          url: '/orders',
          isActive: location.pathname.includes('/orders'),
        },
        showEmbeds && {
          id: 6,
          icon: 'pen',
          faBase: 'fa-regular',
          label: activeRole?.institutionClassifierId
            ? intl.formatMessage({
                id: 'profile.org_user_embeds',
              })
            : intl.formatMessage({
                id: 'profile.user_embeds',
              }),
          url: '/user-embeds',
          isActive: location.pathname.includes('/user-embeds'),
        },
        !isMobile && logoutMeuOption,
      ]
    : [
        {
          id: 1,
          icon: 'user',
          faBase: 'fa-regular',
          label: intl.formatMessage({
            id: 'profile.login',
          }),
          url: loginUrl,
          onClick: (e: any) => {
            setSessionValue(location.pathname + location.search);
            window.location.replace(loginUrl);
          },
        },
      ];

  const renderProfileMenuOption = (opt: ProfileMenuOptions) => {
    const { id, url, icon, faBase, label, tooltip, onClick, isActive = false } = opt;
    const toDisable = ['people-group', 'share-from-square', 'pen-to-square'];
    let isDisabled = false;

    if (!isPhysical && toDisable.includes(opt?.icon)) {
      isDisabled = true;
    }

    const item = (
      <div
        key={id}
        className={ClassNameUtil.create(['profile-menu-option-wrapper']).add({ isDisabled, isActive }).getClassName()}
      >
        <Link
          to={url}
          className="profile-menu-option"
          onClick={() => setProfileVisible(false)}
          replace={url.includes('?') || url.includes('://')}
          onClickCapture={onClick}
        >
          {icon && faBase && <Icon faBase={faBase} icon={icon} />}
          {label}
        </Link>
        <Divider />
      </div>
    );

    if (isDisabled) {
      return (
        <Tooltip
          style={{ width: '100px' }}
          title={tooltip ? tooltip : intl.formatMessage({ id: 'only_physical_persona' })}
        >
          {item}
        </Tooltip>
      );
    }

    return item;
  };

  const profileMenu = () => {
    if (isProxyUser) {
      return <></>;
    }

    return <Menu>{menuOptions.filter(Boolean).map((opt) => renderProfileMenuOption(opt as ProfileMenuOptions))}</Menu>;
  };

  const parseName = (entry: any) => {
    switch (true) {
      case !entry:
        return;

      case isProxyUser:
        return entry.name;

      case !entry.institutionName:
        return intl.formatMessage({ id: 'general.physical_person' });

      default:
        return entry.name + (entry.institutionName ? ' - ' + entry.institutionName : '');
    }
  };

  const renderUserData = (onClick?: MouseEventHandler<HTMLDivElement>) => (
    <ProfileIcon isDetailed={!isMobile} onClick={onClick} />
  );

  const renderLogout = () => {
    if (!isTokenActive()) {
      return null;
    }

    return (
      <>
        <Divider />
        {renderProfileMenuOption(logoutMeuOption)}
      </>
    );
  };

  const renderLayout = () => {
    if (isMobile) {
      return (
        <>
          {renderUserData(() => setProfileVisible((old) => !old))}
          {createPortal(
            <ProfileMenuContainer>
              <Modal
                width="100vw"
                className="fullScreen profile-modal"
                closable
                open={profileVisible}
                onCancel={() => setProfileVisible(false)}
                maskClosable={false}
                footer={renderLogout()}
                title={<ProfileIcon isDetailed={isTokenActive()} onClick={() => setProfileVisible(false)} />}
              >
                {profileMenu()}
              </Modal>
            </ProfileMenuContainer>,
            document.body
          )}
        </>
      );
    }

    return (
      <>
        <Dropdown
          open={profileVisible}
          menu={() => <ProfileMenuContainer>{profileMenu()}</ProfileMenuContainer>}
          trigger={['click']}
          onOpenChange={(open) => setProfileVisible(open)}
          placement="bottomRight"
          overlayClassName="w-225"
          getPopupContainer={() => document.getElementById('profile-menu-dropdown-anchor') || document.body}
          disabled={disableAuthentication}
        >
          {renderUserData()}
        </Dropdown>
      </>
    );
  };

  return <StyledProfileMenu className="profile-menu">{renderLayout()}</StyledProfileMenu>;
};

export default ProfileMenu;
