import styled from 'styled-components';

export const StyledMunicipalProject = styled.div`
  .municipal_project_tab {
    .section_title {
      margin-top: 20px;
      margin-bottom: 15px;
    }
    .ant-tabs-nav {
      margin-bottom: 0;
      padding-top: 20px;
      padding-bottom: 20px !important;
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab {
        border: none !important;
      }
    }

    .ant-tabs-nav-operations {
      display: none !important;
    }
  }

  .wrapper-tab-bar {
    border-bottom: solid 1px ${({ theme }) => theme.border};

    .ant-tabs-nav:before {
      content: initial;
    }
  }

  button.action-print {
    display: flex;
    gap: 5px;
    color: ${({ theme }) => theme.textColor02};

    &:hover {
      &,
      i.portal-icon {
        background-color: initial !important;
        border: initial !important;
        color: ${({ theme }) => theme.brand02} !important;
      }
    }

    &:disabled {
      &,
      &:hover {
        &,
        i {
          background-color: initial !important;
          color: ${({ theme }) => theme.disabledText} !important;
        }
      }
    }
  }
`;
