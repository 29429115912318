import React from 'react';
import { Button, Card, Ellipsis } from 'ui';
import { EventInterface } from '../index';
import { useIntl } from 'react-intl';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import { Flex } from 'antd';

interface EventSectionItemProps {
  event: EventInterface;
  onClick?: (event: EventInterface) => void;
}
export const EventSectionItem = ({
  event: { picture: image, news_name: title, news_description: description, publishing_date: date },
  event,
  onClick,
}: EventSectionItemProps) => {
  const intl = useIntl();

  return (
    <Card className="event_item">
      <img src={image || '/aktualitate.jpeg'} alt="event iamge" />
      <Flex className="content_side" gap={10} vertical>
        <Ellipsis className="title" lines={2}>
          {title}
        </Ellipsis>
        <Ellipsis className="description" lines={4}>
          {parse(description)}
        </Ellipsis>
        <Flex className="footer" gap={15}>
          <span className="date"> {dayjs(date).format('DD.MM.YYYY.')}</span>
          <Button
            className="show-more"
            onClick={onClick && (() => onClick(event))}
            label={intl.formatMessage({ id: 'participation_budget.view_full_post' })}
            type="link"
            border={false}
          />
        </Flex>
      </Flex>
    </Card>
  );
};
