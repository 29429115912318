import styled from 'styled-components/macro';
import { TABLET_MAX_WIDTH } from 'utils/device';

export const StyledCloseButton = styled.div`
  .close-icon-wrapper {
    min-width: ${({ theme }) => theme.iconSize0};
    min-height: ${({ theme }) => theme.iconSize0};
    border: 1px solid ${({ theme }) => theme.customBorder};
    border-radius: 50%;
    padding: initial;
    background-color: ${({ theme }) => theme.closeBtnBg};

    &:hover {
      @media (hover: hover) {
        background-color: ${({ theme }) => theme.iconColor08};
      }
    }
  }

  .close-icon {
    font-size: ${({ theme }) => theme.iconSize3};
  }
`;
