import React from 'react';
import LayerGroup from 'ol/layer/Group';
import { SiderProps } from 'antd/es/layout/Sider';

export type SidebarWidthMapType = { [key in number]: SiderProps['width'] };

interface SelectedTapisDocumentType {
  dok_id: number;

  [key: string]: any;

  layerGroup: LayerGroup;
}

type OpenMapType = 'tapis' | 'lbis' | 'geo';

interface PayloadType {
  openedTapis?: boolean;
  selectedTapisDocument?: SelectedTapisDocumentType;
  selectedGeoproduct?: object;
  openTapisDocument?: boolean;
  openGeoproduct?: boolean;
  openParticipationBudget?: boolean;
  openMyParticipation?: boolean;
  sidebarWidth?: SidebarWidthMapType;
}

interface ActiveSectionPayloadInterface {
  type: 'UPDATE_ACTIVE_SECTION';
  payload: {
    activeHeaderButton: 'planned-documents' | 'geoproduct' | 'participation-budget';
  };
}

type PayloadAction = {
  type:
    | 'OPENED_TAPIS'
    | 'CLOSED_TAPIS'
    | 'SELECT_TAPIS_DOCUMENT'
    | 'UNSELECT_TAPIS_DOCUMENT'
    | 'OPEN_TAPIS'
    | 'CLOSE_TAPIS'
    | 'OPEN_GEOPRODUCT'
    | 'CLOSE_GEOPRODUCT'
    | 'OPEN_PARTICIPATION_BUDGET'
    | 'CLOSE_PARTICIPATION_BUDGET'
    | 'OPEN_PROJECT'
    | 'CLOSE_PROJECT'
    | 'OPEN_MUNICIPAL_PROJECT'
    | 'CLOSE_MUNICIPAL_PROJECT'
    | 'OPEN_PROJECT_VIEW'
    | 'CLOSE_PROJECT_VIEW'
    | 'OPEN_IDEA_VIEW'
    | 'CLOSE_IDEA_VIEW'
    | 'OPEN_VOTE_VIEW'
    | 'CLOSE_VOTE_VIEW'
    | 'OPEN_MY_PARTICIPATION'
    | 'OPEN_SUBMIT_PROJECT'
    | 'CLOSE_SUBMIT_PROJECT'
    | 'OPEN_SUBMIT_PROJECT_CREATE_FORM'
    | 'CLOSE_SUBMIT_PROJECT_CREATE_FORM'
    | 'OPEN_SUBMIT_PROJECT_LAST_STEP'
    | 'CLOSE_SUBMIT_PROJECT_LAST_STEP'
    | 'OPEN_SUBMIT_IDEA'
    | 'CLOSE_SUBMIT_IDEA'
    | 'CLOSE_MY_PARTICIPATION'
    | 'OPEN_PANNING_DOCUMENTS'
    | 'CLOSE_PANNING_DOCUMENTS'
    | 'OPEN_MY_PARTICIPATION_PROPOSALS'
    | 'CLOSE_MY_PARTICIPATION_PROPOSALS'
    | 'OPEN_TUTORIAL'
    | 'CLOSE_TUTORIAL'
    | 'OPEN_PROXY_MODAL'
    | 'CLOSE_PROXY_MODAL'
    | 'OPEN_SINGLE_GEOPRODUCT'
    | 'CLOSE_SINGLE_GEOPRODUCT'
    | 'UPDATE_RIGHT_SIDEBAR'
    | 'UPDATE_LEFT_SIDEBAR'
    | 'OPEN_SUBMIT_PROPOSAL'
    | 'CLOSE_SUBMIT_PROPOSAL';
  payload?: PayloadType;
};

type Action = PayloadAction | ActiveSectionPayloadInterface;

export type Dispatch = (action: Action) => void;

export type State = {
  activeHeaderButton?: string;
  openedTapis?: boolean;
  selectedTapisDocument?: SelectedTapisDocumentType;
  selectedGeoproduct: boolean;
  openTapisDocument?: boolean;
  openGeoproduct?: boolean;
  openParticipationBudget?: boolean;
  openProject?: boolean;
  openMunicipalProject?: boolean;
  openProjectView?: boolean;
  openIdeaView?: boolean;
  openVoteView?: boolean;
  openMyParticipation?: boolean;
  openSubmitProject?: boolean;
  openSubmitProjectCreateForm?: boolean;
  openSubmitProjectLastStep?: boolean;
  openSubmitIdea?: boolean;
  openedMapType: string;
  openPlannedDocuments?: boolean;
  openMyParticipationProposals?: boolean;
  openTutorial?: boolean;
  openProxyModal?: boolean;
  rightSidebars: SidebarWidthMapType;
  leftSidebars: SidebarWidthMapType;
  openSubmitProposal: boolean;
};

type OpenedTypeProviderProps = { children: React.ReactNode };

const OpenedTypeContext = React.createContext<State>(undefined!);
const OpenedTypeDispatchContext = React.createContext<Dispatch>(undefined!);

function userReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPENED_TAPIS': {
      return { ...state, openedTapis: true };
    }
    case 'CLOSED_TAPIS': {
      return { ...state, openedTapis: false };
    }
    case 'SELECT_TAPIS_DOCUMENT': {
      return { ...state, openTapisDocument: false, selectedTapisDocument: action?.payload?.selectedTapisDocument };
    }
    case 'UNSELECT_TAPIS_DOCUMENT': {
      return { ...state, selectedTapisDocument: undefined };
    }
    case 'OPEN_TAPIS': {
      return { ...state, openTapisDocument: true, activeHeaderButton: 'planned-documents', openedMapType: 'tapis' };
    }
    case 'CLOSE_TAPIS': {
      return { ...state, openTapisDocument: false };
    }
    case 'OPEN_GEOPRODUCT': {
      return { ...state, openGeoproduct: true, activeHeaderButton: 'geoproduct', openedMapType: 'geo' };
    }
    case 'CLOSE_GEOPRODUCT': {
      return { ...state, openGeoproduct: false };
    }
    case 'OPEN_PARTICIPATION_BUDGET': {
      return {
        ...state,
        openParticipationBudget: true,
        activeHeaderButton: 'participation-budget',
        openedMapType: 'lbis',
      };
    }
    case 'CLOSE_PARTICIPATION_BUDGET': {
      return { ...state, openParticipationBudget: false };
    }
    case 'OPEN_PROJECT': {
      return { ...state, openProject: true, activeHeaderButton: 'participation-budget', openedMapType: 'lbis' };
    }
    case 'CLOSE_PROJECT': {
      return { ...state, openProject: false };
    }
    case 'OPEN_MUNICIPAL_PROJECT': {
      return {
        ...state,
        openMunicipalProject: true,
        activeHeaderButton: 'participation-budget',
        openedMapType: 'lbis',
      };
    }
    case 'CLOSE_MUNICIPAL_PROJECT': {
      return { ...state, openMunicipalProject: false };
    }
    case 'OPEN_PROJECT_VIEW': {
      return { ...state, openProjectView: true, openedMapType: 'lbis', activeHeaderButton: 'participation-budget' };
    }
    case 'OPEN_IDEA_VIEW': {
      return { ...state, openIdeaView: true, openedMapType: 'lbis', activeHeaderButton: 'participation-budget' };
    }
    case 'OPEN_VOTE_VIEW': {
      return { ...state, openVoteView: true, openedMapType: 'lbis', activeHeaderButton: 'participation-budget' };
    }
    case 'CLOSE_PROJECT_VIEW': {
      return { ...state, openProjectView: false };
    }
    case 'CLOSE_IDEA_VIEW': {
      return { ...state, openIdeaView: false };
    }
    case 'CLOSE_VOTE_VIEW': {
      return { ...state, openVoteView: false };
    }
    case 'OPEN_MY_PARTICIPATION': {
      return { ...state, openMyParticipation: true, openedMapType: 'lbis', activeHeaderButton: 'participation-budget' };
    }
    case 'CLOSE_MY_PARTICIPATION': {
      return { ...state, openMyParticipation: false };
    }
    //special case for my particpation proposals tab
    case 'OPEN_MY_PARTICIPATION_PROPOSALS': {
      return {
        ...state,
        openMyParticipationProposals: true,
        openedMapType: 'tapis',
        activeHeaderButton: 'planned-documents',
      };
    }
    case 'CLOSE_MY_PARTICIPATION_PROPOSALS': {
      return {
        ...state,
        openMyParticipationProposals: false,
      };
    }
    case 'OPEN_SUBMIT_PROJECT': {
      return { ...state, openSubmitProject: true, openedMapType: 'lbis', activeHeaderButton: 'participation-budget' };
    }
    case 'CLOSE_SUBMIT_PROJECT': {
      return { ...state, openSubmitProject: false };
    }
    case 'OPEN_SUBMIT_PROJECT_CREATE_FORM': {
      return {
        ...state,
        openSubmitProjectCreateForm: true,
        openedMapType: 'lbis',
        activeHeaderButton: 'participation-budget',
      };
    }
    case 'CLOSE_SUBMIT_PROJECT_CREATE_FORM': {
      return { ...state, openSubmitProjectCreateForm: false };
    }
    case 'OPEN_SUBMIT_IDEA': {
      return { ...state, openSubmitIdea: true, openedMapType: 'lbis', activeHeaderButton: 'participation-budget' };
    }
    case 'CLOSE_SUBMIT_IDEA': {
      return { ...state, openSubmitIdea: false };
    }
    case 'OPEN_SUBMIT_PROJECT_LAST_STEP': {
      return {
        ...state,
        openSubmitProjectLastStep: true,
        openedMapType: 'lbis',
        activeHeaderButton: 'participation-budget',
      };
    }
    case 'CLOSE_SUBMIT_PROJECT_LAST_STEP': {
      return { ...state, openSubmitProjectLastStep: false };
    }

    case 'OPEN_PANNING_DOCUMENTS': {
      return {
        ...state,
        openPlannedDocuments: true,
        openTapisDocument: false,
        activeHeaderButton: 'planned-documents',
      };
    }
    case 'CLOSE_PANNING_DOCUMENTS': {
      return { ...state, openPlannedDocuments: false };
    }
    case 'OPEN_TUTORIAL': {
      return { ...state, openTutorial: true };
    }
    case 'CLOSE_TUTORIAL': {
      return { ...state, openTutorial: false };
    }
    case 'OPEN_PROXY_MODAL': {
      return { ...state, openProxyModal: true };
    }
    case 'CLOSE_PROXY_MODAL': {
      return { ...state, openProxyModal: false };
    }
    case 'OPEN_SINGLE_GEOPRODUCT': {
      return { ...state, selectedGeoproduct: true };
    }
    case 'CLOSE_SINGLE_GEOPRODUCT': {
      return { ...state, selectedGeoproduct: false };
    }
    case 'UPDATE_RIGHT_SIDEBAR': {
      const { sidebarWidth = {} } = action.payload || {};
      const rightSidebars = Object.fromEntries(
        Object.entries({ ...state.rightSidebars, ...sidebarWidth }).filter(([_, value]) => value)
      );

      return { ...state, rightSidebars };
    }
    case 'UPDATE_LEFT_SIDEBAR': {
      const { sidebarWidth = {} } = action.payload || {};
      const leftSidebars = Object.fromEntries(
        Object.entries({ ...state.leftSidebars, ...sidebarWidth }).filter(([_, value]) => value)
      );

      return { ...state, leftSidebars };
    }
    case 'OPEN_SUBMIT_PROPOSAL': {
      return { ...state, openSubmitProposal: true };
    }
    case 'CLOSE_SUBMIT_PROPOSAL': {
      return { ...state, openSubmitProposal: false };
    }
    case 'UPDATE_ACTIVE_SECTION': {
      const { activeHeaderButton } = action.payload;
      const mapSectionMap: { [key in ActiveSectionPayloadInterface['payload']['activeHeaderButton']]: OpenMapType } = {
        'planned-documents': 'tapis',
        'participation-budget': 'lbis',
        geoproduct: 'geo',
      };

      return { ...state, activeHeaderButton, openedMapType: mapSectionMap[activeHeaderButton] };
    }
  }
}

function OpenedTypeProvider({ children }: OpenedTypeProviderProps) {
  const initialState = {
    activeHeaderButton: 'planned-documents',
    openedTapis: false,
    selectedTapisDocument: undefined,
    selectedGeoproduct: false,
    openTapisDocument: false,
    openGeoproduct: false,
    openParticipationBudget: false,
    openPlannedDocuments: false,
    openedMapType: 'tapis',
    openTutorial: false,
    openProxyModal: false,
    rightSidebars: {},
    leftSidebars: {},
    openSubmitProposal: false,
  };

  const [state, dispatch] = React.useReducer(userReducer, initialState);

  return (
    <OpenedTypeContext.Provider value={state}>
      <OpenedTypeDispatchContext.Provider value={dispatch}>{children}</OpenedTypeDispatchContext.Provider>
    </OpenedTypeContext.Provider>
  );
}

const useOpenedTypeState = () => React.useContext(OpenedTypeContext);
const useOpenedTypeDispatch = () => React.useContext(OpenedTypeDispatchContext);

export { OpenedTypeProvider, useOpenedTypeDispatch, useOpenedTypeState };
