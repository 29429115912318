import { COLORS } from 'styles/globals';
import { ThemeConfigType } from './theme';
import { ValueTypes } from 'ol/style/expressions';
import COLOR = ValueTypes.COLOR;

export const yellowThemeColors = {
  gray01: COLORS.yellow,
  gray02: COLORS.yellow,
  gray03: COLORS.yellow,
  gray12: COLORS.gray12,
  gray13: COLORS.gray12,

  brand01: COLORS.gray12,
  brand02: COLORS.gray12,
  brand02Hover: COLORS.gray12,
  brand03: COLORS.gray12,
  brand04: COLORS.gray12,
  brand05: COLORS.gray12,
  brand06: COLORS.gray12,
  brand07: COLORS.gray12,
  brand08: COLORS.gray12,

  brand02Light: COLORS.black,
  brand02Light2: COLORS.yellow,
  brand02Light3: COLORS.yellow,

  grayInactive: COLORS.gray12,

  errors: COLORS.errors,
  errorsLight: COLORS.errorsLight,
  warning: COLORS.warning,
  breadcumpColor: COLORS.gray12,
  buttonHover: COLORS.gray12,

  textColor01: COLORS.gray12,
  textColor02: COLORS.gray12,
  textColor03: COLORS.yellow,
  textColor04: COLORS.gray12,
  textColor05: COLORS.yellow,
  textColor06: COLORS.gray12,
  textColor07: COLORS.yellow,
  textColor08: COLORS.gray12,
  textColor09: COLORS.gray12,
  textColor10: COLORS.gray12,
  textColor11: COLORS.gray12,
  textColor12: COLORS.yellow,

  button01: COLORS.gray12,
  button02: COLORS.gray12,

  switchColor01: COLORS.gray12,
  switchColor02: COLORS.yellow,
  switchColor03: COLORS.yellow,
  switchColor04: COLORS.yellow,

  boxShadow: 'transparent',
  transparent: COLORS.transparent,

  placeholder: COLORS.yellowPlaceholder,
  rowSelectBackground: COLORS.lightYellow,
  checkedCheckboxBackground: COLORS.black,
  darkModeBorder: COLORS.gray12,

  iconColor01: COLORS.gray12,
  iconColor02: COLORS.gray12,
  iconColor03: COLORS.gray12,
  iconColor04: COLORS.gray12,
  iconColor05: COLORS.gray12,
  iconColor06: COLORS.yellow,
  iconColor07: COLORS.iconColor07,
  iconColor08: COLORS.gray12,
  iconColor09: COLORS.gray12,
  iconColor10: COLORS.yellow,

  tableHeaderColor: COLORS.yellow,

  disabledText: COLORS.gray12,
  disabledBorder: COLORS.gray12,
  disabledBackground: COLORS.yellow,
  disabledBgForSelect: COLORS.lightYellow,

  selectOptionColor: COLORS.gray12,

  portalBackground: COLORS.yellow,
  componentsBackground: COLORS.yellow,

  border: COLORS.gray12,
  border2: COLORS.gray12,
  border3: COLORS.gray12,
  border4: COLORS.gray12,
  border5: COLORS.gray12,
  customBorder: COLORS.gray12,

  alert01: COLORS.yellow,
  alert02: COLORS.yellow,
  alert03: COLORS.yellow,
  alert04: COLORS.yellow,

  alertBorder01: COLORS.gray12,
  alertBorder02: COLORS.gray12,
  alertBorder03: COLORS.gray12,
  alertBorder04: COLORS.gray12,

  alertIcon01: COLORS.gray12,
  alertIcon02: COLORS.gray12,
  alertIcon03: COLORS.gray12,
  alertIcon04: COLORS.gray12,

  tablePaginationActiveColor: COLORS.black,

  tag01: COLORS.gray12,
  tag02: COLORS.gray12,
  tag03: COLORS.gray12,

  tagTextColor0: COLORS.yellow,

  tagBackground01: COLORS.yellow,
  tagBackground02: COLORS.yellow,
  tagBackground03: COLORS.yellow,
  tagBackground05: COLORS.gray12,

  tagBorder01: COLORS.gray12,
  tagBorder02: COLORS.gray12,
  tagBorder03: COLORS.gray12,
  tagBorder04: COLORS.gray12,

  yellow: COLORS.yellow,
  black: COLORS.black,
  blackBg: COLORS.blackBg,
  white: COLORS.white,

  rowGreen: COLORS.gray12,
  rowRed: COLORS.gray12,

  scroll: COLORS.yellow,
  solitude: COLORS.gray12,
  statusYellow: COLORS.gray12,
  statusBrown: COLORS.gray12,
  statusGreen: COLORS.gray12,
  statusBlue: COLORS.gray12,
  statusViolet: COLORS.gray12,
  statusRed: COLORS.gray12,
  statusGray: COLORS.gray12,
  statusDarkBlue: COLORS.gray12,
  statusDarkGreen: COLORS.gray12,

  borderColor: COLORS.black,

  statusRealizedColor: COLORS.black,
  statusUnsupportedColor: COLORS.black,

  dropdownFieldBackground: COLORS.lightYellow,
  tableHoverBackground: COLORS.yellow,
  timePickerCellBg: COLORS.brand02,
  timePickerCellText: COLORS.textColor03,
  linkColor: COLORS.gray12,
  headerBgColor: COLORS.yellow,
  plannedDocsBg: COLORS.yellow,
  closeBtnBg: COLORS.transparent,
  scrollToTopBtnBg: COLORS.yellow,
  projectTagBorder: COLORS.black,
  scrollShadowBg: COLORS.scrollShadowBgBlack,
  deleteButtonColor: COLORS.yellow,
  deleteButtonBg: COLORS.gray12,
};

export const yellowThemeConfig: ThemeConfigType = {
  mobile: {
    components: {
      Button: {
        colorPrimaryActive: '',
        colorPrimaryHover: '',
        colorText: '',
        colorTextLightSolid: '',
        colorBgTextHover: '',
      },
    },
  },
};
