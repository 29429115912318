import { Button, ButtonProps } from 'ui';
import React from 'react';
import { ClassNameUtil } from 'utils/className';
import { StyledCloseButton } from './styles';

const CloseButton = ({ className = '', icon = 'xmark', ...props }: ButtonProps) => {
  return (
    <StyledCloseButton>
      <Button
        iconClassName="close-icon"
        {...props}
        className={ClassNameUtil.create(['close-icon-wrapper', className]).getClassName()}
        icon={icon}
      />
    </StyledCloseButton>
  );
};

export default CloseButton;
