import styled from 'styled-components/macro';
import { TABLET_MAX_WIDTH } from 'utils/device';

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 3rem;

  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    gap: 12px;
  }

  Button {
    flex: auto;

    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      width: 100%;
    }
  }
`;

export const InformationText = styled.div`
  margin: 0rem 7rem 0.4rem 0.4rem;

  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    margin: 0;
  }
`;

export const AgreeText = styled.div`
  margin-bottom: 1rem;
  font-weight: bold;
`;

export const MainBlock = styled.div`
  .title {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .modal-header {
    padding: 3px 3px !important;
  }

  .accessibility-menu .menu-button {
    flex-direction: column;
    border-left: 1px solid #e6e7ee;
    padding-left: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

export const PrivacyButton = styled.a`
  color: ${({ theme }) => theme.brand02};

  &:hover {
    color: ${({ theme }) => theme.brand02};
  }
`;

export const DetailedInformation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  font-weight: bold;
  padding: 0.8rem;
  cursor: pointer;
  background-color: ${({ theme }) => theme.dropdownFieldBackground};
`;

export const DetailedInformationTableContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;

  .ant-table-thead .ant-table-cell,
  .ant-table-row .ant-table-cell {
    background: ${({ theme }) => theme.dropdownFieldBackground};
    color: ${({ theme }) => theme.textColor01};

    &.ant-table-cell-row-hover {
      background: ${({ theme }) => theme.tableHoverBackground};
    }
  }
`;
