import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormProps as AntdFormProps } from 'antd/es/form/Form';
import { useDebounce } from 'utils/useDebounce';
import toastMessage from 'utils/toastMessage';
import useQueryApiClient, { RequestProps, UseQueryApiClientProps } from 'utils/useQueryApiClient';
import type { FormInstance } from 'antd/es/form/hooks/useForm';
import { useIntl } from 'react-intl';

type SaveStatus = 'loading' | 'success' | 'error' | undefined;

interface FormProps extends Omit<AntdFormProps, 'children' | 'autoSave' | 'onFinish' | 'form'> {
  request: RequestProps;
  form: FormInstance;
  saveAfter: number;
  formatData?: (formData: any) => any;
  children?: React.ReactNode;
  onSuccess?: UseQueryApiClientProps['onSuccess'];
  onError?: UseQueryApiClientProps['onError'];
}

const AutoSaveForm = ({ children, request, formatData, form, onSuccess, onError, saveAfter, ...props }: FormProps) => {
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const [saveStatus, setSaveStatus] = useState<SaveStatus>();

  const formData = Form.useWatch(undefined, { form, preserve: true });
  const intl = useIntl();
  const debouncedFormData = useDebounce(formData, saveAfter);
  const { appendData: saveData, isLoading: isSaveLoading } = useQueryApiClient({
    request,
    onSuccess: (response) => {
      setSaveStatus('success');
      onSuccess && onSuccess(response);
    },
    onError: (response) => {
      setSaveStatus('error');
      onError && onError(response);
    },
  });

  useEffect(() => {
    const canMount = debouncedFormData && !hasMounted;

    canMount && setHasMounted(true);
  }, [debouncedFormData]);

  useEffect(() => {
    handleOnFinish(debouncedFormData);
  }, [debouncedFormData]);

  useEffect(() => {
    switch (true) {
      case isSaveLoading:
        displayMessage('loading');
        break;

      case !!saveStatus:
        displayMessage(saveStatus);
    }
  }, [saveStatus, isSaveLoading]);

  const displayMessage = (type: SaveStatus) => {
    const messageKeyMap = {
      loading: { content: 'general.changes_saving', key: 'LOADING_MESSAGE_KEY' },
      success: { content: 'general.changes_saved', key: 'SUCCESS_MESSAGE_KEY' },
      error: { content: 'general.changes_not_saved', key: 'ERROR_MESSAGE_KEY' },
    };

    if (!type) {
      return;
    }

    Object.values(messageKeyMap).forEach(({ key }) => toastMessage.destroy(key));
    toastMessage[type](intl.formatMessage({ id: messageKeyMap[type].content }), {
      key: messageKeyMap[type].key,
      duration: 3,
    });
  };

  const handleOnFinish = (values: any) => {
    if (!hasMounted || !Object.keys(formData || {}).length) return;

    setSaveStatus('loading');
    saveData(formatData ? formatData(values) : values);
  };

  return (
    <Form {...props} form={form}>
      {children}
    </Form>
  );
};

export default AutoSaveForm;
