import { Select, SelectProps } from 'ui';
import React from 'react';
import { Organization, useOrganizationsState } from 'contexts/OrganizationsContext';
import { useIntl } from 'react-intl';
import { disable } from 'ol/rotationconstraint';

export interface OrganizationsSelectProps extends SelectProps {
  filterItems?: (items: Organization[]) => Organization[];
}

export const OrganizationsSelect = ({
  label,
  placeholder,
  name,
  mode,
  loading,
  onChange,
  disabled,
  filterItems = (items) => items,
}: OrganizationsSelectProps) => {
  const intl = useIntl();
  const { organizations, isLoading: itemsLoading } = useOrganizationsState();

  return (
    <Select
      mode={mode}
      label={label}
      name={name}
      onChange={onChange}
      placeholder={placeholder || intl.formatMessage({ id: 'my_participation.my_budget.choose_a_municipality' })}
      loading={itemsLoading || loading}
      options={filterItems(organizations).map(({ name: label, atvk_id: value }: Organization) => ({ label, value }))}
      disabled={disabled}
    />
  );
};
