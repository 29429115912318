import { useState, useContext, useEffect, useCallback } from 'react';
import OlLayerBase from 'ol/layer/Base';
import LayerGroup from 'ol/layer/Group';
import Tile from 'ol/layer/Tile';
import Image from 'ol/layer/Image';
import MapContext from 'contexts/MapContext';
import { useOpenedTypeState } from 'contexts/OpenedTypeContext';
import {
  getDefaultBackgroundLayers,
  getDefaultBackgroundLayersForTAPIS,
  getDefaultBackgroundLayersForLBIS,
  HOME_LAYER_GROUP,
  BASE_LAYER_GROUP,
  MapLayerId,
} from 'utils/mapUtils';
import { StyledLayerSwitcher, VerticalLine } from './styles';
import { Button } from 'ui';
import { useSystemSettingState } from 'contexts/SystemSettingContext';
import { useIntl } from 'react-intl';
import { ClassNameUtil } from 'utils/className';

const defaultLayers = getDefaultBackgroundLayers();
const defaultTAPISLayers = getDefaultBackgroundLayersForTAPIS();
const defaultLBISLayers = getDefaultBackgroundLayersForLBIS();

const getCurrentLayer = (layerGroup: LayerGroup, currentLayer: string): any =>
  layerGroup &&
  layerGroup
    .getLayers()
    .getArray()
    .find((l) => l.get('id') === currentLayer);

const getNextLayer = (layerGroup: LayerGroup, currentLayer: string): MapLayerId => {
  const layerNames: string[] = layerGroup
    .getLayers()
    .getArray()
    .map((l) => l.get('id'));
  const currentLayerIndex = layerNames.indexOf(currentLayer);

  if (currentLayerIndex < 0) {
    return 'empty';
  }

  return layerNames[(currentLayerIndex + 1) % layerNames.length] as MapLayerId;
};

interface LayerSwitcherProps {
  setIsOpenLayerSettings: Function;
  visibleLayers?: string[];
}

export const LayerSwitcher = ({ setIsOpenLayerSettings, visibleLayers }: LayerSwitcherProps) => {
  const map = useContext(MapContext);
  const { openedMapType, openedTapis, openGeoproduct } = useOpenedTypeState();
  const layers =
    openedMapType === 'tapis' ? defaultTAPISLayers : openedMapType === 'lbis' ? defaultLBISLayers : defaultLayers;
  const {
    device: { isMobile },
  } = useSystemSettingState();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [layerGroup, setLayerGroup] = useState<LayerGroup>();
  const [currentLayer, setCurrentLayer] = useState<MapLayerId>('empty');
  const [loading, setLoading] = useState<number>(0);
  const [isVisibleLayersApplied, setIsVisibleLayersApplied] = useState<boolean>(false);

  const addLoading = useCallback(() => {
    setLoading((l) => l + 1);
  }, []);
  const addLoaded = useCallback(() => {
    setLoading((l) => l - 1);
  }, []);
  const resetLoading = useCallback(() => {
    setLoading(0);
  }, []);
  const intl = useIntl();

  // converts layers to layergroup
  useEffect(() => {
    if (map && layers.length > 0) {
      setLayerGroup(
        new LayerGroup({
          properties: {
            id: BASE_LAYER_GROUP,
          },
          layers,
        })
      );

      // get first visible layer
      const visibleLayer = layers.find((l) => l.getVisible());

      if (visibleLayer) {
        setCurrentLayer(visibleLayer.get('id'));
      }

      const setLoadingEvents = (l: OlLayerBase) => {
        if (l instanceof Tile) {
          const src = l.getSource();
          if (src) {
            src.on('tileloadstart', addLoading);
            src.on('tileloadend', addLoaded);
            src.on('tileloaderror', addLoaded);
          }
        }

        if (l instanceof Image) {
          const src = l.getSource();
          src.on('imageloadstart', addLoading);
          src.on('imageloadend', addLoaded);
          src.on('imageloaderror', addLoaded);
        }

        if (l instanceof LayerGroup) {
          l.getLayers().forEach((ll) => setLoadingEvents(ll));
        }
      };

      layers.forEach((l) => setLoadingEvents(l));

      return () => {
        resetLoading();
        setLayerGroup(undefined);
        setCurrentLayer('empty');
      };
    }
  }, [map, layers, addLoading, addLoaded, resetLoading]);

  // adds/removes layer group in OL Map
  useEffect(() => {
    if (map && layerGroup) {
      map.addLayer(layerGroup);

      return () => {
        map.removeLayer(layerGroup);
      };
    }
  }, [map, layerGroup]);

  // set current layer to be visible on map
  useEffect(() => {
    if (layerGroup) {
      const targetLayer = getCurrentLayer(layerGroup, currentLayer);

      layerGroup.getLayers().forEach((l) => l.setVisible(false));

      if (targetLayer?.get('id') === 'empty') {
        return;
      }

      targetLayer?.setVisible(true);
    }
  }, [currentLayer, layerGroup]);

  //reset isVisibleLayersApplied when only visibleLayers change
  useEffect(() => {
    if (visibleLayers) {
      setIsVisibleLayersApplied(false);
    }
  }, [visibleLayers]);

  // sets visible layers from props
  useEffect(() => {
    if (map && layerGroup && visibleLayers && !isVisibleLayersApplied) {
      const baseKey = `${HOME_LAYER_GROUP}->${BASE_LAYER_GROUP}->`;
      const visibleLayer = visibleLayers
        .map((k) => (k.startsWith(baseKey) ? k.substring(baseKey.length) : null))
        .filter((k) => !!k);

      if (visibleLayer.length > 0 && visibleLayer[0]) {
        setCurrentLayer(visibleLayer[0] as MapLayerId);
      } else {
        openedTapis ? setCurrentLayer('empty') : setCurrentLayer('osm');
      }

      setIsVisibleLayersApplied(true);
    }
  }, [map, layerGroup, visibleLayers]);

  if (!layerGroup) {
    return null;
  }

  const currentOLLayer = getCurrentLayer(layerGroup, currentLayer);

  if (openedMapType === 'lbis') {
    return null;
  }

  const nextLayerId = getNextLayer(layerGroup, currentLayer as string);

  const renderToggleBtn = (key: any, loading: boolean = false) => {
    return (
      <div className="btn-wrapper toggle">
        <Button
          loading={loading}
          key={key}
          label={!isMobile && intl.formatMessage({ id: 'layer_switcher.switcher_title' })}
          icon="layer-group"
          faBase="far"
          className={ClassNameUtil.create(['toggle', !isMobile && nextLayerId]).getClassName()}
          onClick={() => setCurrentLayer(nextLayerId)}
        />
      </div>
    );
  };

  return (
    <StyledLayerSwitcher
      className={
        'ol-unselectable ol-control' + (isExpanded ? ' expanded' : '') + (openGeoproduct ? ' geo-product' : '')
      }
      onMouseEnter={() => setIsExpanded(!isMobile)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      {!currentOLLayer
        ? renderToggleBtn(`layer-button ${nextLayerId}`)
        : loading > 0
        ? renderToggleBtn(`current_loading_${nextLayerId}`, true)
        : renderToggleBtn(`current_${nextLayerId}`)}

      {isExpanded ? (
        <>
          <VerticalLine />
          {layerGroup
            .getLayers()
            .getArray()
            .map((l) => (
              <div className={'btn-wrapper' + (currentLayer === l.get('id') ? ' selected' : '')}>
                <Button
                  label={!isMobile && intl.formatMessage({ id: 'layer_switcher.switcher_title' })}
                  icon="layer-group"
                  faBase="far"
                  key={l.get('id')}
                  className={l.get('id') + ' options'}
                  onClick={() => setCurrentLayer(l.get('id'))}
                  title={l.get('title')}
                />
              </div>
            ))}
          <Button
            className={'more options layer-button'}
            label={intl.formatMessage({ id: 'layer_switcher.more_title' })}
            icon="layer-group"
            faBase="far"
            onClick={() => setIsOpenLayerSettings(true)}
            title={intl.formatMessage({ id: 'layer_switcher.more_tooltip' })}
          />
        </>
      ) : null}
    </StyledLayerSwitcher>
  );
};

export default LayerSwitcher;
