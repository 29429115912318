import React, { useState } from 'react';
import { Option, Select, SelectProps } from 'ui';
import { StyledTagSelect } from './styles';
import useQueryApiClient, { RequestProps } from 'utils/useQueryApiClient';
import { Form } from 'antd';

interface ApiSelectProps<T> extends SelectProps {
  request: RequestProps;
  formatOptions?: (items: T[], searchString: string) => T[];
  onSuccess?: (response: T[], passOnSuccess?: any) => void;
}

export const ApiSelect = <T extends Option>({ request, formatOptions, onSuccess, ...props }: ApiSelectProps<T>) => {
  const [searchString, setSearchString] = useState<string>('');
  const [items, setItems] = useState<T[]>([]);

  const { isLoading: itemsLoading } = useQueryApiClient<T[]>({
    request,
    onSuccess: (response) => {
      if (onSuccess) {
        onSuccess(response ? response : []);
      }

      setItems(response ? response : []);
    },
  });

  const selectedItems: Array<T['value']> = Form.useWatch(props.name) || [];
  const disabled = itemsLoading;

  const getOptions = (): T[] => {
    const formattedOptions = formatOptions ? formatOptions(items, searchString) : items;
    const selected = items.filter(({ value }) => selectedItems.includes(value));
    const notSelected = formattedOptions.filter(({ value }) => !selectedItems.includes(value));

    return selected.concat(notSelected);
  };

  return (
    <StyledTagSelect>
      <Select
        value={selectedItems as string[]}
        mode="multiple"
        options={getOptions()}
        loading={disabled}
        disabled={disabled}
        onSearch={setSearchString}
        onSelect={() => setSearchString('')}
        virtual={false}
        {...props}
      />
    </StyledTagSelect>
  );
};

export default ApiSelect;
