import React, { useEffect, useState } from 'react';
import { TabPane, Tabs, Tooltip } from 'ui';
import { useIntl } from 'react-intl';
import { SubmitedProjects } from './components/SubmitedProjects';
import TabMyProfile from './components/TabNotifications';
import TabMyProposals from './components/TabMyProposals';
import ProposalItem from './components/TabMyProposals/components/ProposalItem';
import MyVotesProjects from './components/MyVotesProjects';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Ideas from './components/Ideas';

type ActiveKeyType = 'notices_tab' | 'my_proposals_tab' | 'my_votes_tab' | 'submitted_projects_tab' | 'ideas_tab';

export const getTabUrl = (activeKey: ActiveKeyType) => {
  return `/main?my-participation=open&tab=${activeKey}&temp=temp`;
};

export const PROPOSAL_URl_KEY = 'id';

const MyParticipationTabs = () => {
  let [searchParams] = useSearchParams();
  const [proposalItem, setProposalItem] = useState();
  const [proposalItems, setProposalItems] = useState<any[]>([]);
  const activeTabFromQuery = searchParams.get('tab');
  const [activeTab, setActiveTab] = useState<string>(activeTabFromQuery ? activeTabFromQuery : 'notices_tab');

  const navigate = useNavigate();
  const intl = useIntl();
  const [searchPrams] = useSearchParams();

  useEffect(() => {
    if (activeTabFromQuery) {
      setActiveTab(activeTabFromQuery);
    }
  }, [activeTabFromQuery]);

  useEffect(() => {
    const activeProposalId = searchParams.get(PROPOSAL_URl_KEY);

    setProposalItem(
      activeProposalId ? proposalItems.find(({ id }: { id: number }) => id === +activeProposalId) : undefined
    );
  }, [searchPrams, proposalItems]);

  const onTabChange = (activeKey: ActiveKeyType) => {
    setActiveTab(activeKey);
    navigate(getTabUrl(activeKey));
  };

  return (
    <Tabs
      type="card"
      onChange={(activeKey) => onTabChange(activeKey as ActiveKeyType)}
      activeKey={activeTab}
      className="my-participation-tabs no-border"
    >
      <TabPane
        tab={intl.formatMessage({
          id: 'my_participation.tab_title.notices',
        })}
        key="notices_tab"
      >
        <TabMyProfile />
      </TabPane>

      <TabPane
        tab={
          <Tooltip title={intl.formatMessage({ id: 'my_participation.my_participation.my_proposals_tooltip' })}>
            {intl.formatMessage({
              id: 'my_participation.tab_title.my_proposals',
            })}
          </Tooltip>
        }
        key="my_proposals_tab"
      >
        {proposalItem ? (
          <ProposalItem proposalItem={proposalItem} />
        ) : (
          <TabMyProposals setProposalItems={setProposalItems} />
        )}
      </TabPane>
      <TabPane
        tab={intl.formatMessage({
          id: 'my_participation.tab_title.my_votes',
        })}
        key="my_votes_tab"
        destroyInactiveTabPane
      >
        <MyVotesProjects />
      </TabPane>
      <TabPane
        tab={intl.formatMessage({
          id: 'my_participation.tab_title.submitted_projects',
        })}
        key="submitted_projects_tab"
        destroyInactiveTabPane
      >
        <SubmitedProjects />
      </TabPane>
      <TabPane
        tab={intl.formatMessage({
          id: 'my_participation.tab_title.ideas',
        })}
        key="ideas_tab"
      >
        <Ideas isOpen={activeTab === 'ideas_tab'} />
      </TabPane>
    </Tabs>
  );
};

export default MyParticipationTabs;
