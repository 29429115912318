import React, {
  type Dispatch,
  type MutableRefObject,
  ReactNode,
  Ref,
  type SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PlannedDocumentWrapper, StyledList, StyledListItem } from './style';
import VirtualList from 'rc-virtual-list';
import useQueryApiClient from 'utils/useQueryApiClient';
import { listStatuses } from 'config/config';
import { Tag } from '../tag';
import { Spinner } from '../spinner';
import { Flex, Popover } from 'antd';
import { Icon } from '../icon';
import { ListRef } from 'rc-virtual-list/lib/List';

export interface ListProps {
  url: string;
  pageSize?: number;
  height?: number;
  onSelect: Function;
  dataKey?: string;
  selectedTitle?: Function;
  isOpenDocument?: boolean;
  itemHeight?: number;
  hoverItem?: boolean;
  isPlanned?: boolean;
  captcha?: {
    captchaEnabled?: boolean;
    captchaKeyIsSet?: boolean | null;
    setCaptchaEnabled: Dispatch<SetStateAction<boolean | undefined>>;
    disableOnMount?: boolean;
  };
  useClientHeight?: {
    ref?: MutableRefObject<any>;
    actionBarRef?: MutableRefObject<any>;
    contentRef?: MutableRefObject<any>;
    enable: boolean;
  };
  className?: string;
  properties: {
    prefix?: string;
    title: string;
    shortTitle?: string;
    subtitle?: string;
    status?: string;
    id: string;
  };
  filterParams?: any;
  disableOnMount?: boolean;
  renderItem?: (item: any, options: ItemOptions) => ReactNode;
  formatItems?: (items: any[], response?: any) => any[];
  scrollToTop?: boolean;
}

export interface ItemOptions {
  title?: string;
  subtitle?: string;
  isStatus?: boolean;
  status?: {
    title?: string;
    shortTitle?: string;
    color?: string;
  };
}

const containerHeight = 400;

export const List = ({
  url,
  pageSize,
  height,
  onSelect,
  dataKey = 'data',
  properties,
  captcha,
  useClientHeight,
  itemHeight,
  filterParams,
  selectedTitle,
  isOpenDocument,
  disableOnMount,
  hoverItem,
  isPlanned,
  renderItem,
  className,
  formatItems = (items: any[]) => items,
  scrollToTop: enableScrollToTop,
}: ListProps) => {
  const [data, setData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState(0);
  const [_height, setHeight] = useState<number>(height || containerHeight);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [responses, setResponses] = useState<any[]>([]);

  const listRef = useRef<ListRef>();
  const _disableOnMount = useMemo(() => {
    return !!captcha
      ? !!disableOnMount
        ? (!!captcha?.captchaEnabled || captcha?.captchaEnabled === undefined) && disableOnMount
        : !!captcha?.captchaEnabled || captcha?.captchaEnabled === undefined
      : disableOnMount;
  }, [captcha, disableOnMount]);

  const { refetch, isLoading } = useQueryApiClient({
    request: {
      url: url,
      data: {
        page: currentPage,
        pageSize: pageSize || 100,
        count: 10,
        ...filterParams,
      },
      disableOnMount: true,
    },
    onSuccess: (response, { isInfiniteLoaded }) => {
      const items = response[dataKey] || [];

      setResponses((old) => (isInfiniteLoaded ? [...old, response] : [response]));
      setData((old: any[]) => (isInfiniteLoaded ? [...old, ...items] : items));
    },
  });

  const { isLoading: captchaIsLoading } = useQueryApiClient({
    request: {
      url: `api/v1/captcha/show`,
      method: 'GET',
      disableOnMount: captcha?.disableOnMount,
    },
    onSuccess: (response) => {
      if (parseInt(response.geoproduct.value) == 1) {
        captcha?.setCaptchaEnabled?.(true);
      } else {
        captcha?.setCaptchaEnabled?.(false);
      }
    },
  });

  useEffect(() => {
    if (!_disableOnMount) {
      refetch({ isInfiniteLoaded: currentPage > 1 });
    }
  }, [pageSize, currentPage, url, _disableOnMount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }

    if (!filterParams) {
      return;
    }

    refetch();
  }, [filterParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (useClientHeight && useClientHeight?.enable) {
      setHeight(useClientHeight.ref?.current?.clientHeight || containerHeight);
    } else if (height) {
      setHeight(height);
    } else {
      setHeight(containerHeight);
    }
  }, [data, useClientHeight, height]);

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    const scrolledPixels = Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop);
    const scrollablePixels = Math.round(_height);
    // add +/- correction because of math rounding
    const isBetween = scrolledPixels >= scrollablePixels - 1 && scrolledPixels <= scrollablePixels + 1;

    setIsScrolled(e.currentTarget.scrollTop !== 0);

    if (isBetween && !!data.length) {
      setCurrentPage((old) => old + 1);
    }
  };

  const innerOnSelect = (item: any) => {
    if (selectedTitle) {
      selectedTitle(computedValue(item, 'title'));
    }

    setSelected(computedValue(item, 'id'));
    onSelect(computedValue(item, 'id'));
  };

  const scrollToTop = () => {
    listRef.current?.scrollTo({ top: 0 });
  };

  const getType = (options: any): 'title' | 'shortTitle' | 'color' | 'searchStatus' => {
    const { isTitle = true, isColor = false, isCompact = false } = options;

    switch (true) {
      case isTitle:
        return isCompact ? 'shortTitle' : 'title';
      case isColor:
        return 'color';
      default:
        return 'searchStatus';
    }
  };

  const computedStatus = (statusCode: string, options: any = {}) => {
    const status = listStatuses.find((status) => status.searchStatus === statusCode);

    if (status) {
      return status[getType(options)];
    }

    return status;
  };

  const statusExistsInStatusList = (statusCode: string) => {
    return listStatuses.some((status) => status.searchStatus === statusCode);
  };

  const computedValue = (item: any, propertyName: 'title' | 'subtitle' | 'status' | 'id') => {
    return properties.prefix
      ? item[properties.prefix][properties[propertyName] as string]
      : item[properties[propertyName] as string];
  };

  const hoverContent = (item: any) => {
    if (hoverItem) {
      return (
        <PlannedDocumentWrapper>
          <span>{computedValue(item, 'title')}</span>
        </PlannedDocumentWrapper>
      );
    }
    return null;
  };

  const renderListItem = (item: any, index: number) => {
    if (typeof item === 'function') {
      return item();
    }

    const options = {
      title: computedValue(item, 'title'),
      subtitle: computedValue(item, 'subtitle'),
      isStatus: !!(properties.status && statusExistsInStatusList(computedValue(item, 'status'))),
      status: {
        title: computedStatus(computedValue(item, 'status')),
        shortTitle: computedStatus(computedValue(item, 'status'), { isCompact: true }),
        color: computedStatus(computedValue(item, 'status'), { isTitle: false, isColor: true }),
      },
    };
    const isSelected = selected === computedValue(item, 'id') && isOpenDocument;

    return (
      <StyledListItem
        style={{ height: itemHeight }}
        className={isSelected ? 'selected' : ''}
        onClick={() => innerOnSelect(item)}
        key={index}
      >
        {renderItem ? (
          renderItem(item, options)
        ) : (
          <Popover content={hoverContent(item)} placement="right">
            <div className="box">
              {options.isStatus && <Tag color={options.status.color || ''} label={options.status.shortTitle} />}
              <div>
                <div className="title">
                  <span style={{ fontWeight: isPlanned ? 'bold' : 'normal' }}>{options.title}</span>
                </div>
                {properties.subtitle && <p className="list__subtitle">{options.subtitle}</p>}
              </div>
            </div>
          </Popover>
        )}
      </StyledListItem>
    );
  };

  const renderScrollToTop = () => {
    const isHidden = !enableScrollToTop || !isScrolled;

    return (
      !isHidden && (
        <Flex justify="center" align="center" className="scroll-to-top-icon-wrapper clickable" onClick={scrollToTop}>
          <Icon className="close-icon" icon="arrow-up" faBase="fa-solid" />
        </Flex>
      )
    );
  };

  return (
    <Spinner spinning={isLoading || captchaIsLoading}>
      <StyledList className={className}>
        <VirtualList
          data={formatItems(data, responses)}
          height={_height}
          itemKey="id"
          onScroll={onScroll}
          ref={listRef as Ref<ListRef>}
        >
          {(item: any, i) => renderListItem(item, i)}
        </VirtualList>
        {renderScrollToTop()}
      </StyledList>
    </Spinner>
  );
};
