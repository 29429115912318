import { darkThemeColors, darkThemeConfig } from './darkTheme';
import { defaultThemeColors, defaultThemeConfig } from './defaultTheme';
import { yellowThemeColors, yellowThemeConfig } from './yellowTheme';
import { ThemeConfig } from 'antd/es/config-provider/context';
import { LayoutType } from 'contexts/SystemSettingContext';

export type ThemeType = {
  [key: string]: string;
};

export type ThemeConfigType = {
  [key in LayoutType]?: ThemeConfig;
};

const dark = darkThemeColors;
const yellow = yellowThemeColors;
const defaultTheme = defaultThemeColors;

export { dark, darkThemeConfig, yellow, yellowThemeConfig, defaultTheme, defaultThemeConfig };
