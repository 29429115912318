import React, { useEffect, useRef, useContext, useState } from 'react';
import { StyledProposalItem } from './style';
import { Button, Card, Icon, Label, Spinner } from 'ui';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import useQueryApiClient from 'utils/useQueryApiClient';
import { List } from 'antd';
import { StyledProposalEditContainer } from 'pages/Proposals/ProposalEditPage/style';
import { useUserDispatch } from 'contexts/UserContext';
import MapContext from 'contexts/MapContext';
import OlLayerVector from 'ol/layer/Vector';
import OlSourceVector from 'ol/source/Vector';
import OlGeometry from 'ol/geom/Geometry';
import OlCollection from 'ol/Collection';
import OlStyleStyle from 'ol/style/Style';
import OlStyleStroke from 'ol/style/Stroke';
import OlStyleFill from 'ol/style/Fill';
import OlFeature from 'ol/Feature';
import { getIconStyle } from 'utils/mapUtils';
import GeoJSON from 'ol/format/GeoJSON';
import { useSearchParams } from 'react-router-dom';
import { PROPOSAL_URl_KEY } from '../../../../MyParticipationTabs';

interface TabMyProposalItemProp {
  proposalItem: any;
  proposalId?: number;
}

const fillColor = 'rgba(101, 219, 147, 0.7)';
const strokeColor = 'rgba(28, 97, 55, 0.9)';
const GeoJSONFormat = new GeoJSON();

const ProposalItem = ({ proposalItem }: TabMyProposalItemProp) => {
  const intl = useIntl();
  const scrollToBottom = useRef<HTMLDivElement>(null);

  const dispatch = useUserDispatch();
  const [searchPrams, setSearchParams] = useSearchParams();

  const [layer, setLayer] = useState<OlLayerVector<OlSourceVector<OlGeometry>> | null>(null);
  const map = useContext(MapContext);

  const { data: discussion, isLoading } = useQueryApiClient({
    request: {
      url: `api/v1/tapis/get-discussion-answer/${proposalItem.id}`,
      disableOnMount: !proposalItem,
    },
    onSuccess: (response) => {
      if (response?.has_seen) {
        dispatch({ type: 'NOTIFICATION_SEEN' });
      }
    },
  });

  const { refetch: zoomToTapisDocument } = useQueryApiClient({
    request: {
      url: `api/v1/tapis/geoserver/get_extent/${proposalItem?.document_id}`,
      disableOnMount: true,
    },
    onSuccess: (r) => {
      // zoom map to extent
      if (r && r.st_xmin) {
        map?.getView().fit([r.st_xmin, r.st_ymin, r.st_xmax, r.st_ymax]);
      }
    },
  });

  useEffect(() => {
    if (scrollToBottom.current) {
      scrollToBottom.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, []);

  // add map layer
  useEffect(() => {
    if (map) {
      const ml = new OlLayerVector({
        properties: {
          name: '_my_proposal_item',
        },
        source: new OlSourceVector({
          features: new OlCollection<OlFeature<OlGeometry>>(),
        }),
        style: new OlStyleStyle({
          fill: new OlStyleFill({
            color: fillColor,
          }),
          stroke: new OlStyleStroke({
            color: strokeColor,
            width: 2,
          }),
          image: getIconStyle('comment', strokeColor, ''),
        }),
        zIndex: 100,
      });
      map.addLayer(ml);
      setLayer(ml);
      return () => {
        map.removeLayer(ml);
        setLayer(null);
      };
    }
  }, [map]);

  // add/update projects into layer
  useEffect(() => {
    if (map && layer) {
      const source = layer.getSource() as OlSourceVector<OlGeometry>;
      source.clear();
      if (proposalItem) {
        if (proposalItem.geometry) {
          const feature = new OlFeature({
            ...proposalItem,
            geometry: GeoJSONFormat.readGeometry(proposalItem.geometry),
          });
          source.addFeature(feature);
          map.getView().fit(feature.getGeometry(), {
            duration: 800,
            maxZoom: 8,
          });
        } else {
          zoomToTapisDocument();
        }
      }
    }
  }, [map, layer, proposalItem]);

  return (
    <StyledProposalItem>
      <div className="proposal-item__header">
        <Button
          className="without-hovering"
          onClick={() => {
            searchPrams.delete(PROPOSAL_URl_KEY);
            setSearchParams(searchPrams);
          }}
          label={
            <div className="back-button">
              <Icon icon="long-arrow-left" faBase="fa-regular" />
              <span>{intl.formatMessage({ id: 'general.back' })}</span>
            </div>
          }
          type="text"
        />
        <Label
          subTitle
          label={`${intl.formatMessage({ id: 'my_participation.my_proposals.proposal_for_document' })}: ${
            proposalItem?.document_name
          }`}
          bold
        />
      </div>
      <Spinner spinning={isLoading}>
        <StyledProposalEditContainer>
          <Card>
            <div className="content-wrapper">
              <div className="text-wrapper">
                <FormattedMessage id="proposals.submit_date" />:
                <span className="text"> {dayjs(proposalItem?.created_at).format('DD.MM.YYYY.')}</span>
              </div>
              <div className="text-wrapper">
                <div>
                  <FormattedMessage id="proposals.my_proposal" />:
                </div>
                <div>
                  <span className="text">{proposalItem?.comment}</span>
                </div>
              </div>
            </div>
          </Card>
          <div className="proposal-items-wrapper" ref={scrollToBottom}>
            <List
              itemLayout="horizontal"
              dataSource={discussion.data}
              bordered={false}
              locale={{ emptyText: 'Vēl nav sagatavota atbilde' }}
              renderItem={(item: any) => (
                <List.Item>
                  <Card>
                    <div className="content-wrapper">
                      <div className="text-wrapper">
                        <FormattedMessage id="proposal.date" />:
                        <span className="text"> {dayjs(item?.sent_time_to_latvija_lv).format('DD.MM.YYYY.')}</span>
                      </div>

                      <div className="text-wrapper">
                        <FormattedMessage id="proposals.answer_status" />:
                        <span className="text"> {item?.decision}</span>
                      </div>

                      <div className="text-wrapper">
                        <div>
                          <FormattedMessage id="my_participation.my_proposals.response_text" />:
                        </div>
                        <div>
                          <span className="text">
                            {item?.decision_justification.split('\r\n').map((e: string) => (
                              <div>{e}</div>
                            ))}
                          </span>
                        </div>
                      </div>
                      {!!item?.file_attachment_url && (
                        <div className="text-wrapper">
                          <FormattedMessage id="proposals.file" />:<span className="text"></span>
                          <a target="_blank" style={{ fontWeight: 'normal' }} href={item?.file_attachment_url}>
                            {' '}
                            {item?.file_attachment_url}
                          </a>
                        </div>
                      )}
                      <div className="text-wrapper">
                        <FormattedMessage id="proposals.contact_information" />:
                        <span className="text"> {item?.contact_info}</span>
                      </div>
                    </div>
                  </Card>
                </List.Item>
              )}
            />
          </div>
        </StyledProposalEditContainer>
      </Spinner>
    </StyledProposalItem>
  );
};

export default ProposalItem;
