import React from 'react';
import InfoWrapper from '../info-wrapper';
import { Alert } from 'ui';
import { Tag } from 'antd';
import parse from 'html-react-parser';
import { useIntl } from 'react-intl';

interface DidNotQualifyProps {
  submittedProject: any;
}

const DidNotQualify = ({ submittedProject }: DidNotQualifyProps) => {
  const intl = useIntl();

  return (
    <InfoWrapper>
      <div className="beingImplementedStatus">
        <div />
        <Tag className={`status ${submittedProject.state}`}>
          {intl.formatMessage({ id: `participation_budget.${submittedProject.state}` })}
        </Tag>
      </div>
      {submittedProject?.versions[0]?.state_explanation && (
        <Alert type="warning" message={parse(submittedProject?.versions[0]?.state_explanation)} className="mt-4" />
      )}
    </InfoWrapper>
  );
};

export default DidNotQualify;
