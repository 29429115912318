import React, { createContext, useState } from 'react';
import { SettingsFontSizes } from 'constants/enums';

export interface FontSizeContextProps {
  fontSize: SettingsFontSizes;
  changeFontSize: Function;
}

export interface FontSizeContextProviderProps {
  children: React.ReactNode;
}

const STORAGE_KEY = 'selected-font-size';

export const FontSizeContext = createContext({} as FontSizeContextProps);

const FontSizeContextProvider = ({ children }: FontSizeContextProviderProps) => {
  const [fontSize, setFontSize] = useState(
    (window.localStorage.getItem(STORAGE_KEY) as SettingsFontSizes) || SettingsFontSizes.fontSize1
  );
  const changeFontSize = (fontSize: SettingsFontSizes) => {
    window.localStorage.setItem(STORAGE_KEY, fontSize);
    setFontSize(fontSize);
  };

  return <FontSizeContext.Provider value={{ fontSize, changeFontSize }}>{children}</FontSizeContext.Provider>;
};

export default FontSizeContextProvider;
