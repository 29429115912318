import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'contexts/ThemeContext';
import { Icon, Popover } from 'ui';
import { Flex } from 'antd';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { OptionsContainer } from './styles';
import DropdownSidebar from './Components/DropdownSidebar';
import useQueryApiClient from 'utils/useQueryApiClient';
import { State as UserType, useUserState } from 'contexts/UserContext';
import useJwt from 'utils/useJwt';
import AccessibilityMenu from '../../AccessibilityMenu/AccessibilityMenu';
import useSessionStorage from 'utils/useSessionStorage';
import { HeaderOptionTypes } from 'constants/enums';
import { useSystemSettingState } from 'contexts/SystemSettingContext';
import ProfileMenu from 'components/ProfileMenu';
import PortalSectionButtons from 'components/PortalSectionButtons';

interface HeaderProps {
  showHeaderOptions?: boolean;
}

const DefaultHeader = ({ showHeaderOptions }: HeaderProps) => {
  const [visibilityConfig, setVisibilityConfig] = useState({
    [HeaderOptionTypes.ACCESSIBILITY]: false,
    [HeaderOptionTypes.OPTIONS]: false,
  });
  const [logo, setLogo] = useState('/geo_logo.svg');

  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const user: UserType = useUserState();
  const { isTokenActive } = useJwt();
  const {
    device: { isDesktop, isMobile },
  } = useSystemSettingState();
  const intl = useIntl();
  const { value: locationValue, removeSessionValue } = useSessionStorage<string | null | undefined>('SAVE_LOCATION');
  const { data: uiMenu } = useQueryApiClient({
    request: {
      url: 'api/v1/ui-menu',
      disableOnMount: isTokenActive() && !user.selectedRole,
    },
  });

  useEffect(() => {
    if (locationValue) {
      navigate(locationValue);
      removeSessionValue();
    }
  }, []);

  useEffect(() => {
    const getLogoPath = () => {
      if (theme === 'black-on-yellow') {
        setLogo('/geo_logo_yellow.svg');
        return;
      }

      if (theme === 'white-on-black') {
        setLogo('/geo_logo_black.svg');
        return;
      }

      setLogo('/geo_logo.svg');
    };

    getLogoPath();
  }, [theme]);

  const menu2 = (
    <DropdownSidebar uiMenu={uiMenu} onItemClick={() => handleOpenChange(false, HeaderOptionTypes.OPTIONS)} />
  );

  const handleOpenChange = (open: boolean, type: HeaderOptionTypes) => {
    setVisibilityConfig({
      ...visibilityConfig,
      [type]: open,
    });
  };

  return (
    <>
      <div className="left-side-header">
        <Link to="/main" reloadDocument={!isDesktop}>
          <img className="logo" src={logo} alt="" />
        </Link>
        <div className="random-line" />
        {isDesktop && <PortalSectionButtons />}
      </div>
      {showHeaderOptions && (
        <Flex>
          <OptionsContainer>
            <AccessibilityMenu />
          </OptionsContainer>
          {isDesktop && (
            <OptionsContainer>
              <Popover
                content={menu2}
                trigger="click"
                open={visibilityConfig[HeaderOptionTypes.OPTIONS]}
                onOpenChange={(open: boolean) => handleOpenChange(open, HeaderOptionTypes.OPTIONS)}
              >
                <div className="cursor">
                  <Icon faBase="far" icon="bars" className="header-item" />
                  <span className="header-text">{intl.formatMessage({ id: 'tooltip.ui_menu' })}</span>
                </div>
              </Popover>
            </OptionsContainer>
          )}
          <ProfileMenu />
        </Flex>
      )}
    </>
  );
};

export default DefaultHeader;
