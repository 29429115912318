import React from 'react';
import DefaultLayout from 'components/DefaultLayout';
import { Button, Spinner, Upload, UploadFile } from 'ui';
import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { StyledForm, StyledPage } from 'styles/layout/form';
import { pages } from 'constants/pages';
import { QuillEditor } from 'ui/quillEditor';
import useQueryApiClient from 'utils/useQueryApiClient';
import { tutorialListOrder } from '../../constants/listOrder';

interface Tutorial {
  content: string;
  image: [UploadFile] | [];
}

export interface ResponseTutorial {
  content: string;
  uniqueKey: keyof TutorialsConfiguration;
  attachment: UploadFile | null;
}

export interface TutorialsConfiguration {
  participation_budget: Tutorial;
  planned_documents: Tutorial;
  geoproducts: Tutorial;
}

const TutorialsPage = () => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const { isLoading: isInitialLoading } = useQueryApiClient({
    request: { url: `api/v1/tutorials` },
    onSuccess: (res: ResponseTutorial[]) => updateFormValues(res),
  });

  const { appendData: updateTutorial, isLoading: isUpdateLoading } = useQueryApiClient({
    request: { url: `api/v1/tutorials`, method: 'PATCH' },
  });

  const updateFormValues = (response: ResponseTutorial[]) => {
    const formValuesEntries: any = [];

    Object.values(response).forEach((tutorial: ResponseTutorial) => {
      const { content, attachment, uniqueKey } = tutorial;

      formValuesEntries.push([uniqueKey, { content, image: [attachment].filter(Boolean) }]);
    });

    form.setFieldsValue(Object.fromEntries(formValuesEntries));
  };

  const onFinish = (values: TutorialsConfiguration) => {
    const result = Object.fromEntries(
      Object.entries(values).map(([key, tutorial]) => [
        key,
        {
          content: tutorial.content || null,
          image: [tutorial.image[0]?.id].filter(Boolean),
        },
      ])
    );

    updateTutorial(result);
  };

  const renderTutorialFields = (tutorialTitle: string, name: string) => {
    return (
      <div className="field-group-wrapper" key={tutorialTitle}>
        <div className="title-wrapper mb-1">
          <span className="title">{intl.formatMessage({ id: tutorialTitle })}</span>
        </div>
        <Upload name={[name, 'image']} maxCount={1} />
        <QuillEditor
          enableImages
          enableLinks
          enableSubscript
          enableStrikethrough
          enableTextAlign
          enableTextColor
          enableBulletList
          enableHeadingSize
          enableNumberList
          maxLength={250}
          name={[name, 'content']}
          className={name}
          toolbarKey={name}
        />
      </div>
    );
  };

  return (
    <Spinner spinning={isInitialLoading || isUpdateLoading}>
      <DefaultLayout.PageLayout>
        <DefaultLayout.PageContent>
          <StyledPage>
            <DefaultLayout.PageHeader
              breadcrumb={[
                {
                  path: '/',
                  name: intl.formatMessage({ id: 'general.administration' }),
                },
              ]}
              title={intl.formatMessage({ id: pages.tutorials.edit.title })}
            />
            <StyledForm>
              <Form form={form} onFinish={onFinish} layout="vertical">
                {tutorialListOrder.map((title: string) => renderTutorialFields(`navigation.${title}`, title))}
                <Button type="primary" label={intl.formatMessage({ id: 'general.save' })} htmlType="submit" />
              </Form>
            </StyledForm>
          </StyledPage>
        </DefaultLayout.PageContent>
      </DefaultLayout.PageLayout>
    </Spinner>
  );
};

export default TutorialsPage;
