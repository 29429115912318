import { Button, Tooltip } from 'ui';
import { StyledEmbedCreateButton } from './styles';
import { type Dispatch, type SetStateAction } from 'react';
import useJwt from 'utils/useJwt';
import { useSystemSettingState } from 'contexts/SystemSettingContext';
import { useIntl } from 'react-intl';

interface EmbedCreateButtonProps {
  setIsOpenedEmbedded: Dispatch<SetStateAction<boolean>>;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

export const EmbedCreateButton = ({ setIsOpenedEmbedded, setShowModal }: EmbedCreateButtonProps) => {
  const { isTokenActive } = useJwt();
  const intl = useIntl();
  const {
    device: { isDesktop },
  } = useSystemSettingState();

  const tooltip = isDesktop ? '' : intl.formatMessage({ id: 'general.unavailable_on_mobile' });

  const onClick = () => (isTokenActive() ? setIsOpenedEmbedded(true) : setShowModal(true));

  return (
    <StyledEmbedCreateButton className="ol-unselectable ol-control">
      <Tooltip title={tooltip} trigger="click" ignoreDevice placement="left">
        <Button
          className="embed-create-button"
          icon="share-from-square"
          faBase="fa-regular"
          title="Veidot iegulto karti"
          onClick={tooltip ? undefined : onClick}
        />
      </Tooltip>
    </StyledEmbedCreateButton>
  );
};

export default EmbedCreateButton;
