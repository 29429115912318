import styled from 'styled-components';
import { StyledIdeaView } from '../../../../IdeaView/style';

export const StyledEventView = styled(StyledIdeaView)`
  padding: 0 0 26px;

  .description p {
    margin: initial;
  }

  .image {
    margin: 0 0 10px;
  }
`;
