import styled from 'styled-components/macro';

export const StyledMapActions = styled.div`
  &,
  .actions-wrapper {
    position: absolute;
    width: 100%;
  }

  > :not(.map-search-component) {
    background: ${({ theme }) => theme.portalBackground};

    button {
      border-radius: 6px;
      padding: 9px 12px;
      border: 1px solid ${({ theme }) => theme.border2};
      z-index: 1;
    }
  }

  button.planned-documents-nav-btn {
    padding: 9px 13px;
    background: ${({ theme }) => theme.brand02Light2};
    border: 1px solid ${({ theme }) => theme.brand02};
  }
`;
