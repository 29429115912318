import styled from 'styled-components/macro';

export const StyledImageCropModal = styled.div`
  .image-crop-modal {
    .ant-modal-close {
      color: ${({ theme }) => theme.blackBg};
      &:hover {
        color: ${({ theme }) => theme.blackBg};
      }
    }
    .ant-modal-close > span {
      font-size: ${({ theme }) => theme.p1Size};
    }
    .ant-modal-header {
      .ant-modal-title {
        background-color: ${({ theme }) => theme.gray01};
        font-size: ${({ theme }) => theme.p1Size};
        color: ${({ theme }) => theme.blackBg};
      }
    }
    .ant-modal-content {
      background-color: ${({ theme }) => theme.gray01};
      .img-crop-control {
        .ant-slider-rail {
          height: ${({ theme }) => theme.imgCropControlHeight} !important;
          background-color: ${({ theme }) => theme.brand02} !important;
        }
        .ant-slider-track {
          height: ${({ theme }) => theme.imgCropControlHeight} !important;
        }
        .ant-slider-handle,
        .ant-slider-handle::after {
          width: ${({ theme }) => theme.imgCropControlHandle} !important;
          height: ${({ theme }) => theme.imgCropControlHandle} !important;
        }
        button {
          font-size: ${({ theme }) => theme.h4Size} !important;
          color: ${({ theme }) => theme.brand02};
        }
      }
      .ant-slider {
        .ant-slider-track {
          background-color: ${({ theme }) => theme.brand02};
        }
        .ant-slider-handle::after {
          box-shadow: 0 0 0 2px ${({ theme }) => theme.brand02};
          background-color: ${({ theme }) => theme.gray01};
        }
      }
    }
    .ant-modal-footer {
      .ant-btn {
        border-radius: 6px;
        font-size: ${({ theme }) => theme.p2Size};
        font-weight: ${({ theme }) => theme.fontWeightBold};
        line-height: ${({ theme }) => theme.lineHeight6};
        height: auto;
      }
      .ant-btn-default {
        background-color: ${({ theme }) => theme.gray01};
        color: ${({ theme }) => theme.textColor01};
        border: 1px solid ${({ theme }) => theme.border};
        &:hover {
          background-color: ${({ theme }) => theme.brand03} !important;
          border-color: ${({ theme }) => theme.border4} !important;
          color: ${({ theme }) => theme.textColor03} !important;
        }
        &:focus {
          background-color: ${({ theme }) => theme.gray01};
        }
      }
      .ant-btn-primary {
        background-color: ${({ theme }) => theme.button01};
        background: ${({ theme }) => theme.button01};
        border-color: ${({ theme }) => theme.brand02};
        color: ${({ theme }) => theme.textColor03};

        &:hover {
          background-color: ${({ theme }) => theme.brand03} !important;
          border-color: ${({ theme }) => theme.border4} !important;
          color: ${({ theme }) => theme.textColor03} !important;
        }
      }
    }
  }
`;
