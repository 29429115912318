import React, { useEffect, useState } from 'react';
import { StyledIdeaView } from './style';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { Card, Divider, Label } from 'ui';
import { useIntl } from 'react-intl';
import useQueryApiClient from 'utils/useQueryApiClient';
import { useSearchParams } from 'react-router-dom';
import { IdeaState } from '../MyParticipationTabs/components/Ideas';
import dayjs from 'dayjs';
import { Tag } from 'antd';

export interface BackFileProp {
  id: number;
  position: number;
  url: string;
  blob: {
    byte_size: number;
    checksum: string;
    content_type: string;
    filename: string;
    id: number;
    small_url?: string;
  };
}

export interface IdeaInterface {
  atvk_id: string;
  created_at: string;
  description: string;
  file_attachments?: BackFileProp[];
  id: number;
  name: string;
  person_id: number;
  pictures: BackFileProp[];
  state: IdeaState;
  submitter_code: string;
  submitter_email: string;
  submitter_last_name: string;
  submitter_name: string;
  the_geom: string;
  updated_at: string;
  year: number;
  comment: string;
}

export const IdeaView = ({ setSubmittedIdeaTitle }: { setSubmittedIdeaTitle?: Function }) => {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const [ideaProject, setIdeaProject] = useState<IdeaInterface | undefined>();

  const {} = useQueryApiClient({
    request: {
      url: `api/v1/tapis/idea/${searchParams.get('geoIdeaId')}`,
    },
    onSuccess: (ideasResponse: IdeaInterface) => setIdeaProject(formatIdea(ideasResponse)),
  });

  useEffect(() => {
    if (setSubmittedIdeaTitle) {
      setSubmittedIdeaTitle(ideaProject?.name);
    }
  }, [ideaProject?.id]);

  const formatIdea = (idea: IdeaInterface) => ({
    ...idea,
    pictures: formatAttachmentsUrl(idea.pictures || []),
    file_attachments: formatAttachmentsUrl(idea.file_attachments || []),
  });

  const formatAttachmentsUrl = (attachments: BackFileProp[]) =>
    attachments.map((attachment: BackFileProp) => ({ ...attachment, url: attachment.url + '?attachment_type=idea' }));

  const renderPictures = () => {
    if (!ideaProject?.pictures?.length) {
      return null;
    }

    return (
      <div className="idea-pictures">
        <Swiper
          cssMode={true}
          navigation={true}
          pagination={true}
          loop={true}
          modules={[Navigation, Pagination, Mousewheel]}
          className="project-view_swipper"
        >
          {ideaProject.pictures.map(({ url, blob: { filename } }) => (
            <SwiperSlide>
              <img src={url} alt={filename} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  };

  const renderFiles = () => {
    const { file_attachments: files = [] } = ideaProject || {};

    if (!files.length) {
      return null;
    }

    return (
      <>
        <Divider />
        <div className="mb-5 idea-files">
          <Label
            label={intl.formatMessage({ id: 'participation_budget.files' })}
            subTitle={true}
            bold={true}
            className="section_title"
          />
          <div className="data_list">
            {files.map((file: BackFileProp) => {
              const fileSize = file.blob.byte_size / (1024 * 1024);

              return (
                <Card className="file">
                  <a href={file.url} download>
                    <Label label={file.blob.filename} subTitle={true} extraBold={true} className="file_name" />
                  </a>
                  <Label label={fileSize.toFixed(2) + ' MB'} subTitle={true} regular={true} className="file_size" />
                </Card>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const renderTag = (props: any) => {
    const { value, label, closable, onClose } = props;
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        className={'idea-status status status-' + value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

  const renderStatus = (state?: IdeaState) => {
    switch (state) {
      case 'submitted':
        return renderTag({
          label: intl.formatMessage({ id: `participation_budget.${state}` }),
          value: state,
        });

      case 'answered':
      case 'realized':
        return (
          <div>
            {renderTag({
              label: intl.formatMessage({ id: `participation_budget.${state}` }),
              value: state,
            })}
            <Divider />
            <div className="idea-description pb-4">
              <Label
                label={intl.formatMessage({ id: 'participation_budget.description_municipality' })}
                subTitle={true}
                bold={true}
                className="section_title"
              />
              <span className="section_date">{dayjs(ideaProject?.updated_at).format('DD.MM.YYYY')}</span>
              <p>{ideaProject?.comment}</p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <StyledIdeaView>
      <Divider />
      {renderPictures()}
      <div className="idea-description">
        <Label
          label={intl.formatMessage({ id: 'participation_budget.description' })}
          subTitle={true}
          bold={true}
          className="section_title"
        />
        <p>{ideaProject?.description}</p>
      </div>
      {renderFiles()}
      <Divider />
      <div className="idea-status">
        <Label
          label={intl.formatMessage({ id: 'user_management.status' }) + ':'}
          subTitle={true}
          bold={true}
          className="section_title"
        />
        {renderStatus(ideaProject?.state)}
      </div>
    </StyledIdeaView>
  );
};
