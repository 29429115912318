import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Space } from 'antd';
import { useIntl } from 'react-intl';
import { Button, Icon, Input, Label, Tooltip, Divider, CheckboxGroup, Checkbox } from 'ui';
import { type UserRole, useUserDispatch, useUserState } from 'contexts/UserContext';
import useQueryApiClient from 'utils/useQueryApiClient';
import { StyledLabelWrapper, StyledVerifyButtonWrapper, StyledTabNotifications, StyledMessageWrapper } from './styles';
import Notifications from './Notifications';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import SendUserEmailVerificationModal from 'components/Modals/SendEmailVerificationModal';
import { OrganizationsCollapse } from '../OrganizationsCollapse';
import { useOrganizationsState } from 'contexts/OrganizationsContext';
import AutoSaveForm from 'ui/autoSaveForm';
import useTooltip from 'utils/useTooltip';

const TabNotifications = () => {
  const [isDisabledEmailInput, setIsDisabledEmailInput] = useState<boolean>(true);
  const [selectedRoleInSelect, setSelectedRoleInSelect] = useState<UserRole>();
  const [showSendEmailVerificationModal, setShowSendEmailVerificationModal] = useState(false);
  const [notificationCheckBox, setNotificationCheckBox] = useState<CheckboxValueType[]>([]);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean | undefined>();

  const user = useUserState();
  const dispatchUser = useUserDispatch();
  const { organizations } = useOrganizationsState();
  const intl = useIntl();
  const [form] = Form.useForm();
  const [personForm] = Form.useForm();
  const { getRestrictionTooltip } = useTooltip('');

  const activeRole = user.roles.find((e) => e.id === user.selectedRole);
  const isPhysical = activeRole?.code === 'authenticated';

  const [changedEmail, setChangedEmail] = useState<string | undefined>(activeRole?.email);

  useEffect(() => {
    setSelectedRoleInSelect(activeRole);
  }, [activeRole]);

  const { appendData: generateEmail, isLoading: isSendingEmail } = useQueryApiClient({
    request: {
      url: `api/v1/user-email/generate`,
      method: 'POST',
    },
    onSuccess() {
      setIsDisabledEmailInput(true);
    },
  });
  const { appendData: updateUser, isLoading: userUpdateLoading } = useQueryApiClient({
    request: {
      url: `api/v1/users`,
      method: 'PATCH',
      enableOnMount: false,
    },
    onSuccess: () => {
      setIsDisabledEmailInput(true);
      setShowSendEmailVerificationModal(true);
      generateEmail({
        email: personForm.getFieldValue('email'),
        roleId: selectedRoleInSelect?.id,
      });
    },
  });

  useQueryApiClient({
    request: {
      url: `api/v1/tapis/notification_configurations/`,
      method: 'GET',
    },
    onSuccess({ configurations: res, voted_project_notifications }) {
      if (res && res.length > 0) {
        const ownMunicipality = res.find(({ own_municipality }: any) => !!own_municipality) || {};
        const voteStart = ownMunicipality.vote_start ? 'vote_start' : undefined;
        const submitStart = ownMunicipality.submit_start ? 'submit_start' : undefined;
        const status_change = voted_project_notifications ? 'status_change' : undefined;
        const atvk_ids = res
          .map((item: any) => (item.atvk_id !== ownMunicipality.atvk_id ? item.atvk_id : undefined))
          .filter(Boolean);

        form.setFieldsValue({ notice_checkbox: [submitStart, voteStart, status_change], atvk_id: atvk_ids });
      }
    },
  });

  useEffect(() => {
    const activeRole = user.roles.find((e) => e.id === user.selectedRole);

    setIsEmailVerified(activeRole?.emailVerified);
    personForm.setFieldsValue({
      name: user.name,
      surname: user.surname,
      email: activeRole?.email,
      selectedRole: user.selectedRole,
      sendEmail: user.sendEmail,
    });
  }, [user, personForm]);

  const onGenerateVerificationEmail = async () => {
    await personForm.validateFields();

    setIsDisabledEmailInput(true);
    updateUser({ ...personForm.getFieldsValue(true), emailVerified: false });
  };

  const handleCancelEmailEdit = () => {
    setIsDisabledEmailInput(true);
    personForm.setFieldValue('email', selectedRoleInSelect?.email);
  };

  const formatData = ({ atvk_id: newMunicipalities = [] }: { atvk_id?: string[] }) => {
    const submit_start = notificationCheckBox.includes('submit_start');
    const ownMunicipality = {
      atvk_id: user.atvkCode,
      vote_start: notificationCheckBox.includes('vote_start'),
      submit_start,
    };
    const configurations = newMunicipalities
      .map((el: any) => ({ atvk_id: el, vote_start: false, submit_start: true }))
      .concat(ownMunicipality);

    return {
      notification_configuration: {
        configurations,
        voted_project_notifications: notificationCheckBox.includes('status_change'),
      },
    };
  };

  const renderNotice = (id: string) => {
    return (
      <Col span={24}>
        <StyledMessageWrapper className="mb-3_5">{intl.formatMessage({ id })}</StyledMessageWrapper>
      </Col>
    );
  };

  const renderConfiguration = () => {
    const restrictionTooltip = getRestrictionTooltip('email-verification', {
      user,
      section: 'notification-configuration',
    });
    const isDisabled = !!restrictionTooltip || !isEmailVerified;

    return (
      <>
        <Col span={24}>
          <Label
            subTitle
            label={intl.formatMessage({
              id: 'my_participation.my_budget.announcements_about_the_participation_budget',
            })}
            bold
            className="mt-3_5"
          />
        </Col>
        {restrictionTooltip && renderNotice(restrictionTooltip)}
        <Col span={24}>
          <CheckboxGroup
            direction="vertical"
            name="notice_checkbox"
            onChange={(checkedValue: CheckboxValueType[]) => setNotificationCheckBox(checkedValue)}
            disabled={isDisabled}
          >
            <Checkbox
              label={intl.formatMessage({ id: 'my_participation.my_budget.vote_municipality_notification' })}
              value="vote_start"
              name="vote_start"
              defaultChecked={false}
            />
            <Checkbox
              label={intl.formatMessage({
                id: 'my_participation.my_budget.submit_not_my_municipality_notification',
              })}
              value="submit_start"
              name="submit_start"
              defaultChecked={false}
            />
            <Checkbox
              label={intl.formatMessage({ id: 'my_participation.my_budget.vote_state_change_notification' })}
              value="status_change"
              name="status_change"
              defaultChecked={false}
            />
          </CheckboxGroup>
        </Col>
        <Divider />
        <Col span={24}>
          <OrganizationsCollapse
            panel={{
              header: intl.formatMessage({
                id: 'my_participation.my_budget.submit_not_my_municipality',
              }),
            }}
            select={{
              name: 'atvk_id',
              disabled: isDisabled,
              filterItems: (items) => items.filter(({ atvk_id }) => atvk_id !== user.atvkCode),
            }}
          />
        </Col>
      </>
    );
  };

  return (
    <StyledTabNotifications>
      <Label
        subTitle
        label={intl.formatMessage({ id: 'my_participation.my_profile.my_profile' })}
        bold
        styles={{ marginBottom: '15px' }}
      />
      <Form form={personForm} layout="vertical">
        <Space size={22} direction="vertical" style={{ width: '100%' }}>
          <Row gutter={[30, 7]}>
            <Col span={24} md={12}>
              <Input name="name" label={intl.formatMessage({ id: 'group.name_1' })} disabled />
            </Col>
            <Col span={24} md={12}>
              <Input name="surname" label={intl.formatMessage({ id: 'group.surname' })} disabled />
            </Col>
            <Col span={24} md={12}>
              <StyledLabelWrapper>
                <Tooltip
                  title={intl.formatMessage({
                    id: 'my_participation.my_profile.not_verified_email_text',
                  })}
                  placement={'topLeft'}
                >
                  <Label label={intl.formatMessage({ id: 'group.email' })} />
                </Tooltip>
              </StyledLabelWrapper>

              <Input
                disabled={isDisabledEmailInput || userUpdateLoading}
                name="email"
                onChange={(e) => setChangedEmail(e.target.value)}
                validations="email"
                suffix={
                  isDisabledEmailInput && (
                    <>
                      {(!selectedRoleInSelect?.email || !selectedRoleInSelect?.emailVerified) && (
                        <Tooltip
                          title={intl.formatMessage({
                            id: !selectedRoleInSelect?.email
                              ? 'my_participation.my_profile.empty_email_tooltip_text'
                              : 'my_participation.my_profile.not_verified_email_text',
                          })}
                          placement={'topRight'}
                        >
                          <div className="icon-warning">
                            <Icon
                              faBase="far"
                              icon="exclamation-triangle"
                              className="field-email-icon-exclamation-triangle"
                            />
                          </div>
                        </Tooltip>
                      )}

                      <Icon
                        faBase="far"
                        icon="edit"
                        className="field-email-icon-edit"
                        onClick={() => setIsDisabledEmailInput(false)}
                      />
                    </>
                  )
                }
              />

              {!isDisabledEmailInput && (
                <StyledVerifyButtonWrapper>
                  <Button
                    onClick={handleCancelEmailEdit}
                    type="default"
                    label={intl.formatMessage({ id: 'general.cancel_changes' })}
                  />
                  <Button
                    onClick={onGenerateVerificationEmail}
                    type="primary"
                    label={intl.formatMessage({ id: 'group.verify' })}
                  />
                </StyledVerifyButtonWrapper>
              )}
            </Col>
            <Col span={24} md={12}>
              <Tooltip
                hack
                placement="topRight"
                title={intl.formatMessage({ id: 'my_participation.my_profile.declared_address_tooltip' })}
                align={{ offset: [-11, 30] }}
              >
                <Input
                  disabled
                  name="address"
                  placeholder={organizations.find(({ atvk_id }) => atvk_id === user.atvkCode)?.name || ''}
                  label={intl.formatMessage({ id: 'my_participation.my_profile.declared_address' })}
                  suffix={<Icon faBase="far" icon="info-circle" />}
                />
              </Tooltip>
            </Col>
          </Row>
        </Space>
      </Form>
      <AutoSaveForm
        request={{
          url: `api/v1/tapis/notification_configurations`,
          method: 'POST',
          enableOnMount: false,
        }}
        form={form}
        saveAfter={1000}
        formatData={formatData}
        layout="vertical"
      >
        <Space size={22} direction="vertical" style={{ width: '100%' }}>
          <Row gutter={[30, 7]}>
            <Divider />
            {renderConfiguration()}
            <Divider />
          </Row>
        </Space>
      </AutoSaveForm>
      {isPhysical && <Notifications />}
      <SendUserEmailVerificationModal
        isLoading={isSendingEmail}
        email={changedEmail}
        setModalOpen={setShowSendEmailVerificationModal}
        modalOpen={showSendEmailVerificationModal}
        userRefetch={true}
      />
    </StyledTabNotifications>
  );
};

export default TabNotifications;
