import styled from 'styled-components/macro';
import { MOBILE_MAX_WIDTH } from 'utils/device';

export const StyledProfileIcon = styled.div`
  height: 100%;

  &:not(.is-authenticated) .user-data .profile-icon {
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      background-color: ${({ theme }) => theme.gray02};
    }
  }

  .user-data {
    padding: 0 20px;
    cursor: pointer;
    height: inherit;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      padding: 0 15px;
    }

    span {
      font-weight: ${({ theme }) => theme.fontWeightBold};
      font-size: ${({ theme }) => theme.p2Size};
    }

    .user-name {
      font-weight: ${({ theme }) => theme.fontWeightBold};
      font-size: ${({ theme }) => theme.p2Size};
      margin-bottom: 4px;

      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        font-weight: ${({ theme }) => theme.fontWeightBold};
      }
    }

    .user-role {
      font-weight: ${({ theme }) => theme.fontWeightBold};
      font-size: ${({ theme }) => theme.p2Size};
      color: ${({ theme }) => theme.textColor02};

      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        font-size: ${({ theme }) => theme.p3Size};
        color: ${({ theme }) => theme.textColor13};
        font-weight: ${({ theme }) => theme.fontWeightRegular};
      }
    }

    .ant-badge .ant-badge-dot {
      width: 11px;
      height: 11px;
    }

    .profile-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${({ theme }) => theme.brand02};
      border-radius: 50%;
      margin: 0;
      width: 38px;
      height: 38px;
      position: relative;
      color: ${({ theme }) => theme.gray01};
      font-weight: ${({ theme }) => theme.fontWeightBold};

      .notification-dot {
        width: 10px;
        height: 11px;
        background-color: ${({ theme }) => theme.brand06};
        position: absolute;
        top: -1px;
        left: 1px;
        border-radius: 100px;
      }
    }
  }
`;
