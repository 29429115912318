import styled from 'styled-components';

export const StyledPlanningDocuments = styled.div`
  display: flex;
  flex-direction: column;

  .ant-divider {
    max-width: 30px;
  }
`;
