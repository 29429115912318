import React from 'react';
import { Alert } from 'ui';
import { useIntl } from 'react-intl';
import InfoWrapper from '../info-wrapper';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { DateVoteCount } from '../date-vote-status';
import VoteButton, { VoteHandler } from 'components/VoteButton';

interface InVotingProps {
  isVoted: boolean | null;
  isLoadingVotion: boolean | undefined;
  handleVote: VoteHandler;
  visibleVotes: boolean;
  votingPeriodFrom: string;
  votingPeriodTo: string;
  voteCount: number;
  submittedProject?: any;
}

const InVotingState = ({
  votingPeriodFrom,
  votingPeriodTo,
  visibleVotes,
  voteCount,
  isVoted,
  isLoadingVotion,
  handleVote,
  submittedProject,
}: InVotingProps) => {
  const intl = useIntl();

  const renderVoteButton = () => {
    if (isVoted === null) {
      return null;
    }

    return (
      <VoteButton
        project={submittedProject}
        button={{ loading: isLoadingVotion }}
        tooltip={{ placement: 'topLeft' }}
        handleVote={handleVote}
      />
    );
  };

  const renderCanVoteUntil = () => {
    return (
      <p>
        {intl.formatMessage({ id: 'participation_budget.you_can_vote_until' })}{' '}
        <span>{dayjs(votingPeriodTo).format('DD.MM.YYYY.')}</span>
      </p>
    );
  };

  return (
    <InfoWrapper>
      <div className="text_side">
        <div>
          {votingPeriodFrom && votingPeriodTo && visibleVotes ? (
            <>
              {renderCanVoteUntil()}
              <DateVoteCount
                votingPeriodFrom={votingPeriodFrom}
                votingPeriodTo={votingPeriodTo}
                voteCount={voteCount}
              />
            </>
          ) : (
            <>
              {renderCanVoteUntil()}
              <p>{intl.formatMessage({ id: 'participation_budget.notification_3' })}</p>
            </>
          )}
        </div>
        {renderVoteButton()}
      </div>
      {submittedProject?.versions[0]?.state_explanation && (
        <Alert type="warning" message={parse(submittedProject?.versions[0]?.state_explanation)} className="mt-4" />
      )}
    </InfoWrapper>
  );
};

export default InVotingState;
