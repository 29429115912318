import styled from 'styled-components/macro';
import { VARIABLES } from 'styles/globals';
import { MOBILE_MAX_WIDTH } from 'utils/device';

export const StyledMeasure = styled.div`
  bottom: calc(${VARIABLES.mapButtonMargin} + 3 * ${VARIABLES.mapButtonSize});

  right: ${VARIABLES.mapButtonMargin};
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    bottom: calc(3 * (${VARIABLES.mapButtonSize} + var(--map-action-button-gap)) + var(--map-action-button-gap));
  }

  .length {
    border-top-left-radius: ${VARIABLES.mapButtonBorderRadius} !important;
    border-top-right-radius: ${VARIABLES.mapButtonBorderRadius} !important;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      border-radius: ${VARIABLES.mapButtonBorderRadius} !important;
    }

    &.expanded {
      border-top-left-radius: 0 !important;

      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        border-bottom-left-radius: 0 !important;
      }
    }
  }

  .area {
    border-top-left-radius: ${VARIABLES.mapButtonBorderRadius} !important;
    border-bottom-left-radius: ${VARIABLES.mapButtonBorderRadius} !important;
  }

  .measure-button:hover {
    @media (hover: hover) {
      background-color: ${({ theme }) => theme.brand02} !important;
      color: ${({ theme }) => theme.textColor03} !important;
    }
  }

  i {
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      color: ${({ theme }) => theme.iconColor09};
    }
  }
`;
