import styled from 'styled-components/macro';
import { VARIABLES } from 'styles/globals';
import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from 'utils/device';

export const StyledPage = styled.div`
  .layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .ant-layout-content {
    background-color: ${({ theme }) => theme.portalBackground};
    width: initial !important;
  }

  .with-sidebar-width {
    padding-left: ${VARIABLES.sidebarWidth};
  }

  .with-small-sidebar-width {
    padding-left: var(--sidebar-drawer-width);
  }

  .ant-layout-content.without-page-header {
    margin-top: calc(var(--header-height) + 16px);
  }

  .collapsed-layout {
    padding-left: 78px;
  }

  .site-header {
    width: 100%;
    background: ${({ theme }) => theme.headerBgColor};
    color: ${({ theme }) => theme.textColor01};
    border-bottom: 1px solid ${({ theme }) => theme.border};
    height: var(--header-height);
    line-height: ${({ theme }) => theme.lineHeight9};
    padding-left: 30px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: ${VARIABLES.zIndex10};
    box-shadow: 0px 2px 30px -20px rgba(0, 0, 0, 0.75);

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      padding: 0 0 0 30px;
    }

    .left-side-header {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        align-self: stretch;
        display: flex;
        align-items: center;

        .logo {
          margin: auto 0;
        }
      }

      .random-line {
        width: 1px;
        height: 20px;
        background: ${({ theme }) => theme.border2};
        margin: 0 25px;

        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          display: none;
        }
      }
    }
  }

  .fake-language-select {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    align-items: center;
    cursor: pointer;
    flex-grow: 1;
    color: ${({ theme }) => theme.textColor02};
  }

  @media screen and (max-width: 480px) {
    .html-container {
      height: 300px;
      overflow: auto;
    }
  }

  .openTapisDocument,
  .openGeoproduct {
    .footer .shadow {
      min-width: 12px;
      height: 100%;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.1) 160%);
    }
  }

  .openMapClickResults .tapis-documents {
    display: none;
  }
  .openSubmitProposal .detailed-planned-documents {
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      display: none;
    }
  }
`;

export const StyledDivider = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.portalBackground};
  padding: 0 100px;
`;
