import { ClassName, Disabled, Name } from 'interfaces/shared';
import { StyledBreadcrumb } from './style';
import { Icon } from '../icon';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import truncateWithEllipsis from '../../utils/truncateWithEllipsis';
import { MouseEventHandler } from 'react';

export interface BreadcrumbProps extends Disabled, ClassName, Name {
  title: string | undefined;
  breadcrumb?: BreadcrumbObject[];
  trunc?: number;
}
export interface BreadcrumbObject {
  path?: string;
  name: string;
  goBack?: boolean;
  withState?: boolean;
  onClick?: MouseEventHandler;
}
export const Breadcrumb = ({ title, breadcrumb = [], trunc = 0 }: BreadcrumbProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const renderCustomBreadcrumb = (breadcrumb: BreadcrumbObject) => {
    if (breadcrumb.onClick) {
      return <span onClick={breadcrumb.onClick}>{breadcrumb.name}</span>;
    }

    if (breadcrumb.withState) {
      return (
        <span onClick={() => navigate(`${breadcrumb.path}`, { state: { currentPage: state?.currentPage } })}>
          {breadcrumb.name}
        </span>
      );
    }

    return breadcrumb.path ? <NavLink to={breadcrumb.path}>{breadcrumb.name}</NavLink> : breadcrumb.name;
  };

  return (
    <StyledBreadcrumb separator={<Icon icon="chevron-right" className="custom-icon" />}>
      {breadcrumb.map((breadcrumb: BreadcrumbObject, index: number) => {
        return (
          <StyledBreadcrumb.Item key={index}>
            {breadcrumb.goBack ? (
              <span onClick={() => navigate(-1)}>{breadcrumb.name}</span>
            ) : (
              renderCustomBreadcrumb(breadcrumb)
            )}
          </StyledBreadcrumb.Item>
        );
      })}
      {title && <StyledBreadcrumb.Item>{trunc ? truncateWithEllipsis(title, trunc) : title}</StyledBreadcrumb.Item>}
    </StyledBreadcrumb>
  );
};
