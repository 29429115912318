import { useOpenedTypeState } from 'contexts/OpenedTypeContext';
import React from 'react';
import { StyledSubmitProjectFirstStep } from './style';
import { Icon, Label } from 'ui';
import { useNavigate } from 'react-router-dom';

const SubmitProjectFirstStep = () => {
  const { openSubmitProject } = useOpenedTypeState();
  const navigate = useNavigate();

  return (
    <StyledSubmitProjectFirstStep className={`custom-modal ${openSubmitProject ? 'open' : ''}`}>
      <div className="custom-modal-content">
        <Label className="title" bold={true} heading={true} label="Atzīmē kartē projekta atrašanās vietu"></Label>
        <p>
          Pašvaldības īpašumā esošās zemes vienības iekrāsotas zaļā krāsā. Ja projektu vēlēsieties iesniegt ārpus
          pašvaldības īpašumā esošajām zemes vienībām, Jums būs nepieciešams pievienot zemes īpašnieka saskaņojumu, ka
          viņš piekrīt projekta realizācijai uz viņa zemes.
        </p>
      </div>
      <Icon
        className="custom-modal-close"
        faBase="fal"
        icon="circle-xmark"
        onClick={() => navigate('/main?participation-budget=open')}
      />
    </StyledSubmitProjectFirstStep>
  );
};

export default SubmitProjectFirstStep;
