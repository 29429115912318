import styled from 'styled-components';
import { Modal } from 'antd';
import { TABLET_MAX_WIDTH } from 'utils/device';

export const StyledSubscribeModal = styled(Modal)`
  .ant-modal-content .ant-modal-footer .actions-wrapper {
    .ant-btn {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        margin: initial;
        width: 100%;
      }
    }

    > *:not(.go-to-my-profile-btn) {
      @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
        order: -1;
      }
    }
  }
`;
