import styled from 'styled-components/macro';

export const StyledTagSelect = styled.div`
  .ant-tag {
    height: initial;

    &.invalid {
      background-color: ${({ theme }) => theme.brand06Light} !important;

      .ant-select-selection-item-content {
        color: ${({ theme }) => theme.statusRed};
      }
    }
  }

  .ant-select {
    min-height: initial !important;
  }
`;
