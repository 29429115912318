import React from 'react';
import { ClassNameUtil } from 'utils/className';
import { StyledPage } from './style';

interface PageContentProps {
  withTopPadding?: boolean;
  children?: React.ReactNode;
}

const PageContent = ({ withTopPadding, children }: PageContentProps) => {
  const classname = ClassNameUtil.create(['page-content']).add('with-top-padding', !!withTopPadding);

  return <StyledPage className={classname.getClassName()}>{children}</StyledPage>;
};

export default PageContent;
