import React from 'react';
import { ClassName, Disabled } from 'interfaces/shared';
import { StyledTooltip } from './style';
import { TooltipPlacement } from 'antd/es/tooltip';
import type { AlignType } from '@rc-component/trigger/lib/interface';
import { useSystemSettingState } from 'contexts/SystemSettingContext';

type ActionType = 'hover' | 'focus' | 'click' | 'contextMenu';

export interface TooltipProps extends Disabled, ClassName {
  color?: string;
  title: string | React.ReactNode;
  children: React.ReactNode;
  overlay?: React.ReactElement | (() => React.ReactElement);
  hack?: boolean;
  style?: any;
  overlayClassName?: string;
  placement?: TooltipPlacement;
  trigger?: ActionType | ActionType[];
  open?: boolean | undefined;
  onOpenChange?: (open: boolean) => void;
  align?: AlignType;
  ignoreDevice?: boolean;
}

export const Tooltip = ({
  color,
  title,
  children,
  overlay,
  hack,
  className,
  style,
  overlayClassName,
  placement,
  trigger,
  open,
  onOpenChange,
  align,
  ignoreDevice = false,
}: TooltipProps) => {
  const {
    device: { isDesktop },
  } = useSystemSettingState();

  const mainApp: any = document.getElementsByClassName('App')[0];
  const deviceOpenState = isDesktop ? open : false;

  return (
    <StyledTooltip
      color={color}
      title={title}
      overlay={overlay}
      getPopupContainer={() => mainApp}
      className={className}
      style={style}
      overlayClassName={overlayClassName}
      placement={placement}
      trigger={trigger}
      open={ignoreDevice ? open : deviceOpenState}
      onOpenChange={onOpenChange}
      align={align}
    >
      {hack ? <div className="tooltip">{children}</div> : children}
    </StyledTooltip>
  );
};
