import styled from 'styled-components';

export const StyledProjectSection = styled.div`
  padding-bottom: 20px;
  .year_tabs {
    .ant-tabs-nav {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      &::before {
        border: none;
      }
    }
  }

  .ant-pagination {
    display: flex;
    justify-content: center;
  }
`;
