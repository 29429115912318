import React, { useRef, useEffect, useState } from 'react';
import { StyledInfoTooltip, StyledProjectModal } from './style';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { Button, Spinner, Tooltip, Image } from 'ui';
import { Tag } from 'antd';
import { ProjectType } from '../../Sidebars/Project';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useJwt from 'utils/useJwt';
import { useUserState } from 'contexts/UserContext';
import useQueryApiClient from 'utils/useQueryApiClient';
import { getMapModalPosition } from 'utils/getMapModalPosition';
import { useProjectDispatch } from 'contexts/ProjectContext';
import { UserGroups } from 'constants/userGroups';
import VoteButton, { VotingProject } from 'components/VoteButton';
import { NavigateOptions } from 'react-router/dist/lib/context';

type ModalTypeType = 'idea' | 'project';

interface ProjectModalType {
  onClose?: (e: React.MouseEvent<HTMLElement>) => void;
  isOpenProjectModal?: boolean;
  project: ProjectType | null;
  setIsOpenProjectModal: Function;
  isProjectLoading: boolean;
  type?: ModalTypeType;
}

const ProjectModal = ({
  project,
  isOpenProjectModal,
  setIsOpenProjectModal,
  isProjectLoading,
  type = 'project',
}: ProjectModalType) => {
  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);

  const intl = useIntl();
  const modalRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<any>(null);
  const user = useUserState();
  const dispatch = useProjectDispatch();
  const navigate = useNavigate();
  const { isTokenActive } = useJwt();
  const { appendData: submitVote, isLoading: isLoadingVotion } = useQueryApiClient({
    request: {
      url: 'api/v1/tapis/vote-for-project',
      method: 'POST',
    },
    onSuccess(_, project) {
      onVoted(project);
    },
  });

  const activeRole = user.roles.find((e) => e.id === user.selectedRole)?.code;
  const coordinatesForModal = project?.coordinatesForModal;
  const modalRefWidth = modalRef.current?.clientWidth ? modalRef.current.clientWidth : 0;
  const modalRefHeight = modalRef.current?.clientHeight ? modalRef.current.clientHeight : 0;
  const { left, top } = coordinatesForModal
    ? getMapModalPosition(
        coordinatesForModal.x,
        coordinatesForModal.y,
        coordinatesForModal.mapWidth,
        coordinatesForModal.mapHeight,
        modalRefWidth,
        modalRefHeight
      )
    : { left: 0, top: 0 };

  useEffect(() => {
    const checkModal = (event: Event): void => {
      if (
        (event.target as Node).nodeName !== 'CANVAS' &&
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setIsOpenProjectModal(false);
      }
    };
    window.addEventListener('click', checkModal);

    return () => {
      window.removeEventListener('click', checkModal);
    };
  }, []);

  useEffect(() => {
    const { current } = textRef;

    if (current) {
      setIsOverflowed(current?.scrollHeight > current?.clientHeight);
    }
  }, [project]);

  const onVoted = (project: VotingProject | null) => {
    if (project) {
      dispatch({ type: 'VOTE_PROJECT', payload: project?.id });
    }
  };

  const appendVote = (project: VotingProject | null) => {
    if (!project) {
      return;
    }

    if (activeRole === UserGroups.proxy) {
      return onVoted(project);
    }

    submitVote(
      {
        vote: {
          project_id: project?.id,
          is_active: !project?.has_voted,
        },
      },
      undefined,
      project
    );
  };

  const handleOpenProjectView = (type: ModalTypeType) => {
    const navigationMap: { [key in ModalTypeType]: { url: string; options: NavigateOptions } } = {
      idea: {
        url: `/main?idea-view=open&side=right&geoIdeaId=${project?.id}`,
        options: { state: { backBtnRoute: '/main?my-participation=open&tab=ideas_tab' } },
      },
      project: {
        url: `/main?project-view=open&side=left&geoProjectId=${project?.id}`,
        options: { state: { backBtnRoute: '/main?participation-budget=open' } },
      },
    };

    navigate(navigationMap[type].url, navigationMap[type].options);
    setIsOpenProjectModal(false);
  };

  const renderVoteButton = () => {
    if (project?.state === 'in_voting') {
      return (
        <VoteButton
          project={project}
          button={{ loading: isLoadingVotion }}
          handleVote={appendVote}
          tooltip={{ placement: 'top', overlayClassName: 'white' }}
        />
      );
    }

    return (
      <Tag className={`status ${project?.state} ${type}`}>
        {intl.formatMessage({ id: `participation_budget.${project?.state}` })}
      </Tag>
    );
  };

  const renderImages = () => {
    const { pictures = [], versions: [{ pictures: versionPictures = [] }] = [{}] } = project || {};
    const images = typeof pictures[0] === 'string' ? pictures : versionPictures.map(({ url }) => url);

    return (
      <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        loop={true}
        modules={[Navigation, Pagination, Mousewheel]}
        className="project-item__swiper"
      >
        {images.length ? (
          images.map((image: string, idx: number) => (
            <SwiperSlide key={idx}>
              <Image src={image} type={type === 'idea' ? 'idea' : undefined} size="medium" />
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <Image type={type === 'idea' ? 'idea' : undefined} size="medium" />
          </SwiperSlide>
        )}
      </Swiper>
    );
  };

  return (
    <StyledProjectModal>
      <div
        ref={modalRef}
        className={`modal ${isOpenProjectModal ? 'open' : ''}`}
        style={
          coordinatesForModal
            ? {
                left: `${left}px`,
                top: `${top}px`,
              }
            : {}
        }
      >
        <Spinner spinning={isProjectLoading}>
          {renderImages()}
          <div className="project-item__content-wrapper">
            <Tooltip overlayClassName="white" title={isOverflowed ? project?.name : ''}>
              <h2 className="title_project_modal" ref={textRef}>
                {project?.name}
              </h2>
            </Tooltip>
          </div>
          {!isProjectLoading && (
            <div className="buttons_group">
              <div className="left_side">{renderVoteButton()}</div>
              <Button
                label={intl.formatMessage({
                  id: 'participation_budget.projects_see_description',
                })}
                type="link"
                onClick={() => handleOpenProjectView(type)}
              />
            </div>
          )}
        </Spinner>
      </div>
    </StyledProjectModal>
  );
};

export default ProjectModal;
