import { useIntl } from 'react-intl';
import { BreadcrumbObject, Button, SidebarDrawer, Spinner, Tabs, Tooltip } from 'ui';
import { NotificationSide } from './components/NotificationSide';
import { StyledMunicipalProject } from './style';
import { ProjectSection } from './components/ProjectSection';
import { EventInterface, EventSection } from './components/EventSection';
import { SpecialistInfo } from './components/SpecialistInfo';
import React, { ReactNode, useEffect, useState } from 'react';
import useQueryApiClient from 'utils/useQueryApiClient';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { RenderTabBar } from 'rc-tabs/lib/interface';
import { Flex } from 'antd';
import { routes } from 'config/config';
import FileDownload from 'js-file-download';
import toastMessage from 'utils/toastMessage';
import { EventView } from './components/EventSection/EventView';

export const MunicipalProject = ({ isOpenMunicipalProject, closeProject }: any) => {
  const [hasVotingPeriod, setHasVotingPeriod] = useState<boolean>(false);
  const [hasSubmissionPeriod, setHasSubmissionPeriod] = useState<boolean>(false);
  const [budget, setBudget] = useState<number>(0);
  const [municipal, setMunicipal] = useState<any>();
  const [selectedYear, setSelectedYear] = useState(routes.geo.municipalProjectSelectedYear);
  const [projects, setProjects] = useState<any[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbObject[]>([]);
  const [drawerTitle, setDrawerTitle] = useState<string>('');
  const [selectedEvent, setSelectedEvent] = useState<EventInterface | undefined>();
  const [eventActivePage, setEventActivePage] = useState<number>(1);

  const intl = useIntl();
  let [searchParams] = useSearchParams();
  const atvkId = searchParams.get('atvk_id') || undefined;
  const isModalOpen = searchParams.get('municipal-modal');

  const { refetch, isLoading: isMunicipalLoading } = useQueryApiClient({
    request: {
      url: `/api/v1/tapis/participation_budgets/${atvkId}`,
      method: 'GET',
      disableOnMount: true,
    },
    onSuccess: (response) => {
      setBudget(response.budget);

      const afterSub = dayjs(response.submission_period_from).startOf('d').isBefore(dayjs().endOf('d'));
      const beforeSub = dayjs(response.submission_period_to).endOf('d').isAfter(dayjs().startOf('d'));

      setHasSubmissionPeriod(afterSub && beforeSub);

      const after = dayjs(response.voting_period_from).startOf('d').isBefore(dayjs().endOf('d'));
      const before = dayjs(response.voting_period_to).endOf('d').isAfter(dayjs().startOf('d'));

      setHasVotingPeriod(after && before);
      setMunicipal(response);
      setDrawerTitle(response?.name);
    },
  });

  const { appendData: exportProjects, isLoading: isExporting } = useQueryApiClient({
    request: {
      url: `/api/v1/tapis/projects/export?search[year]=${selectedYear}`,
      method: 'GET',
      multipart: true,
      disableOnMount: true,
    },
    onSuccess: (response) => {
      FileDownload(response, `project_list_${dayjs().format('DD_MM_YYYY')}.pdf`);
      toastMessage.success(intl.formatMessage({ id: 'general.download_success' }));
    },
  });

  useEffect(() => {
    if (atvkId && isModalOpen !== 'open') {
      refetch();
    }
  }, [searchParams]);

  const tabSelectorData = [
    {
      tab: 'events',
      id: 2,
      children: (
        <EventSection
          atvkId={atvkId}
          onEventClick={(event) => onEventClick(event)}
          activePage={eventActivePage}
          setActivePage={setEventActivePage}
        />
      ),
    },
    {
      tab: 'project',
      id: 1,
      children: <ProjectSection budget={budget} onTabChange={setSelectedYear} onProjectListChange={setProjects} />,
    },
  ];

  const onEventClick = (event: EventInterface) => {
    const eventBreadcrumbs: BreadcrumbObject[] = [
      { name: intl.formatMessage({ id: 'general.start' }), onClick: () => onClose('event') },
      { name: municipal?.name, onClick: () => onClose('event') },
    ];

    setSelectedEvent(event);
    setDrawerTitle(event.news_name);
    setBreadcrumbs(eventBreadcrumbs);
  };

  const onPrint = (projects: any[]) => {
    if (projects.length) {
      exportProjects({ projectIds: projects.map(({ id }) => id), search: { year: selectedYear } });
    }
  };

  const excludedStatuses = ['submitted', 'did_not_qualify', 'ready_to_vote'];

  const renderTabBar: RenderTabBar = (props, DefaultTabBar) => (
    <Flex justify="space-between" align="center" className="wrapper-tab-bar">
      <DefaultTabBar {...props} />
      {props.activeKey == '1' && (
        <Tooltip hack title={intl.formatMessage({ id: 'participation_budget.projects_download_tooltip' })}>
          <Button
            className="action-print"
            icon="file-pdf"
            faBase="fa-light"
            label={intl.formatMessage({ id: 'participation_budget.projects_download' })}
            border={false}
            disabled={
              isExporting || !projects?.filter((project: any) => !excludedStatuses.includes(project.state))?.length
            }
            onClick={() => onPrint(projects)}
          />
        </Tooltip>
      )}
    </Flex>
  );

  const onClose = (type?: 'event') => {
    switch (type) {
      case 'event':
        setSelectedEvent(undefined);
        setDrawerTitle(municipal?.name);
        setBreadcrumbs([]);

        return;

      default:
        closeProject();
    }
  };

  const renderNotification = (type: 'vote' | 'submission') => {
    const renderMap: { [key in typeof type]: ReactNode } = {
      vote: (
        <NotificationSide
          message={intl.formatMessage(
            { id: `municipiality_can_vote_for_projects_message` },
            { until: dayjs(municipal?.voting_period_to).format('DD.MM.YYYY.') }
          )}
        />
      ),
      submission: (
        <NotificationSide
          message={intl.formatMessage(
            { id: `municipiality_submission_period_message` },
            { until: dayjs(municipal?.submission_period_to).format('DD.MM.YYYY.') }
          )}
        />
      ),
    };

    return renderMap[type];
  };

  const renderDrawerContent = () => {
    if (selectedEvent) {
      return <EventView event={selectedEvent} />;
    }

    return (
      <StyledMunicipalProject>
        <Spinner spinning={isMunicipalLoading || isExporting}>
          <Tabs
            defaultActiveKey="2"
            type="card"
            className="municipal_project_tab"
            items={tabSelectorData.map((item) => {
              return {
                label: intl.formatMessage({ id: `participation_budget.${item.tab}` }),
                key: item.id,
                children: (
                  <div>
                    {hasVotingPeriod && renderNotification('vote')}
                    {hasSubmissionPeriod && renderNotification('submission')}
                    <SpecialistInfo
                      hasSubmissionPeriod={hasSubmissionPeriod}
                      isThereSubmissionPeriod={Boolean(municipal?.submission_period_from)}
                      isMunicipalLoading={isMunicipalLoading}
                      budget={municipal}
                    />
                    {item.children}
                  </div>
                ),
              };
            })}
            renderTabBar={renderTabBar}
          />
        </Spinner>
      </StyledMunicipalProject>
    );
  };

  return (
    <SidebarDrawer
      width="50vw"
      className={`sidebar-style-2 ${selectedEvent ? 'event-view' : ''}`}
      title={drawerTitle}
      isOpen={isOpenMunicipalProject}
      showBreadcrumb={!!breadcrumbs.length}
      backIcon={'left'}
      onClose={() => (selectedEvent ? onClose('event') : onClose())}
      breadcrumb={breadcrumbs}
      backText={selectedEvent && 'general.back'}
      dividerVisible={!selectedEvent}
      side="right"
    >
      {renderDrawerContent()}
    </SidebarDrawer>
  );
};
