import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';
import { StatusProps } from '../../index';
import { StyledDateVoteCount } from './styles';

interface VoteStatusProps extends Omit<StatusProps, 'visibleVotes' | 'state'> {}

export const DateVoteCount = ({ voteCount, votingPeriodTo, votingPeriodFrom }: VoteStatusProps) => {
  const intl = useIntl();

  const renderVoteCount = () => {
    const label = voteCount > 1 || voteCount === 0 ? 'participation_budget.citizens' : 'participation_budget.citizen';

    return <span className="vote-count">{intl.formatMessage({ id: label }, { count: voteCount })}</span>;
  };

  return (
    <StyledDateVoteCount className="dateVoteCount">
      <p>
        {intl.formatMessage(
          { id: 'participation_budget.votes_from_to' },
          {
            from_to: (
              <span>
                {dayjs(votingPeriodFrom).format('DD.MM.YYYY.')} - {dayjs(votingPeriodTo).format('DD.MM.YYYY.')}
              </span>
            ),
          }
        )}
        {renderVoteCount()}
      </p>
    </StyledDateVoteCount>
  );
};
