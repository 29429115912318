import { Navigate, useParams } from 'react-router-dom';
import React from 'react';

interface RedirectWithParamsProps {
  basePath: string;
  queryParams?: Record<string, string>;
}

const RedirectWithParams = ({ basePath, queryParams = {} }: RedirectWithParamsProps) => {
  const { id } = useParams();
  const searchParams = new URLSearchParams(queryParams);

  id && searchParams.append('id', id);

  const to = `${basePath}${basePath.includes('?') ? '&' : '?'}${searchParams.toString()}`;

  return <Navigate to={to} replace />;
};

export default RedirectWithParams;
