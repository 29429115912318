import styled from 'styled-components/macro';
import { MOBILE_MAX_WIDTH } from '../../utils/device';

export const StyledFilter = styled.div`
  .filter-fields {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 10px 30px;
  }

  .actions-list {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.border};
    padding-bottom: 30px;

    .left-side {
      display: flex;
      gap: 10px;
    }

    .right-side {
      display: flex;
      gap: 10px;
    }
  }

  .actions-list-table {
    display: flex;
    justify-content: space-between;

    .left-side {
      display: flex;
      gap: 10px;
    }

    .right-side {
      display: flex;
      gap: 10px;
    }
  }

  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    .filter-fields {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .actions-list {
      .right-side {
        display: flex;
        flex-direction: column-reverse;
        flex-grow: 1;
        gap: 12px;
      }

      &.my-9 {
        margin: 24px 0;
      }
    }
  }
`;
