const stringConverter = () => {
  const toSnake = (string: string) => {
    return string
      .replace(/\W+/g, ' ')
      .split(/ |\B(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join('_');
  };

  const toKebab = (string: string) =>
    string
      .replace(/\W+/g, ' ')
      .split(/ |\B(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join('-');

  return {
    toSnake,
    toKebab,
  };
};

export default stringConverter;
