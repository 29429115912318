import { ClassName, Disabled, Name } from 'interfaces/shared';
import { StyledTabs } from './style';
import { RenderTabBar } from 'rc-tabs/lib/interface';

export interface TabsProps extends Disabled, ClassName, Name {
  type?: 'line' | 'card' | 'editable-card';
  activeKey?: string;
  onChange?: (activeKey: string) => void;
  children?: React.ReactNode;
  defaultActiveKey?: string;
  className?: string;
  items?: any;
  renderTabBar?: RenderTabBar;
}

export const Tabs = ({
  type,
  activeKey,
  onChange,
  children,
  defaultActiveKey,
  className,
  items,
  renderTabBar,
}: TabsProps) => {
  return (
    <StyledTabs
      type={type}
      activeKey={activeKey}
      onChange={onChange}
      defaultActiveKey={defaultActiveKey}
      className={className}
      items={items}
      renderTabBar={renderTabBar}
    >
      {children}
    </StyledTabs>
  );
};
