import { Image as AntdImage } from 'antd';
import { ImageProps as AntdImageProps } from 'rc-image/lib/Image';
import { routes } from '../../config/config';

export type ImageSizeType = 'small' | 'medium';

interface ImageProps extends AntdImageProps {
  size?: ImageSizeType;
  type?: string;
}

export const Image = ({ size, type, src, ...props }: ImageProps) => {
  const url = new URL(src || (type === 'idea' ? '/no_idea.jpg' : '/placeholder'), routes.api.frontendUrl);

  size && url.searchParams.set('size', size);
  type && url.searchParams.set('attachment_type', type);

  return <AntdImage preview={false} {...props} src={url.toString()} />;
};
