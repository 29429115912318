import { StyledMapActions } from './styles';
import MapSearch from 'components/Map/MapSearch';
import React, { useState } from 'react';
import { useSystemSettingState } from 'contexts/SystemSettingContext';
import { useOpenedTypeState } from 'contexts/OpenedTypeContext';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'ui';
import { routes } from 'config/config';
import useJwt from 'utils/useJwt';
import UnauthenticatedModal from 'components/Modals/UnauthenticatedModal';
import { Flex } from 'antd';

const MapActions = () => {
  const PLANNED_DOCS_NAV_LINK = '/main?document=open';

  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    device: { isMobile, isDesktop },
  } = useSystemSettingState();
  const {
    openedTapis,
    openTapisDocument,
    openGeoproduct,
    openMyParticipation,
    openParticipationBudget,
    openProject,
    openSubmitProjectCreateForm,
    openPlannedDocuments,
  } = useOpenedTypeState();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { isTokenActive } = useJwt();
  const navigate = useNavigate();
  const { activeHeaderButton } = useOpenedTypeState();

  const isPlannedDocumentSection = activeHeaderButton === 'planned-documents';
  const isPlannedDocsNavVisible =
    !isDesktop && isPlannedDocumentSection && PLANNED_DOCS_NAV_LINK !== location.pathname + location.search;
  const isGetNotificationsVisible = !isDesktop && isPlannedDocumentSection;

  // reloaded to clear sidebar open states
  const navigateWithReload = (url: string) => window.location.replace(url);

  return (
    <StyledMapActions className="map-actions">
      <MapSearch
        className="map-search-component"
        show={
          !isMobile
            ? !(
                openGeoproduct ||
                openProject ||
                openMyParticipation ||
                openSubmitProjectCreateForm ||
                openPlannedDocuments ||
                openParticipationBudget ||
                openedTapis ||
                searchParams.get('notification') === 'open' ||
                openTapisDocument
              )
            : false
        }
      />
      <Flex gap={8} className="actions-wrapper m-3">
        {isPlannedDocsNavVisible && (
          <div className="planned-documents-nav-btn-wrapper">
            <Button
              className="planned-documents-nav-btn"
              icon="file-lines"
              faBase="far"
              color="green"
              onClick={() => navigateWithReload(routes.api.frontendUrl + PLANNED_DOCS_NAV_LINK)}
            />
          </div>
        )}
        {isGetNotificationsVisible && (
          <div className="receive-notifications-btn-wrapper">
            <Button
              icon="bell"
              faBase="far"
              color="paleSky"
              onClick={() => (isTokenActive() ? navigate('/main?notification=open') : setShowModal(true))}
            />
          </div>
        )}
      </Flex>
      <UnauthenticatedModal setShowModal={setShowModal} showModal={showModal} />
    </StyledMapActions>
  );
};

export default MapActions;
