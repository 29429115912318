import styled from 'styled-components/macro';
import { MOBILE_MAX_WIDTH } from 'utils/device';

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  margin: 8px 0;
  border-right: 1px solid ${({ theme }) => theme.border2};

  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    padding: 0 15px;
    border-right: 1px solid ${({ theme }) => theme.border};
  }

  label {
    font-family: 'Ubuntu', serif;
  }

  span {
    font-weight: ${({ theme }) => theme.fontWeightBold};
    font-size: ${({ theme }) => theme.p2Size};
  }

  .header-text {
    display: ${({ theme }) => theme.textVisible};
  }

  .header-item {
    cursor: pointer;
    margin-right: 10px;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      margin: initial;
    }

    &:hover {
      @media (hover: hover) {
        color: ${({ theme }) => theme.brand02};
      }
    }
  }
`;

export const ProfileTooltipContent = styled.div`
  a {
    color: ${({ theme }) => theme.textColor07} !important;
    text-decoration: underline;
  }
`;
