import React from 'react';
import { StyledImageCropModal } from './style';
import ImgCrop from 'antd-img-crop';

type BeforeUploadValueType = boolean | string | Blob | File | void;

interface ImgCropModalProps {
  children: any;
  modalTitle: string;
  modalOk: string;
  modalCancel: string;
  aspect: number;
  onModalOk: (value: BeforeUploadValueType | Promise<BeforeUploadValueType>) => void;
  modalClassName: string;
}

export const ImgCropModal = ({
  children,
  modalTitle,
  modalOk,
  modalCancel,
  aspect,
  modalClassName,
  onModalOk,
}: ImgCropModalProps) => {
  return (
    <StyledImageCropModal>
      <ImgCrop
        modalProps={{
          getContainer: false,
        }}
        modalTitle={modalTitle}
        modalOk={modalOk}
        modalCancel={modalCancel}
        aspect={aspect}
        onModalOk={onModalOk}
        modalClassName={modalClassName}
      >
        {children}
      </ImgCrop>
    </StyledImageCropModal>
  );
};
