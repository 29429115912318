import styled from 'styled-components/macro';

export const StyledValidationModal = styled.div`
  .ant-modal-wrap {
    display: flex;
    align-items: center;
  }

  .ant-modal {
    top: initial;
  }

  .ant-modal-content {
    padding: 26px 26px 22px;

    .ant-modal-body .modal-header {
      padding: 0 0 22px;
      margin: 0 0 18px;

      .title {
        font-weight: ${({ theme }) => theme.fontWeightBolder};
        font-size: ${({ theme }) => theme.h3Size};
      }
    }

    .ant-modal-footer {
      margin: 14px 0 0;
      padding: 22px 0 0;
      border-top: 1px solid ${({ theme }) => theme.border};
    }

    .actual-content {
      color: ${({ theme }) => theme.textColor02};
    }
  }

  .ant-tree-list-holder-inner {
    &,
    .ant-tree-treenode:hover:before {
      background: initial;
    }
  }

  .ant-tree-switcher {
    position: absolute;
    right: 0;

    &_close {
      transform: rotate(90deg);
    }

    &_open {
      transform: rotate(180deg);
    }
  }

  .child-node .label {
    color: ${({ theme }) => theme.textColor02};
    font-weight: ${({ theme }) => theme.fontWeightBold};
  }

  .parent-node {
    color: ${({ theme }) => theme.textColor04};
    text-decoration: underline;
  }

  .ant-tree-indent {
    display: none;
  }

  .fail .ant-modal-content {
    .modal-header {
      padding: initial;
      border: initial;
      text-align: center;
    }

    .ant-modal-footer {
      justify-content: center;
    }
  }

  .fail .ant-modal-content {
    .ant-modal-footer {
      padding: initial;
      border: initial;
    }
  }

  .notification-options {
    .ant-checkbox-wrapper span.ant-checkbox + span {
      font-weight: ${({ theme }) => theme.fontWeightBold};
      padding-right: initial;
    }

    .ant-collapse .ant-form-item-row {
      flex-direction: column;
    }
  }

  .authLoading {
    &,
    .ant-spin-nested-loading {
      height: 100%;
      top: initial;
    }

    .ant-spin {
      max-height: initial;
    }

    .ant-spin-dot {
      filter: brightness(200%);
    }
  }
`;
