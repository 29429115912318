import React from 'react';

export const SAVE_ORGANIZATIONS = 'SAVE_ORGANIZATIONS';

interface PayloadAction {
  type: typeof SAVE_ORGANIZATIONS;
  payload: Partial<OrganizationState>;
}

export interface Attachment {
  data: string;
}

export interface Organization {
  id: number;
  atvk_id: string;
  name: string;
  ensures_participatory_budgeting_process: boolean;
  profile_attachments: Attachment[];
  web_page: string;
}

export interface OrganizationState {
  organizations: Organization[];
  isLoading: boolean;
}

function userReducer(state: OrganizationState, { type, payload }: PayloadAction) {
  switch (type) {
    case 'SAVE_ORGANIZATIONS': {
      return {
        ...state,
        isLoading: false,
        ...payload,
      };
    }
  }
}

const ProjectContext = React.createContext<OrganizationState>(undefined!);
const ProjectDispatchContext = React.createContext<(action: PayloadAction) => void>(undefined!);

export function OrganizationsProvider({ children }: { children: React.ReactNode }) {
  const initialState: OrganizationState = { organizations: [], isLoading: true };
  const [state, dispatch] = React.useReducer(userReducer, initialState);

  return (
    <ProjectContext.Provider value={state}>
      <ProjectDispatchContext.Provider value={dispatch}>{children}</ProjectDispatchContext.Provider>
    </ProjectContext.Provider>
  );
}

export const useOrganizationsState = () => React.useContext(ProjectContext);
export const useOrganizationsDispatch = () => React.useContext(ProjectDispatchContext);
