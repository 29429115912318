import styled from 'styled-components';
import { VARIABLES } from '../../../../../../../styles/globals';

export const StyledInfoWrapper = styled.div`
  .not-supported_text {
    margin-top: 30px;
    border: 1px solid ${({ theme }) => theme.tagBorder04};
    border-radius: 6px;
    padding: 30px;
    background-color: ${({ theme }) => theme.tagBackground04};
  }

  .not-supported-status {
    padding: 10px 12px;
    border-radius: 16px;
    color: ${({ theme }) => theme.brand06};
    background-color: ${({ theme }) => theme.brand06Light};
  }

  .being-implemented {
    margin-bottom: 30px;
    display: inline-block;
    padding: 10px 12px;
    border-radius: 16px;
    color: ${({ theme }) => theme.statusBlue};
    background-color: ${({ theme }) => theme.brand05Light};
  }

  .submitted-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .beingImplementedStatus {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .delete-btn {
    border-radius: ${VARIABLES.mapButtonBorderRadius};
    font-size: ${({ theme }) => theme.p2Size} !important;
    font-weight: ${({ theme }) => theme.fontWeightBold};
    color: ${({ theme }) => theme.deleteButtonColor};
    background-color: ${({ theme }) => theme.deleteButtonBg};
  }

  .tooltip {
    width: fit-content;
  }
`;
