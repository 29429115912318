import { Attachment, Organization, useOrganizationsState } from 'contexts/OrganizationsContext';
import { Spinner, Image } from 'ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Navigation, Pagination } from 'swiper/modules';
import React from 'react';

interface OrganizationImagesProps {
  atvkId: string;
}

export const OrganizationImages = ({ atvkId }: OrganizationImagesProps) => {
  const { isLoading: isPicturesLoading, organizations } = useOrganizationsState();

  const placeholder = [{ data: '/pasvaldiba.jpg' }];

  const getImages = (): Attachment[] => {
    const organizationAttachments: Attachment[] | undefined = organizations.find(
      ({ atvk_id: orgAtvkId }: Organization) => atvkId === orgAtvkId
    )?.profile_attachments;

    return organizationAttachments?.length ? organizationAttachments : placeholder;
  };

  return (
    <Spinner spinning={isPicturesLoading}>
      <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        loop={true}
        modules={[Navigation, Pagination, Mousewheel]}
        className="project-item__swiper mo"
      >
        {getImages().map(({ data: url }: Attachment, i: number) => (
          <SwiperSlide key={i}>
            <Image src={url} width="100%" />
          </SwiperSlide>
        ))}
      </Swiper>
    </Spinner>
  );
};
