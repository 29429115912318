export const MOBILE_MAX_WIDTH = '767px';
export const TABLET_MAX_WIDTH = '1023px';
export const DESKTOP_MIN_WIDTH = '1024px';

const device = {
  mobile: (): boolean =>
    window.matchMedia(`(max-width: ${MOBILE_MAX_WIDTH})`).matches && window.matchMedia('screen').matches,
  tablet: (): boolean =>
    window.matchMedia(`(min-width: ${MOBILE_MAX_WIDTH}) and (max-width: ${TABLET_MAX_WIDTH})`).matches &&
    window.matchMedia('screen').matches,
  desktop: () => window.matchMedia(`(min-width: ${DESKTOP_MIN_WIDTH})`).matches && window.matchMedia('screen').matches,
};

export default device;
