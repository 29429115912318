import React from 'react';
import { ProjectState } from '../pages/LayoutPage/Components/Sidebars/MunicipalProject/components/ProjectSection';
import { VotingProject } from '../components/VoteButton';

interface PayloadType {
  projects: any;
  appendData: Function;
  search?: SearchInterface;
}

type PayloadAction = {
  type: 'SAVE_PAYLOAD' | 'REFETCH';
  payload: PayloadType;
};

type RefetchAction = {
  type: 'REFETCH';
  payload: any;
};

type HighlightAction = {
  type: 'HIGHLIGHT_PROJECT';
  payload: number;
};

type VoteAction = {
  type: 'VOTE_PROJECT';
  payload: number;
};
type Action = PayloadAction | RefetchAction | HighlightAction | VoteAction;

type Dispatch = (action: Action) => void;

type State = {
  projects: any[];
  appendData: Function;
  initialized: boolean;
  isLoading: boolean;
  highlightedProjectId: number | null;
  voteMap: any; // TODO refactor to substitute voteMap
  voteMap_2: { [key in VotingProject['id']]: boolean };
  search: SearchInterface;
};

type ProjectProviderProps = { children: React.ReactNode };

export type SearchInterface = {
  name?: string;
  atvk_id?: string;
  territorial_units?: string[];
  neighbourhoods?: string[];
  state?: string[];
  category?: string[];
  voter_code?: 'replace';
  submitter_code?: 'replace';
  order?: string;
  has_voted?: boolean;
};

const ProjectContext = React.createContext<State>(undefined!);
const ProjectDispatchContext = React.createContext<Dispatch>(undefined!);

const getVoteMap = (projects: any[]) => {
  return Object.fromEntries(Object.values(projects).map(({ id, has_voted }) => [id, has_voted]));
};

function userReducer(state: State, action: Action) {
  switch (action.type) {
    case 'SAVE_PAYLOAD': {
      let payload = action.payload;
      return {
        ...state,
        ...payload,
        projects: payload.projects || [],
        initialized: true,
        isLoading: false,
        highlightedProjectId: null,
      };
    }
    case 'REFETCH': {
      state.appendData(action.payload, undefined, action.payload);
      return {
        ...state,
        projects: [],
        isLoading: true,
        highlightedProjectId: null,
      };
    }
    case 'HIGHLIGHT_PROJECT': {
      return {
        ...state,
        highlightedProjectId: action.payload,
      };
    }

    case 'VOTE_PROJECT': {
      let hasVoted = false;
      const projectId = action.payload;
      const projects = state.projects.map((project: any) => {
        if (project.id === projectId) {
          hasVoted = !project.has_voted;

          return { ...project, has_voted: hasVoted };
        }

        return project;
      });

      return {
        ...state,
        projects,
        // TODO refactor to substitute with voteMap_2
        voteMap: {
          ...state.voteMap,
          [projectId]: hasVoted,
        },
      };
    }
  }
}

function ProjectProvider({ children }: ProjectProviderProps) {
  const initialState = {
    projects: [],
    initialized: false,
    isLoading: true,
    appendData: () => null,
    highlightedProjectId: null,
    voteMap: {}, // TODO refactor to substitute with voteMap_2
    search: {},
    get voteMap_2() {
      return {};
    },
  };

  const [state, dispatch] = React.useReducer(userReducer, initialState);

  const dynamicState = {
    ...state,
    get voteMap_2() {
      return getVoteMap(state.projects);
    },
  };

  return (
    <ProjectContext.Provider value={dynamicState}>
      <ProjectDispatchContext.Provider value={dispatch}>{children}</ProjectDispatchContext.Provider>
    </ProjectContext.Provider>
  );
}

const useProjectState = () => React.useContext(ProjectContext);
const useProjectDispatch = () => React.useContext(ProjectDispatchContext);

export { ProjectProvider, useProjectDispatch, useProjectState };
