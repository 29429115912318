import React, { useContext, useEffect, useState } from 'react';
import { Button, ImageUploader, Input, TextArea, FileUploadCards } from 'ui';
import { StyledIdeaSubmission } from './style';
import { useIntl } from 'react-intl';
import { Form, message } from 'antd';
import { getBase64 } from 'utils/globalFunctions';
import { useNavigate } from 'react-router-dom';
import FileUploadPart from 'pages/LayoutPage/Components/Sidebars/ParticipationBudget/components/SubmitProjectForm/DocumentUploader/components/FileUploadPart';
import useQueryApiClient from 'utils/useQueryApiClient';
import { UserRole, useUserState } from 'contexts/UserContext';
import dayjs from 'dayjs';
import useHandleError from 'utils/useHandleError';
import MapContext from 'contexts/MapContext';
import { fromLKSToGPS, getIconStyle, PROJ_GPS, PROJ_LKS } from 'utils/mapUtils';
import Feature from 'ol/Feature';
import { Coordinate } from 'ol/coordinate';
import { Point } from 'ol/geom';
import { Style } from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { MapBrowserEvent } from 'ol';
import { useMapClickResultsOpening } from 'contexts/MapClickResultsOpeningContext';
import toastMessage from 'utils/toastMessage';
import OlPoint from 'ol/geom/Point';
import WKT from 'ol/format/WKT';

interface AttachmentInterface {
  filename: string;
  data: string;
  id?: string;
}

interface FileProps {
  position: number;
  attachment: AttachmentInterface;
}

const ICON_MARKER = getIconStyle('marker_outline', 'rgba(28, 97, 55, 1.0)', '', 0, 1.35);

const iconLayer = new VectorSource();
const WKTFormat = new WKT();

export const IdeaSubmisson = () => {
  const [images, setImages] = useState<AttachmentInterface[]>([]);
  const [materials, setMaterials] = useState<AttachmentInterface[]>([]);
  const [coords, setCoords] = useState<string>('');

  const intl = useIntl();
  const navigate = useNavigate();
  const user = useUserState();
  const [handleError] = useHandleError();
  const map = useContext(MapContext);
  const { atvkId } = useMapClickResultsOpening();
  const { appendData: createIdea } = useQueryApiClient({
    request: {
      url: `api/v1/tapis/idea`,
      method: 'POST',
      enableOnMount: false,
    },
    onSuccess: () => {
      toastMessage.success(intl.formatMessage({ id: 'my_participation.idea_submitted_notice' }));
      returnToIdeaListing();
    },
    onError: handleError,
  });

  useEffect(() => {
    if (map) {
      const mapLayer = new VectorLayer({ source: iconLayer, zIndex: 10 });

      const handleClick = ({ coordinate }: MapBrowserEvent<MouseEvent>) => {
        setCoords(WKTFormat.writeGeometry(new OlPoint(fromLKSToGPS(coordinate))));
      };

      map.addLayer(mapLayer);
      map.on('click', handleClick);

      return () => {
        map.removeLayer(mapLayer);
        map.un('click', handleClick);
      };
    }
  }, [map]);

  // rely on municipality change, when that happens add the marker in clicked coords
  useEffect(() => {
    iconLayer.clear();

    if (atvkId && coords) {
      placeMarker(coords, iconLayer);
    }
  }, [atvkId]);

  const placeMarker = (coordinate: string, iconLayer: VectorSource) => {
    const point = WKTFormat.readGeometry(coordinate, {
      dataProjection: PROJ_GPS,
      featureProjection: PROJ_LKS,
    }) as OlPoint;
    const marker = new Feature({ geometry: point });

    marker.setStyle(new Style({ image: ICON_MARKER }));
    iconLayer.addFeature(marker);
  };

  const addFile = async (fileList: AttachmentInterface[], newFile: any) => {
    const isFileAvailable = fileList.find((file) => file.filename === newFile.file.name);

    if (isFileAvailable) {
      return message.warning(intl.formatMessage({ id: 'submit_project.such_file_uploaded' }));
    }

    const id = Date.now();
    const data = await getBase64(newFile.file.originFileObj as any);

    setMaterials([...materials, { data, filename: newFile.file?.name, id: String(id) }]);
  };

  const removeFile = (removingFile: any) => {
    setMaterials([...materials.filter((file) => file.id != removingFile.id)]);
  };

  const formatAttachments = (attachments: any[]): FileProps[] => {
    return attachments.map((file, i) => ({
      position: i,
      attachment: {
        filename: file.filename,
        data: file.data,
      },
    }));
  };

  const handleOnFinish = ({ name: ideaName, description }: any) => {
    const { name, surname, roles, selectedRole } = user;

    if (!atvkId || !coords.length) {
      return handleError({}, undefined, 'my_participation.choose_coordinate');
    }

    createIdea({
      name: ideaName,
      atvk_id: atvkId,
      year: dayjs().year(),
      the_geom: coords,
      submitter_code: 'replace',
      submitter_name: name,
      submitter_last_name: surname,
      submitter_email: roles.find(({ id }: UserRole) => id === selectedRole)?.email,
      description,
      pictures_attributes: formatAttachments(images),
      file_attachments_attributes: formatAttachments(materials),
    });
  };

  const handleCancel = () => {
    returnToIdeaListing();
  };

  const returnToIdeaListing = () => {
    navigate('/main?my-participation=open&tab=ideas_tab');
  };

  return (
    <StyledIdeaSubmission>
      <Form layout="vertical" onFinish={handleOnFinish}>
        <Input
          size="middle"
          name="name"
          label={intl.formatMessage({ id: 'my_participation.idea_name' })}
          validations="required"
          className="idea_field"
        />
        <div className=" idea_field submition_textarea">
          <TextArea
            rules={[
              { required: true, message: intl.formatMessage({ id: 'validation.field_required' }) },
              { max: 1000, message: intl.formatMessage({ id: 'general.max_number_symbols' }, { limit: 1000 }) },
            ]}
            rows={4}
            name="description"
            maxLength={1000}
            label={intl.formatMessage({ id: 'participation_budget.desc_idea' })}
          />
          <span className="max-length">
            {intl.formatMessage({ id: 'general.max_number_symbols' }, { limit: 1000 })}
          </span>
        </div>
        <div className="document-upload">
          <FileUploadPart
            title={intl.formatMessage({ id: 'my_participation.materials' })}
            onChange={(info: any) => addFile(materials, info)}
          />
          <FileUploadCards fileList={materials} handleRemove={removeFile} />
        </div>
        <ImageUploader imageList={images} setImageList={setImages} required={false} />
        <div className="submit_project_btn">
          <Button onClick={handleCancel} label={intl.formatMessage({ id: 'general.cancel' })} />
          <Button type="primary" htmlType="submit" label={intl.formatMessage({ id: 'my_participation.submit_idea' })} />
        </div>
      </Form>
    </StyledIdeaSubmission>
  );
};
