import { StyledProjectList } from './style';
import { ProjectInterface } from '../../temporary-data';
import { useIntl } from 'react-intl';
import { ProjectListItem } from '../ProjectListItem';
import { Collapse, Label, Pagination } from 'ui';
import { Collapse as AntdCollapse } from 'antd';
import usePagination from 'utils/usePagination';
import dayjs from 'dayjs';
import { useState } from 'react';

type BlockType = 'first' | 'second' | 'third';

export const ProjectsList = ({ data, budget, municipalProject }: any) => {
  const [seeMore, setSeeMore] = useState(false);

  const intl = useIntl();
  const { Panel } = AntdCollapse;
  const { currentPage, paginatedData, handlePageChange } = usePagination(data.projects);

  const isVotingClosed = ({ state }: ProjectInterface) => state === 'voting_is_closed';

  const renderBlockTitle = (type: BlockType) => {
    const isAllVotingClosed = !paginatedData.find((project: ProjectInterface) => !isVotingClosed(project));

    if (isAllVotingClosed) {
      return null;
    }

    const { labels } = getBlockData(type);

    return (
      <Label
        className="block_title"
        subTitle
        bold
        label={intl.formatMessage({
          id: municipalProject?.vote_available ? labels.shift() : labels.pop(),
        })}
      />
    );
  };

  const renderBlockList = (type: BlockType) => {
    const { list } = getBlockData(type);

    if (!list.length) {
      return null;
    }

    return (
      <div className={`${type}_block block`}>
        {renderBlockTitle(type)}
        <div className="projects">
          {list.map((project: ProjectInterface) => (
            <ProjectListItem
              project={project}
              block={type}
              visibleVotes={municipalProject?.visible_votes}
              votingPeriodFrom={municipalProject?.voting_period_from}
              votingPeriodTo={municipalProject?.voting_period_to}
              isVotingPeriod={municipalProject?.vote_available}
            />
          ))}
        </div>
      </div>
    );
  };

  const getBlockData = (type: BlockType): { list: ProjectInterface[]; labels: string[] } => {
    switch (type) {
      case 'first':
        return {
          list: paginatedData.filter((project: ProjectInterface) => !isVotingClosed(project) && project.is_winner),
          labels: [
            'participation_budget.supported_projects_title',
            'participation_budget.municipal_supported_projects_title',
          ],
        };

      case 'second':
        return {
          list: paginatedData.filter((project: ProjectInterface) => !isVotingClosed(project) && !project.is_winner),
          labels: ['participation_budget.active_projects_title', 'participation_budget.not_supported_projects_title'],
        };

      case 'third':
        return {
          list: paginatedData.filter((project: ProjectInterface) => isVotingClosed(project)),
          labels: ['participation_budget.no_decision_projects_title'],
        };
    }
  };

  const renderBudgetInfo = () => {
    if (municipalProject.vote_available) {
      return null;
    }

    return (
      <>
        {' '}
        <Label
          className="budget-title"
          subTitle
          bold
          label={
            intl.formatMessage({ id: 'amount_allocated_to_participation_budget' }) +
            ' ' +
            new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2 }).format(budget).replace(',', '.') +
            ' EUR'
          }
        />
        <Collapse onChange={() => setSeeMore((old) => !old)} className="less-margin show-more">
          <Panel
            forceRender
            key={'show-more'}
            header={
              <label>
                {
                  <b>
                    {seeMore
                      ? intl.formatMessage({ id: 'projects.see_less' })
                      : intl.formatMessage({ id: 'projects.see_more' })}
                  </b>
                }
              </label>
            }
          >
            <Label
              label={intl.formatMessage(
                { id: 'project.project_submission_period' },
                {
                  period: (
                    <b>
                      &nbsp;
                      {dayjs(municipalProject?.submission_period_from).format('DD.MM.YYYY.') +
                        ' - ' +
                        dayjs(municipalProject?.submission_period_to).format('DD.MM.YYYY.')}
                    </b>
                  ),
                }
              )}
            />
            {municipalProject?.voting_period_from && (
              <Label
                label={intl.formatMessage(
                  { id: 'project.voting_period' },
                  {
                    period: (
                      <b>
                        &nbsp;
                        {dayjs(municipalProject?.voting_period_from).format('DD.MM.YYYY.') +
                          ' - ' +
                          dayjs(municipalProject?.voting_period_to).format('DD.MM.YYYY.')}
                      </b>
                    ),
                  }
                )}
              />
            )}
          </Panel>
        </Collapse>
      </>
    );
  };

  return (
    <StyledProjectList>
      <div className="municipal_project_list">
        <div>
          <div className="default_block">
            {renderBudgetInfo()}
            {renderBlockList('first')}
          </div>
          {renderBlockList('second')}
          {renderBlockList('third')}
        </div>
      </div>
      {data.projects.length > 6 && (
        <Pagination
          current={currentPage}
          onChange={handlePageChange}
          total={data.projects.length}
          showSizeChanger={false}
          className="default"
        />
      )}
    </StyledProjectList>
  );
};
