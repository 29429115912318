export const getMapModalPosition = (
  xMapLayout: number,
  yMapLayout: number,
  mapWidth: number,
  mapHeight: number,
  modalWidth: number,
  modalHeight: number
) => {
  const SIDE_BUFFER = 15;
  const TWO_MODAL_WIDTHS = modalWidth * 2 + SIDE_BUFFER;

  if (mapWidth > TWO_MODAL_WIDTHS) {
    const calcWidth = mapWidth - (xMapLayout + modalWidth);
    const left = calcWidth < 0 ? xMapLayout - modalWidth - SIDE_BUFFER : xMapLayout + SIDE_BUFFER;

    const calcHeight = mapHeight - (yMapLayout + modalHeight);
    const top = calcHeight < 0 ? yMapLayout - (yMapLayout + modalHeight - mapHeight + 5) : yMapLayout;

    return { left, top };
  }

  return { left: (mapWidth - modalWidth) / 2, top: mapHeight - (modalHeight + 60) };
};
