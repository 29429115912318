import { StyledEllipsis } from './style';
import React from 'react';

export interface EllipsisProps extends React.ComponentPropsWithoutRef<'div'> {
  lines: number;
}

export const Ellipsis = ({ lines, children, ...rest }: EllipsisProps) => {
  return (
    <StyledEllipsis lines={lines} {...rest}>
      {children}
    </StyledEllipsis>
  );
};
