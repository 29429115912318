import React, { ComponentPropsWithoutRef, MouseEventHandler, useContext, useState } from 'react';
import { Button, Dropdown, DropdownProps, Icon, IconProps as UiIconProps, Label, Modal } from 'ui';
import { SettingsColorSchemes, SettingsFontSizes } from 'constants/enums';
import { Flex, Row, Col, Menu } from 'antd';
import { useIntl } from 'react-intl';
import { ThemeContext } from 'contexts/ThemeContext';
import { FontSizeContext } from 'contexts/FontSizeContext';
import { SettingsContainer, SidebarHeaderContainer, StyledAccessibilityModal } from './styles';
import { ClassNameUtil } from 'utils/className';
import { LayoutType, useSystemSettingState } from 'contexts/SystemSettingContext';
import { FlexProps } from 'antd/es/flex/interface';
import { createPortal } from 'react-dom';

type ColorEntryType = [string, SettingsColorSchemes];

interface IconProps extends UiIconProps {
  visible?: boolean;
}

interface AccessibilityMenuProps extends ComponentPropsWithoutRef<'div'> {
  dropdown?: Omit<DropdownProps, 'open' | 'onOpenChange' | 'trigger' | 'children'>;
  icon?: IconProps;
  flex?: Omit<FlexProps, 'children'>;
}

const AccessibilityMenu = ({
  dropdown: { ...dropdownProps } = {},
  icon: { icon, color, ...iconProps } = { icon: 'glasses', color: 'paleSky' },
  flex = { gap: 10 },
  className = '',
}: AccessibilityMenuProps) => {
  const [visible, setVisible] = useState(false);

  const intl = useIntl();
  const { theme, changeTheme } = useContext(ThemeContext);
  const { fontSize, changeFontSize } = useContext(FontSizeContext);
  const {
    device: { isDesktop, isMobile },
  } = useSystemSettingState();

  const renderTextOptions = (entry: string[], index: number) => {
    if (isMobile) {
      const isActive = fontSize.toString() === entry[1];

      return (
        <Button
          onClick={() => changeFontSize(entry[1])}
          className={ClassNameUtil.create(['text-option']).add({ isActive }).getClassName()}
          label={
            <Flex gap={4} justify="center" className="my-1 mx-2">
              {isActive && <Icon faBase="far" icon="check" color="green" />}
              <span className="font-size-text">{entry[1] + '%'}</span>
            </Flex>
          }
        />
      );
    }

    return (
      <div className="text-option" onClick={() => changeFontSize(entry[1])} key={index}>
        <Label label={`${entry[1]}%`} />
        {fontSize.toString() === entry[1] && <Icon faBase="far" icon="check" />}
      </div>
    );
  };

  const renderColorOptions = (entry: ColorEntryType, index: number = 10, text?: string) => {
    const isActive = theme === entry[1];

    if (isMobile) {
      return (
        <Button
          onClick={() => changeTheme(entry[1])}
          className={ClassNameUtil.create(['text-option', entry[1]]).add({ isActive }).getClassName()}
          label={
            <Flex justify="center" className="my-1_5">
              {text || (isActive ? <Icon faBase="far" icon="check" color="green" /> : <Icon faBase="far" icon="eye" />)}
            </Flex>
          }
        />
      );
    }

    return (
      <div className={`text-option ${entry[1]}`} onClick={() => changeTheme(entry[1])} key={index}>
        {isActive ? <Icon faBase="far" icon="check" /> : <Icon faBase="far" icon="eye" />}
      </div>
    );
  };

  const renderMenuTitle = () => (
    <SidebarHeaderContainer className="menu-title">
      <div className="title">
        <Label
          label={intl.formatMessage({
            id: 'navigation.settings',
          })}
        />
      </div>
      <div className="close-icon" onClick={() => setVisible(false)}>
        <Icon icon="times" faBase="far" />
      </div>
    </SidebarHeaderContainer>
  );

  const renderFontSizes = () => {
    if (isMobile) {
      return (
        <Flex gap={12} vertical className="font-sizes">
          <span className="semi-title">{intl.formatMessage({ id: 'general.font_size' })}</span>
          <Flex gap={12} wrap="wrap" className="text-options">
            {Object.entries(SettingsFontSizes).map((entry: string[], index) => renderTextOptions(entry, index))}
          </Flex>
        </Flex>
      );
    }

    return (
      <div>
        <Label
          label={intl.formatMessage({
            id: 'general.text_size',
          })}
          className="semi-title"
        />
        {Object.entries(SettingsFontSizes).map((entry: string[], index) => renderTextOptions(entry, index))}
      </div>
    );
  };

  const renderThemes = () => {
    if (isMobile) {
      return (
        <Flex gap={12} vertical className="themes">
          <span className="semi-title">{intl.formatMessage({ id: 'general.contrast' })}</span>
          <div className="text-options">
            <Row gutter={[12, 12]}>
              {Object.entries(SettingsColorSchemes).map((colorEntry: ColorEntryType, index) => (
                <Col span={12}>{renderColorOptions(colorEntry, index)}</Col>
              ))}
            </Row>
          </div>
        </Flex>
      );
    }

    return (
      <div>
        <Label label={intl.formatMessage({ id: 'general.contrast' })} className="semi-title" />
        {Object.entries(SettingsColorSchemes).map((entry: ColorEntryType, index) => renderColorOptions(entry, index))}
      </div>
    );
  };

  const renderMenu = () => (
    <Menu>
      {!isMobile && renderMenuTitle()}
      <SettingsContainer className="settings">
        <Flex gap={isMobile ? 16 : undefined} vertical>
          {renderFontSizes()}
          {renderThemes()}
        </Flex>
      </SettingsContainer>
    </Menu>
  );

  const renderMenuButton = (onClick?: MouseEventHandler<HTMLDivElement>) => {
    return (
      <Flex justify="center" align="center" className="menu-button" {...flex} onClick={onClick}>
        <Icon faBase="far" icon={icon} color={color} {...iconProps} />
        {isDesktop && <span className="menu-label mt-0_5">{intl.formatMessage({ id: 'cookies.accessibility' })}</span>}
      </Flex>
    );
  };

  const renderLayout = (type: LayoutType) => {
    switch (type) {
      case 'desktop':
        return (
          <Dropdown
            menu={() => renderMenu()}
            {...dropdownProps}
            trigger={['click']}
            open={visible}
            onOpenChange={setVisible}
            placement="bottomLeft"
          >
            {renderMenuButton()}
          </Dropdown>
        );

      case 'mobile':
        return (
          <>
            {renderMenuButton(() => setVisible((old) => !old))}
            {createPortal(
              <StyledAccessibilityModal>
                <Modal
                  width="100vw"
                  className="fullScreen accessibility-modal"
                  closable
                  open={visible}
                  onCancel={() => setVisible(false)}
                  maskClosable={false}
                  title={intl.formatMessage({ id: 'cookies.accessibility' })}
                  footer={
                    <Button
                      onClick={() => {
                        changeTheme(SettingsColorSchemes.default);
                        changeFontSize(SettingsFontSizes.fontSize1);
                      }}
                      label={
                        <Flex justify="center" className="my-1_5">
                          {intl.formatMessage({ id: 'cookies.default_option' })}
                        </Flex>
                      }
                    />
                  }
                >
                  {renderMenu()}
                </Modal>
              </StyledAccessibilityModal>,
              document.body
            )}
          </>
        );
    }
  };

  return (
    <div className={ClassNameUtil.create(['accessibility-menu', className]).getClassName()}>
      {renderLayout(isMobile ? 'mobile' : 'desktop')}
    </div>
  );
};

export default AccessibilityMenu;
