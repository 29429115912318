import React from 'react';
import { useIntl } from 'react-intl';
import { EventInterface } from '../index';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import { StyledEventView } from './style';
import { Flex, Image } from 'antd';

interface EventViewProps {
  event: EventInterface;
}

export const EventView = ({
  event: { picture, news_description: description, publishing_date: publishedOn },
}: EventViewProps) => {
  const intl = useIntl();

  return (
    <StyledEventView className="StyledEventView mt-2">
      <Flex gap={20} vertical>
        <div>{`${intl.formatMessage({ id: 'general.published' })}: ${dayjs(publishedOn).format('DD.MM.YYYY')}`}</div>
        {picture && <Image src={picture} alt="event picture" preview={false} className="image" />}
        <div className="description">{parse(description)}</div>
      </Flex>
    </StyledEventView>
  );
};
