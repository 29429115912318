import React, { useState } from 'react';
import InfoWrapper from '../info-wrapper';
import { Alert, Button, Icon } from 'ui';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { DateVoteCount } from '../date-vote-status';
import { getTabUrl as getMyParticipationTabUrl } from 'pages/LayoutPage/Components/Sidebars/MyParticipationTabs/MyParticipationTabs';
import DeleteModal from 'components/Modals/DeleteModal';
import { Flex, Tag } from 'antd';
import useQueryApiClient from 'utils/useQueryApiClient';
import dayjs from 'dayjs';

interface SubmittedStateProps {
  projectId: number;
  visibleVotes: boolean;
  votingPeriodFrom: string;
  votingPeriodTo: string;
  voteCount: number;
  submittedProject: any;
}

const SubmittedState = ({
  projectId,
  votingPeriodFrom,
  votingPeriodTo,
  visibleVotes,
  voteCount,
  submittedProject,
}: SubmittedStateProps) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const intl = useIntl();
  const navigate = useNavigate();
  const { appendData: deleteProject } = useQueryApiClient({
    request: {
      url: `api/v1/tapis/projects/${projectId}`,
      method: 'DELETE',
      enableOnMount: false,
    },
    onSuccess: () => navigate(getMyParticipationTabUrl('submitted_projects_tab')),
  });

  const handleEdit = () => {
    navigate(`/main?submit-project-form=open&project-id=${projectId}`);
  };

  const handleDelete = () => {
    setIsDeleteModalVisible(true);
  };

  const onDeleteSuccess = () => {
    setIsDeleteModalVisible(false);
    deleteProject();
  };

  const renderButtons = () => {
    const { submission_period_to, isTheUserCreator } = submittedProject;
    const isPastSubmissionEnd = dayjs(submission_period_to, 'DD.MM.YYYY').endOf('d').isBefore(dayjs());

    if (!isTheUserCreator) {
      return null;
    }

    if (isPastSubmissionEnd) {
      return (
        <>
          <Tag className={`status ${submittedProject.state}`}>
            {intl.formatMessage({ id: `participation_budget.${submittedProject.state}` })}
          </Tag>
        </>
      );
    }

    return (
      <Flex gap={10}>
        <Button label={intl.formatMessage({ id: 'general.edit' })} type={'default'} onClick={handleEdit} />
        <Button
          label={intl.formatMessage({ id: 'participation_budget.delete_project' })}
          onClick={handleDelete}
          className="delete-btn"
        />
      </Flex>
    );
  };

  return (
    <InfoWrapper>
      <div className="submitted-status">
        {votingPeriodFrom && votingPeriodTo && visibleVotes ? (
          <DateVoteCount votingPeriodFrom={votingPeriodFrom} votingPeriodTo={votingPeriodTo} voteCount={voteCount} />
        ) : (
          <p>
            {!dayjs(submittedProject.submission_period_to, 'DD.MM.YYYY').endOf('d').isBefore(dayjs()) && (
              <>
                <Icon icon="circle-info" faBase="fa-regular" />
                &nbsp;{' '}
                {intl.formatMessage(
                  { id: 'participation_budget.submission_period_to' },
                  { date: submittedProject.submission_period_to }
                )}
              </>
            )}
          </p>
        )}
        {renderButtons()}
      </div>
      {submittedProject?.versions[0]?.state_explanation && (
        <Alert type="warning" message={parse(submittedProject?.versions[0]?.state_explanation)} className="mt-4" />
      )}
      <DeleteModal
        setShowModal={setIsDeleteModalVisible}
        showModal={isDeleteModalVisible}
        actionProps={{ okProps: { type: 'primary', onClick: onDeleteSuccess } }}
        url="api/v1/tapis/projects/{id}"
        deleteId={projectId}
      />
    </InfoWrapper>
  );
};

export default SubmittedState;
