import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { StyledProjects } from './style';
import { Button, Input, Pagination, ProjectCard, Select, SelectOption, Popover, Spinner } from 'ui';
import { Form, Tag } from 'antd';
import { useIntl } from 'react-intl';
import usePagination from 'utils/usePagination';
import { useProjectState, useProjectDispatch, SearchInterface } from 'contexts/ProjectContext';
import { CoordinatesWindowPosition } from 'pages/LayoutPage';
import { useParticipationBudgetState } from 'contexts/ParticipationBudgetContext';
import useTooltip from 'utils/useTooltip';
import { useUserState } from 'contexts/UserContext';
import ApiSelect from 'components/Selects/ApiSelect';

interface ProjectVersion extends Omit<ProjectType, 'versions' | 'pictures'> {
  pictures: Array<{
    blob: any;
    custom_name: string;
    url: string;
  }>;
}

export interface ProjectType {
  id: number;
  name: string;
  title: string;
  voted: boolean;
  pictures: string[];
  the_geom: string;
  state: string;
  isTheUserCreator: boolean;
  versions: ProjectVersion[];
  modalCoord?: any;
  has_voted?: boolean;
  coordinatesForModal?: CoordinatesWindowPosition;
  atvk_id: string;
  can_vote: boolean;
  project_id?: number;
  year: number;
}

interface ProjectPropType {
  isOpenProjectSearch: boolean;
  setIsOpenProjectSearch: Dispatch<SetStateAction<boolean>>;
}

interface ProjectSearchInterface
  extends Pick<SearchInterface, 'state' | 'territorial_units' | 'atvk_id' | 'name' | 'category'> {}

const Project = ({ isOpenProjectSearch, setIsOpenProjectSearch }: ProjectPropType) => {
  const [projectsListed, setProjectsListed] = useState<boolean>(false);
  const [projects, setProjects] = useState<ProjectType[] | []>([]);
  const [activeProject, setActiveProject] = useState<number | null>(null);

  const intl = useIntl();
  const [form] = Form.useForm();
  const theme = localStorage.getItem('selected-theme');
  const { projects: projectsDataApi, isLoading, search } = useProjectState();
  const dispatchProjects = useProjectDispatch();
  const { budgets } = useParticipationBudgetState();
  const { getRestrictionTooltip } = useTooltip('');
  const user = useUserState();

  const publicStatus = [
    { id: '1', value: 'in_voting', name: 'Balsošanā' },
    { id: '2', value: 'voting_is_closed', name: 'Balsošana noslēgusies' },
    { id: '3', value: 'supported', name: 'Atbalstīts' },
    { id: '4', value: 'being_implemented', name: 'Tiek īstenots' },
    { id: '5', value: 'realized', name: 'Realizēts' },
    { id: '6', value: 'not_supported', name: 'Neatbalstīts' },
  ];

  useEffect(() => {
    setProjects(getSorted(projectsDataApi));
  }, [projectsListed, projectsDataApi]);

  useEffect(() => {
    if (activeProject) {
      dispatchProjects({
        type: 'HIGHLIGHT_PROJECT',
        payload: activeProject,
      });
    }
  }, [activeProject]);

  const extractTerritories = (
    territories: ProjectSearchInterface['territorial_units'] = [],
    targetType: 'territorial-units' | 'neighbourhoods'
  ): string[] => {
    return territories
      .map((item) => {
        const parts = item.split('_');
        const territory = parts.shift();
        const type = parts.pop();

        return type === targetType ? territory : undefined;
      })
      .filter(Boolean) as string[];
  };

  const getTerritorialSearch = (
    territories: ProjectSearchInterface['territorial_units'] = []
  ): { territorial_units?: string[]; neighbourhoods?: string[] } => {
    return territories.length
      ? {
          territorial_units: extractTerritories(territories, 'territorial-units'),
          neighbourhoods: extractTerritories(territories, 'neighbourhoods'),
        }
      : {};
  };

  const onFinish = (values: ProjectSearchInterface) => {
    const territorialSearch = getTerritorialSearch(values.territorial_units);

    if (values?.state?.length) {
      dispatchProjects({
        type: 'REFETCH',
        payload: {
          search: {
            ...values,
            ...territorialSearch,
          },
        },
      });
      return;
    }
    const status = publicStatus.map((el) => el.value);
    dispatchProjects({
      type: 'REFETCH',
      payload: {
        search: {
          ...values,
          ...territorialSearch,
          state: status,
        },
      },
    });
  };

  const getSorted = (projects: ProjectType[]) => {
    let processedIds: number[] = [];

    const hasVoted = projects
      .filter(
        ({ has_voted, state, id }: ProjectType) => has_voted && state === 'in_voting' && !processedIds.includes(id)
      )
      .sort(sortByVotingRestrictions);

    processedIds.push(...hasVoted.map(({ id }) => id));

    const hasNotVoted = projects
      .filter(
        ({ has_voted, state, id }: ProjectType) => !has_voted && state === 'in_voting' && !processedIds.includes(id)
      )
      .sort(sortByVotingRestrictions);

    processedIds.push(...hasNotVoted.map(({ id }) => id));

    const notInVoting = projects.filter(({ can_vote, id }: ProjectType) => !can_vote && !processedIds.includes(id));

    return hasVoted.concat(hasNotVoted).concat(notInVoting);
  };

  const sortByVotingRestrictions = (a: ProjectType, b: ProjectType) => {
    switch (true) {
      case a.can_vote && b.can_vote:
        const stringA = getRestrictionTooltip('vote-project', { user, project: a });
        const stringB = getRestrictionTooltip('vote-project', { user, project: b });

        return stringA.length - stringB.length;

      case a.can_vote:
        return -1;

      case b.can_vote:
        return 1;

      default:
        return 0;
    }
  };

  const renderTag = (props: any) => {
    const { value, label, closable, onClose } = props;
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag className={'status status-' + value} onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  const storedPage = sessionStorage.getItem('ProjectCurrentPage');

  const { currentPage, paginatedData, handlePageChange } = usePagination(
    projects,
    storedPage && parseInt(storedPage),
    projectsListed ? 9 : undefined
  );

  const handleChangeProjectList = (value: boolean) => {
    const newPageSize = value ? 9 : 6;
    const newTotalPages = Math.ceil(projects.length / (newPageSize || projects.length));
    const newPage = Math.min(currentPage, newTotalPages);

    setProjectsListed(value);
    handleProjectPageChange(newPage);
  };
  const handleProjectPageChange = (page: number) => {
    sessionStorage.setItem('ProjectCurrentPage', page.toString());
    handlePageChange(page);
  };

  const getSearchInitialValues = (): ProjectSearchInterface => {
    const { state, atvk_id, category, name, territorial_units } = search;

    return { state: state || ['in_voting'], name, atvk_id, territorial_units, category };
  };

  const resetSearchFields = () => {
    const fields: ProjectSearchInterface = {
      name: undefined,
      state: ['in_voting'],
      atvk_id: undefined,
      category: undefined,
    };

    form.setFieldsValue(fields);
    form.submit();
  };

  return (
    <StyledProjects className="projects">
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={getSearchInitialValues()}>
        <div className="projects__input-button-wrapper">
          <Input
            name="name"
            label={intl.formatMessage({ id: 'participation_budget.projects_search_input' })}
            className="projects__input"
            placeholder={intl.formatMessage({ id: 'participation_budget.projects_search_input_placeholder' })}
          />
          <Button
            loading={isLoading}
            htmlType="submit"
            type="primary"
            label={intl.formatMessage({ id: 'participation_budget.projects_search_input' })}
          />
          {isOpenProjectSearch ? (
            <Button
              className="close-filter-button"
              icon="xmark"
              faBase="fa"
              onClick={() => setIsOpenProjectSearch(false)}
            />
          ) : (
            <Button
              label={intl.formatMessage({ id: 'participation_budget.projects_filter_button' })}
              onClick={() => setIsOpenProjectSearch(true)}
            />
          )}
        </div>
        {isOpenProjectSearch && (
          <>
            <div className="projects__selects-wrapper">
              <Select
                name="atvk_id"
                placeholder={intl.formatMessage({ id: 'projects.for_example_riga' })}
                label={intl.formatMessage({ id: 'participation_budget.projects_select_municipality' })}
                optionFilterProp="children"
                allowClear
                className="municipality-selector"
              >
                {budgets.map((entry: any) => (
                  <SelectOption key={entry.id} value={entry.atvk_id}>
                    {entry.name}
                  </SelectOption>
                ))}
              </Select>
              <ApiSelect
                request={{ url: 'api/v1/tapis/territorial_units' }}
                name="territorial_units"
                placeholder={intl.formatMessage({ id: 'participation_budget.territory_selector_placeholder' })}
                label={intl.formatMessage({ id: 'participation_budget.projects_select_territorialunit' })}
                formatOptions={(items, searchString) => (searchString.length ? items : items.slice(0, 6))}
              />
              <Select
                name="state"
                className="dimmed"
                mode="multiple"
                label={intl.formatMessage({ id: 'participation_budget.projects_select_statusofprojects' })}
                tagRender={renderTag}
              >
                {publicStatus.map((status) => (
                  <SelectOption
                    key={status.id}
                    value={status.value}
                    className={` project-status-${(theme === 'default' || !theme) && status.value}`}
                  >
                    {intl.formatMessage({ id: `participation_budget.${status.value}` })}
                  </SelectOption>
                ))}
              </Select>
              <ApiSelect
                name="category"
                placeholder={intl.formatMessage({ id: 'participation_budget.category_selector_placeholder' })}
                label={intl.formatMessage({ id: 'participation_budget.projects_select_category' })}
                size="large"
                request={{ url: 'api/v1/tapis/categories' }}
                formatOptions={(items, searchString) => (searchString.length ? items : items.slice(0, 6))}
              />
            </div>
            <div className="projects__clear-selects">
              <Button
                icon="trash-can"
                faBase="fa"
                label={intl.formatMessage({ id: 'participation_budget.projects_selects_clear' })}
                border={false}
                onClick={() => resetSearchFields()}
              />
            </div>
          </>
        )}
      </Form>
      <div className="projects__content">
        <div className="projects__content-header">
          <span>
            {intl.formatMessage({ id: 'participation_budget.projects_together' }, { projects: projects.length })}
          </span>
          <div className="projects__content-header-btns">
            <Button
              icon="grid-2"
              faBase="fa-regular"
              label={intl.formatMessage({ id: 'participation_budget.projects_behaviour_column' })}
              className={projectsListed ? '' : 'selected-project-format'}
              border={false}
              onClick={() => handleChangeProjectList(false)}
            />
            <Button
              icon="list"
              faBase="fa-regular"
              label={intl.formatMessage({ id: 'participation_budget.projects_behaviour_list' })}
              className={!projectsListed ? '' : 'selected-project-format'}
              border={false}
              onClick={() => handleChangeProjectList(true)}
            />
          </div>
        </div>
        <Spinner spinning={isLoading}>
          <div className={`projects__content-body ${projectsListed && 'list-view'}`}>
            {paginatedData.map((project: ProjectType, index) => (
              <ProjectCard
                key={project.id}
                listView={projectsListed}
                project={project}
                isVisibleSeDescBtn
                className={`${activeProject === project.id ? 'bordered' : ''} ellipse-title`}
                setActiveProject={setActiveProject}
                projectViewSide="left"
                id={project.id}
                imageSize={projectsListed ? 'small' : 'medium'}
              />
            ))}
          </div>
        </Spinner>
        {projects.length > 6 && (
          <Pagination
            onChange={handleProjectPageChange}
            defaultCurrent={1}
            current={currentPage}
            total={projects.length}
            pageSize={projectsListed ? 9 : undefined}
            showSizeChanger={false}
            className="default"
          />
        )}
      </div>
    </StyledProjects>
  );
};

export default Project;
