import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import React from 'react';
import { useIntl } from 'react-intl';

interface VersionToggleProps {
  projectVersion: {
    version_number: number;
    only_version: boolean;
    isTheUserCreator: boolean;
  };
}

export const VersionToggle = ({
  projectVersion: { version_number: currentVersion, only_version: isOnlyVersion, isTheUserCreator },
}: VersionToggleProps) => {
  const [searchParams] = useSearchParams();
  const intl = useIntl();
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  const isSecondVersion = currentVersion === 2;

  searchParams.set('version', isSecondVersion ? '1' : '2');

  if (isOnlyVersion || !isTheUserCreator) {
    return null;
  }

  const handleOpenProjectView = () => {
    navigate(pathname + '?' + searchParams.toString(), { state: { currentPage: state?.currentPage } });
  };

  return (
    <div className="version-toggle">
      <a onClick={handleOpenProjectView}>
        {intl.formatMessage({
          id: isSecondVersion ? 'version_toggle.original_version' : 'version_toggle.current_version',
        })}
      </a>
    </div>
  );
};
